import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import LoadScreen from '../../../components/load-screen';
import SetSummaryForm from '../../../components/reporting/set-summary/SetSummaryForm';
import SetSummaryTable from '../../../components/reporting/set-summary/SetSummaryTable';

import { getSets, getLabels, getConsignedSets, exportSetSummary } from '../../../actions/setsActions';
import { getHospitals } from '../../../actions/hospitalsActions';
import { getKits } from '../../../actions/kitsActions';

import useLoading from '../../../hooks/useLoading';

const SetSummaryReportingPage = () => {
  const dispatch = useDispatch();

  const { loading: fetching, startLoading, stopLoading } = useLoading(false);

  const kits = useSelector((state) => state?.kits.list);
  const hospitals = useSelector((state) => state?.hospitals.list);
  const labels = useSelector((state) => state?.sets.labels);
  const cases = useSelector((state) => state?.cases.list);
  const currentTenant = useSelector((state) => state.tenant.currentTenant);

  const [loading, setLoading] = useState(false);
  const [exportLoading, setExportLoading] = useState(false);
  const [data, setData] = useState([]);
  const [status, setStatus] = useState('NOT_CONSIGNED');

  useEffect(() => {
    onLoad().catch((err) => console.error(err));
  }, []);

  const onLoad = async () => {
    startLoading()
    try {
      await Promise.all([
        dispatch(getKits()),
        dispatch(getHospitals()),
        dispatch(getLabels()),
      ]);
    } catch (err) {
      console.error(err);
    } finally {
      stopLoading();
    }
  };

  const onSubmit = async (status) => {
    setLoading(true);
    try {
      let sets = []
      if (status === 'CONSIGNED') {
        sets = await dispatch(getConsignedSets(currentTenant.id, false));
      } else {
        sets = await dispatch(getSets(currentTenant.id, false));
      }
      setData(sets);
      // for (const set of sets) {
      //   let items = [];
      //   if (set.status === setPositionOptions.AVAILABLE.value) {
      //     const res = await dispatch(getSections(set.kit));
      //
      //     for (const section of res) {
      //       const res = await dispatch(getItemsByIds(section.items?.filter((i) => i.type !== billOfMaterialItemTypes.NOTES.value)?.map((i) => i.id)));
      //       items = [
      //         ...items,
      //         ...res
      //       ];
      //     }
      //   }
      //   data.push({ ...set, items });
      // }
      // setData(data);
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const onExport = async (data) => {
    try {
      setExportLoading(true);
      await dispatch(exportSetSummary(data));
    } catch (err) {
      console.error(err);
    } finally {
      setExportLoading(false);
    }
  };

  return (
    <div className='page-container reporting-page__container'>
      {fetching && <LoadScreen />}
      <div className='page-title'>Set Summary</div>
      <div className='reporting-page__body'>
        <SetSummaryForm status={status} setStatus={setStatus} onSubmit={onSubmit} loading={loading} />

        <SetSummaryTable
          sets={data}
          kits={kits}
          cases={cases}
          hospitals={hospitals}
          tenantColor={currentTenant.colorPrimary}
          labels={labels}
          exportLoading={exportLoading}
          onExportClick={onExport}
        />
      </div>
    </div>
  );
};

export default SetSummaryReportingPage;
