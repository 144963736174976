import React, { useState, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import {
  ParentKitActions,
  ParentKitBreadcrumbs,
  ParentKitItemsTable,
} from '../../../components/kits/parent-kit';
import Input from '../../../components/shared/Input';
import LoadScreen from '../../../components/load-screen';

import { getKit, updateKit, getKits } from '../../../actions/kitsActions';

import { useLoading, useAlert } from '../../../hooks';

import { kitItemTypes } from '../../../constants/enums';
import { AddItemsModal, AddKitsModal } from '../../../components/kit-preferences';
import { ConfirmationModal } from '../../../components/shared/modal';
import { getItemsByIds } from '../../../actions/itemsActions';

const ParentKitPage = () => {
  const { id } = useParams();
  const kitId = id.replace(':', '');

  const dispatch = useDispatch();
  const kits = useSelector((state) => state?.kits.list);

  const { loading, startLoading, stopLoading } = useLoading(false);
  const { fetching, startLoading: startFetching, stopLoading: stopFetching } = useLoading(false);
  const { loading: deleting, startLoading: startDeleting, stopLoading: stopDeleting } = useLoading(false);

  const { showAlert } = useAlert();

  const [items, setItems] = useState([]);

  const [kit, setKit] = useState(null);
  const [search, setSearch] = useState('');
  const [checkedItems, setCheckedItems] = useState([]);

  const [kitsModalOpen, toggleKitsModal] = useState(false);
  const [itemsModalOpen, toggleItemsModal] = useState(false);
  const [isModalDeleteOpen, toggleDeleteModal] = useState(false);

  useEffect(() => {
    onLoad().catch((err) => console.error(err));
  }, []);

  const fetchKit = async () => {
    try {
      const res = await dispatch(getKit(kitId));
      if (res && res?.id) {
        setKit(res);

        const _items = await dispatch(getItemsByIds(res?.children?.filter((k) => k.type === kitItemTypes.ITEM.value).map((k) => k.itemId)));
        setItems(_items || []);
      }
    } catch (err) {
      throw err;
    }
  };

  const onLoad = async () => {
    startFetching();

    try {
      await dispatch(getKits());
      await fetchKit();
    } catch (err) {
      console.error(err);
    } finally {
      stopFetching();
    }
  };

  const onAddClick = (option) => {
    if (option === kitItemTypes.KIT.value) {
      toggleKitsModal(true);
    } else {
      toggleItemsModal(true);
    }
  };

  const onAddItems = async (ids, type) => {
    startLoading();

    try {
      const newItems = ids?.map((id) => ({
        id,
        type,
        kitId: type === kitItemTypes.KIT.value ? id : null,
        itemId: type === kitItemTypes.ITEM.value ? id : null,
        quantity: 1,
      }));

      await dispatch(updateKit(kitId, { children: kit?.children ? [...kit.children, ...newItems] : [...newItems] }));
      await fetchKit();

      stopLoading();
      toggleKitsModal(false);
      toggleItemsModal(false);
      showAlert('success', `${kitItemTypes[type]?.label}s have been successfully added`);
    } catch (err) {
      console.error(err);
    } finally {
      stopLoading();
    }
  };

  const list = useMemo(() => {
    if (!kit?.children) {
      return [];
    }
    let result = kit?.children?.map((item) => {
      const arr = item?.type === kitItemTypes.KIT.value ? kits : items;
      const kitItem = arr?.find((i) => i.id === item.id);

      return {
        ...item,
        code: kitItem?.code || kitItem?.kitId || '',
        description: kitItem?.description || kitItem?.name || '',
        itemType: kitItem?.type || '',
      };
    });

    if (!!search) {
      result = result?.filter((i) => i?.code?.toLowerCase()?.includes(search?.toLowerCase()) || i?.description?.toLowerCase()?.includes(search?.toLowerCase()));
    }

    return result;
  }, [kit?.children, kits, items, search]);

  const handleCheck = (itemId) => {
    const checked = checkedItems.slice();

    if (checked.includes(itemId)) {
      const index = checked.indexOf(itemId);

      checked.splice(index, 1);
      setCheckedItems(checked);

      return;
    }

    checked.push(itemId);
    setCheckedItems(checked);
  };

  const handleCheckAll = () => {
    const checked = list?.every((i) => !!checkedItems?.includes(i.id));
    const temp = [];

    if (!checked) {
      list?.forEach((item) => {
        temp?.push(item.id);
      });

      setCheckedItems(temp);
      return;
    }

    setCheckedItems(temp);
  };

  const onDeleteClick = () => {
    if (!!checkedItems.length) {
      toggleDeleteModal(true);
    }
  };

  const onDelete = async () => {
    if (!!checkedItems.length) {
      try {
        startDeleting();

        await dispatch(updateKit(kitId, { children: [...kit.children]?.filter((i) => !checkedItems?.includes(i.id)) }));
        await fetchKit();

        setCheckedItems([]);
        toggleDeleteModal(false);
      } catch (err) {
        console.error(err);
      } finally {
        stopDeleting();
      }
    }
  };

  const onQuantityChange = (itemId, quantity) => {
    try {
      const children = kit?.children ? [...kit.children] : [];
      const item = children?.find((i) => i.id === itemId);
      if (item) {
        item.quantity = quantity;
        setKit({ ...kit, children });
        dispatch(updateKit(kitId, { children: children }));
      }
    } catch (err) {
      console.error(err);
    }
  };

  const onReorder = async (reorderedItems) => {
    const children = reorderedItems?.map((item) => ({
      id: item.id,
      type: item.type,
      kitId: item.kitId,
      itemId: item.itemId,
      quantity: item.quantity,
    }));
    setKit({ ...kit, children });
    await dispatch(updateKit(kitId, { children: children }));
  };

  return (
    <div className="settings-cmp__main flex-1 bom-page__container">
      {fetching && <LoadScreen />}

      <span className="settings-title">Parent Kit</span>
      <div className="bom-page__body">
        <div className="settings-block__left">
          <ParentKitActions
            onAddClick={onAddClick}
            onDeleteClick={onDeleteClick}
          />
        </div>
        <div className='flex-1 m-l-md m-r-lg'>
          <ParentKitBreadcrumbs
            kitId={kit?.kitId}
            kitName={kit?.name}
          />
        </div>

        <Input
          type="search"
          placeholder="Search Bundle"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
      </div>

      <div className="m-t-lg">
        <ParentKitItemsTable
          items={list}
          checked={checkedItems}
          onCheck={handleCheck}
          onCheckAll={handleCheckAll}
          onQuantityChange={onQuantityChange}
          onReorder={onReorder}
        />
      </div>

      <ConfirmationModal
        open={isModalDeleteOpen}
        onClose={() => toggleDeleteModal(false)}
        onSubmit={onDelete}
        title='Are you sure you want to delete these kits/items?'
        submitText='Delete'
        loading={deleting}
      />

      <AddKitsModal
        open={kitsModalOpen}
        onClose={() => toggleKitsModal(false)}
        options={kits?.filter((k) => !k?.isParent)}
        onSubmit={(kitIds) => onAddItems(kitIds, kitItemTypes.KIT.value)}
        loading={loading}
      />

      <AddItemsModal
        open={itemsModalOpen}
        onClose={() => toggleItemsModal(false)}
        onSubmit={(itemIds) => onAddItems(itemIds, kitItemTypes.ITEM.value)}
        loading={loading}
        disabledItems={kit?.children?.filter((k) => k.type === kitItemTypes.ITEM.value)?.map((k) => k?.itemId)}
      />
    </div>
  );
};

export default ParentKitPage;
