import React, { useState, useEffect, useCallback } from 'react';
import moment from 'moment';
import { useDispatch } from 'react-redux';

import CircularProgress from '@material-ui/core/CircularProgress';
import ContactIcon from '../../../assets/icons/ContactIcon';
import TaskIcon from '../../../assets/icons/TaskIcon';
import NotesIcon from '../../../assets/icons/NotesIcon';
import SetIcon from '../../../assets/icons/SetIcon';

import Modal from '../../shared/modal';
import AddButton from '../../shared/AddButton';
import NoteForm from '../forms/NoteForm';
import ContactForm from '../forms/ContactForm';

import {
  getHospitalContacts,
  getHospitalNotes,
  createHospitalContact,
  createHospitalNote,
  getHospitalTasks,
  getConsignmentSets,
} from '../../../actions/hospitalsActions';

import { useAlert } from '../../../hooks';

import { alertTypes } from '../../../constants';

const HospitalInfo = (props) => {
  const {
    hospital,
    kits,
  } = props;

  const dispatch = useDispatch();

  const { showAlert } = useAlert();

  const [contacts, setContacts] = useState([]);
  const [notes, setNotes] = useState([]);
  const [tasks, setTasks] = useState([]);
  const [sets, setSets] = useState([]);

  const [isContactModalOpen, toggleContactModal] = useState(false);
  const [isNoteModalOpen, toggleNoteModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [fetching, setFetching] = useState(false);

  useEffect(() => {
    onLoad(hospital.id)
      .catch((err) => console.error(err));
  }, [hospital]);

  const onLoad = async (hospitalId) => {
    setFetching(true);
    await fetchContacts(hospitalId);
    await fetchNotes(hospitalId);
    await fetchTasks(hospitalId);
    await fetchConsignmentSets(hospitalId);
    setFetching(false);
  };

  const fetchContacts = async (hospitalId) => {
    const hospitalContacts = await dispatch(getHospitalContacts(hospitalId));
    setContacts(hospitalContacts || []);
  };

  const fetchNotes = async (hospitalId) => {
    const hospitalNotes = await dispatch(getHospitalNotes(hospitalId));
    setNotes(hospitalNotes || []);
  };

  const fetchTasks = async (hospitalId) => {
    const hospitalTasks = await dispatch(getHospitalTasks(hospitalId));
    setTasks(hospitalTasks || []);
  };

  const fetchConsignmentSets = async (hospitalId) => {
    const hospitalSets = await dispatch(getConsignmentSets(hospitalId));
    setSets(hospitalSets || []);
  };

  const onCreateContact = async (formObj) => {
    setLoading(true);
    const contactObj = {
      name: formObj.name,
      position: formObj.position,
      phone: formObj.phone,
      email: formObj.email,
    };

    try {
      await dispatch(createHospitalContact(hospital.id, contactObj));
      await fetchContacts(hospital.id);

      setLoading(false);
      toggleContactModal(false);
      showAlert(alertTypes.SUCCESS, 'Contact has been successfully created');
    } catch (err) {
      setLoading(false);
      console.error(err);
    }
  };

  const onCreateNote = async (formObj) => {
    setLoading(true);
    const noteObj = {
      note: formObj.note,
    };

    try {
      await dispatch(createHospitalNote(hospital.id, noteObj));
      await fetchNotes(hospital.id);

      setLoading(false);
      toggleNoteModal(false);
      showAlert(alertTypes.SUCCESS, 'Note has been successfully created');
    } catch (err) {
      setLoading(false);
      console.error(err);
    }
  };

  const getKitName = useCallback((kitId) => {
    const kit = kits.find((item) => item.id === kitId);
    return kit ? kit.name : '';
  }, [kits]);

  return (
    <div className='block__container flex-1'>
      <div className='d-flex space-between'>
        <div className='d-flex'>
          <ContactIcon className='m-t-sm' />
          <div className='flex-1 font-bold font-size-bg m-l-md'>
            Contacts
          </div>
        </div>
        <AddButton
          onClick={() => toggleContactModal(true)}
        />
      </div>
      <div className='m-b-lg'>
        {fetching ? (
          <div style={{ marginTop: 24 }} className='d-flex flex-center'>
            <CircularProgress />
          </div>
        ) : (
          contacts.map((contact) => (
            <div key={contact.id} className='m-t-lg block-body'>
              <div className='d-flex'>
                <div className='font-bold'>{contact.name}</div>
                <div className='m-l-sm secondary'>({contact.position})</div>
              </div>
              <div className='m-t-sm'>
                {contact.phone}
              </div>
              <div className='m-t-sm'>
                {contact.email}
              </div>
            </div>
          ))
        )}
      </div>

      <div className='d-flex space-between'>
        <div className='d-flex p-t-md'>
          <NotesIcon className='m-t-sm' />
          <div className='flex-1 font-bold font-size-bg m-l-md'>
            Notes
          </div>
        </div>
        <AddButton
          onClick={() => toggleNoteModal(true)}
        />
      </div>
      <div>
        {fetching ? (
          <div style={{ marginTop: 24 }} className='d-flex flex-center'>
            <CircularProgress />
          </div>
        ) : (
          notes.map((note) => (
            <div key={note.id} className='m-t-lg block-body'>
              <div className='d-flex space-between'>
                <div className='font-bold'>{note.authorFullName}</div>
                <div className='secondary'>{moment(note.createdAt).fromNow()}</div>
              </div>
              <div className='m-t-sm'>
                {note.note}
              </div>
            </div>
          ))
        )}
      </div>

      <div className='d-flex space-between m-t-lg'>
        <div className='d-flex p-t-md'>
          <TaskIcon className='m-t-sm' />
          <div className='flex-1 font-bold font-size-bg m-l-md'>
            Tasks
          </div>
        </div>
      </div>
      <div>
        {fetching ? (
          <div style={{ marginTop: 24 }} className='d-flex flex-center'>
            <CircularProgress />
          </div>
        ) : (
          tasks.map((task) => (
            <div key={task.id} className='m-t-lg block-body'>
              <div className='d-flex space-between'>
                <div className='font-bold'>{task.title}</div>
                <div className='secondary'>{moment(task.createdAt).fromNow()}</div>
              </div>
              <div className='m-t-sm'>
                {task.description}
              </div>
            </div>
          ))
        )}
      </div>

      <div className='d-flex space-between m-t-lg'>
        <div className='d-flex p-t-md'>
          <SetIcon className='m-t-sm' />
          <div className='flex-1 font-bold font-size-bg m-l-md'>
            Consignment Sets
          </div>
        </div>
      </div>
      <div>
        {fetching ? (
          <div style={{ marginTop: 24 }} className='d-flex flex-center'>
            <CircularProgress />
          </div>
        ) : (
          sets.map((set) => (
            <div key={set.id} className='m-t-lg block-body'>
              <div className='d-flex space-between'>
                <div className='font-bold'>{set.number}</div>
              </div>
              <div className='m-t-sm'>
                {getKitName(set.kit)}
              </div>
            </div>
          ))
        )}
      </div>

      <Modal
        open={isContactModalOpen}
        onClose={() => toggleContactModal(false)}
      >
        <ContactForm
          onSubmit={onCreateContact}
          onClose={() => toggleContactModal(false)}
          loading={loading}
        />
      </Modal>

      <Modal
        open={isNoteModalOpen}
        onClose={() => toggleNoteModal(false)}
      >
        <NoteForm
          onSubmit={onCreateNote}
          onClose={() => toggleNoteModal(false)}
          loading={loading}
        />
      </Modal>
    </div>
  );
};

export default HospitalInfo;
