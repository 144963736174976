import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import DashboardIcon from '../../assets/icons/DashboardIcon';
import CaseIcon from '../../assets/icons/CaseIcon';
import ShippingIcon from '../../assets/icons/ShippingIcon';
import UsageIcon from '../../assets/icons/UsageIcon';
import SetIcon from '../../assets/icons/SetIcon';
import CountIcon from '../../assets/icons/CountIcon';
import FormIcon from '../../assets/icons/FormIcon';
import ToolsIcon from '../../assets/icons/ToolsIcon';
import ReportingIcon from '../../assets/icons/ReportingIcon';
import DriveIcon from '../../assets/icons/DriveIcon';
import HelpIcon from '../../assets/icons/HelpIcon';

import { routes, enums } from '../../constants';
import { roleNames } from '../../constants/userRoles';

import './side-menu.scss';

const BOOKED = enums.caseStatusOptions.booked;
const { QUARANTINED } = enums.setPositionOptions;
const { SALES_REP, CUSTOMER_SERVICE, LOGISTICS, SALES_MANAGER, MARKETING, FINANCE, SURGEON, EXTERNAL_SALES_REP, PRACTICE_MANAGER } = roleNames;

const sideMenuNavigation = [
  { key: 'home', title: 'Home', route: routes.HOME, excludedRoles: [] },
  { key: 'cases', title: 'Cases', route: routes.CASES, excludedRoles: [] },
  { key: 'kits', title: 'Shipping', route: routes.KITS, excludedRoles: [SALES_REP, CUSTOMER_SERVICE, SALES_MANAGER, MARKETING, FINANCE] },
  { key: 'usage', title: 'Usage', route: routes.USAGE, excludedRoles: [SALES_REP, LOGISTICS, SALES_MANAGER, MARKETING, FINANCE] },
  { key: 'sets', title: 'Sets', route: routes.SETS, excludedRoles: [SALES_REP, CUSTOMER_SERVICE, SALES_MANAGER, MARKETING, FINANCE] },
  { key: 'inventory', title: 'Counts', route: routes.INVENTORY, excludedRoles: [CUSTOMER_SERVICE, SALES_MANAGER, MARKETING, FINANCE] },
  { key: 'forms', title: 'Forms', route: routes.SUBMITTED_FORMS, excludedRoles: [SURGEON, SALES_REP, EXTERNAL_SALES_REP, PRACTICE_MANAGER] },
  { key: 'tools', title: 'Tools', route: routes.TOOLS, excludedRoles: [LOGISTICS] },
  { key: 'reporting', title: 'Reporting', route: routes.REPORTING, excludedRoles: [SALES_REP, CUSTOMER_SERVICE, LOGISTICS, SALES_MANAGER, MARKETING, FINANCE] },
  { key: 'drive', title: 'Drive', route: routes.DRIVE, excludedRoles: [] },
];

const fontSize = 24;

const SideMenu = (props) => {
  const {
    currentTenant,
    history,
    compressed,
    userRole,
    quarantinedSetsAmount,
    overdueSets,
    newForms,
    // casesAmount,
  } = props;

  const getMenuIcon = (key, color) => {
    switch (key) {
      case 'home':
        return <DashboardIcon color={color} style={{ fontSize }} />;
      case 'cases':
        return <CaseIcon color={color} style={{ fontSize }} />;
      case 'tools':
        return <ToolsIcon color={color} style={{ fontSize }} />;
      case 'inventory':
        return <CountIcon color={color} style={{ fontSize }} />;
      case 'kits':
        return <ShippingIcon color={color} style={{ fontSize }} />;
      case 'sets':
        return <SetIcon color={color} style={{ fontSize }} />;
      case 'drive':
        return <DriveIcon color={color} style={{ fontSize }} />;
      case 'usage':
        return <UsageIcon color={color} style={{ fontSize }} />;
      case 'reporting':
        return <ReportingIcon color={color} style={{ fontSize }} />;
      case 'forms':
        return <FormIcon color={color} style={{ fontSize }} />;
      default:
        return null;
    }
  };

  const path = window.location.pathname;

  const getColor = useCallback((key, route, defaultColor) => {
    if (key === 'home') {
      return path === routes.HOME ? currentTenant.colorPrimary : defaultColor;
    }

    if (key === 'drive') {
      return path === routes.DRIVE ? currentTenant.colorPrimary : defaultColor;
    }

    if (key === 'kits') {
      return path === routes.KITS ? currentTenant.colorPrimary : defaultColor;
    }

    if (key === 'usage') {
      return path === routes.USAGE ? currentTenant.colorPrimary : defaultColor;
    }

    if (key === 'sets') {
      return path === routes.SETS ? currentTenant.colorPrimary : defaultColor;
    }

    if (key === 'cases') {
      return path === routes.CASES || path.includes(`${routes.CASES}/`) ? currentTenant.colorPrimary : defaultColor;
    }

    if (key === 'inventory') {
      return path === '/counts' || path.includes('counts/') ? currentTenant.colorPrimary : defaultColor;
    }

    return path.includes(route) ? currentTenant.colorPrimary : defaultColor;
  }, [path, currentTenant.colorPrimary]);

  if (compressed) {
    return (
      <div className='side-menu' style={{'--menu-width' : '100px'}}>
        <div className='menu-container'>
          <div>
            {sideMenuNavigation.filter((route) => !route.excludedRoles.includes(userRole)).map((item) => (
              <div
                key={item.key}
                className='item'
                style={{
                  color: getColor(item.key, item.route, '#202124'),
                  '--colorHover': currentTenant.colorPrimary
                }}
                onClick={() => history.push(item.route)}
              >
                {getMenuIcon(item.key, getColor(item.key, item.route, '#272d26'))}
                {/*{item.key === 'cases' && casesAmount > 0 && (*/}
                {/*  <div*/}
                {/*    className='notifications'*/}
                {/*    style={{'--color-background': `${currentTenant.colorPrimary}`}}*/}
                {/*  >*/}
                {/*    {casesAmount}*/}
                {/*  </div>*/}
                {/*)}*/}
                {item.key === 'sets' && quarantinedSetsAmount > 0 && (
                  <div
                    className='notifications'
                    style={{'--color-background': `${QUARANTINED.color}`}}
                  >
                    {quarantinedSetsAmount}
                  </div>
                )}
                {item.key === 'kits' && overdueSets > 0 && (
                  <div
                    className='notifications'
                    style={{'--color-background': `${QUARANTINED.color}`}}
                  >
                    {overdueSets}
                  </div>
                )}
                {item.key === 'forms' && newForms > 0 && (
                  <div
                    className='notifications'
                    style={{'--color-background': `${currentTenant.colorPrimary}`}}
                  >
                    {newForms}
                  </div>
                )}
              </div>
            ))}
          </div>
          <div
            className='item'
            style={{ '--colorHover': currentTenant.colorPrimary }}
            onClick={() => window.open('https://help.opologic.com/en/', '_blank')}
          >
            <HelpIcon />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className='side-menu'>
      <div className='menu-container'>
        <div>
          { sideMenuNavigation.filter((route) => !route.excludedRoles.includes(userRole)).map((item) => (
            <div
              key={item.key}
              className='item'
              style={{
                color: getColor(item.key, item.route, '#202124'),
                '--colorHover': currentTenant.colorPrimary
              }}
              onClick={() => history.push(item.route)}
            >
              {getMenuIcon(item.key, getColor(item.key, item.route, '#272d26'))}
              <span style={{ marginLeft: 22 }}>{item.title}</span>
              {/*{item.key === 'cases' && casesAmount > 0 && (*/}
              {/*  <div*/}
              {/*    className='notifications'*/}
              {/*    style={{'--color-background': `${currentTenant.colorPrimary}`}}*/}
              {/*  >*/}
              {/*    {casesAmount}*/}
              {/*  </div>*/}
              {/*)}*/}
              {item.key === 'sets' && quarantinedSetsAmount > 0 && (
                <div
                  className='notifications'
                  style={{'--color-background': `${QUARANTINED.color}`}}
                >
                  {quarantinedSetsAmount}
                </div>
              )}
              {item.key === 'kits' && overdueSets > 0 && (
                <div
                  className='notifications'
                  style={{'--color-background': `${QUARANTINED.color}`}}
                >
                  {overdueSets}
                </div>
              )}
              {item.key === 'forms' && newForms > 0 && (
                <div
                  className='notifications'
                  style={{'--color-background': `${currentTenant.colorPrimary}`}}
                >
                  {newForms}
                </div>
              )}
            </div>
          ))}
        </div>
        <div
          className='item'
          style={{ '--colorHover': currentTenant.colorPrimary }}
          onClick={() => window.open('https://help.opologic.com/en/', '_blank')}
        >
          <HelpIcon />
          <span style={{ marginLeft: 22 }}>Need help?</span>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    currentTenant: state.tenant.currentTenant,
    casesAmount: state.cases.list.filter((item) => item.status === BOOKED && !!item.active).length,
    // quarantinedSetsAmount: state.sets.list?.filter((item) => !!item.quarantined)?.length || state.sets.quarantined?.length || 0,
    // overdueSets: state.cases.overdueSets,
    userRole: state.user.currentUser.role,
    newForms: state.forms.newForms,
  };
};

export default connect(mapStateToProps)(withRouter(SideMenu));
