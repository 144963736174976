import firebase, { collections, storageRefs } from '../firebase';

import { LOAD_USER_DATA, SET_CURRENT_USER } from './actionTypes';

export const getCurrentUser = () => async (dispatch) => {
  dispatch({ type: LOAD_USER_DATA });

  const user = await firebase.doGetCurrentUser();

  if (user && user.uid) {
    return dispatch({ type: SET_CURRENT_USER, user });
  }

  return dispatch({ type: SET_CURRENT_USER, user: null });
};

export const setCurrentUser = (user) => (dispatch) => {
  return dispatch({ type: SET_CURRENT_USER, user });
};

export const updateProfile = (userData, files = {}) => async (dispatch, getState) => {
  const state = getState();
  const userId = state.user.currentUser ? state.user.currentUser.uid : null;
  const path = collections.USERS_COLLECTION;
  const storageRef = firebase.storage.ref();
  const { profileImage } = files;

  if (userId) {
    await firebase.db.collection(path).doc(userId).set(userData, { merge: true });

    if (profileImage) {
      const storagePath = storageRefs.USER_FILES_REF(userId);
      const filePath = `${storagePath}/${profileImage.name}`;
      const ref = storageRef.child(filePath);
      const snap = await ref.put(profileImage);
      const downloadUrl = await snap.ref.getDownloadURL();
      const profilePicture = {
        fileName: profileImage.name,
        path: filePath,
        downloadUrl
      };

      await firebase.db.collection(path).doc(userId).set({ profilePicture }, { merge: true });
    }
  } else {
    throw new Error('Invalid user');
  }
};

export const updatePassword = (currentPassword, newPassword) => async (dispatch, getState) => {
  const state = getState();
  const { uid } = state.user.currentUser;

  try {
    await firebase.reauthenticate(currentPassword);
    await firebase.doPasswordUpdate(newPassword);
    await firebase
      .db
      .collection(collections.USERS_COLLECTION)
      .doc(uid)
      .set({ passwordSet: true }, { merge: true });
  } catch(err) {
    throw err;
  }
};

export const addConnection = (tenantId) => async (dispatch, getState) => {
  const state = getState();
  const userId = state.user.currentUser ? state.user.currentUser.uid : null;
  const connections = state.user.currentUser ? state.user.currentUser.connectedTenants : [];
  const path = collections.USERS_COLLECTION;

  await firebase.db.collection(path).doc(userId).set({ connectedTenants: [...connections, tenantId] }, { merge: true });
};

export const saveUsageExportTypeSelection = (exportType) => async (dispatch, getState) => {
  const state = getState();
  const userId = state.user.currentUser ? state.user.currentUser.uid : null;
  const path = collections.USERS_COLLECTION;

  await firebase.db.collection(path).doc(userId).set({ lastSelectedUsageExportType: exportType }, { merge: true });
};

// Subscriptions
export const subscribeToCurrentUser = () => (dispatch, getState) => {
  const state = getState();
  const { uid } = state.user.currentUser;

  return firebase
    .db
    .collection(collections.USERS_COLLECTION)
    .doc(uid)
    .onSnapshot({
      error: (e) => console.error(e),
      next: (doc) => dispatch(setCurrentUser(doc.data())),
    });
};

