import React, { useMemo, useState } from 'react';

import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

import AddItemsModal from './AddItemsModal';
import Input from '../../shared/Input';
import Button from '../../shared/Button';

import './styles.scss';

const BOMLookUpForm = (props) => {
  const {
    code,
    setCode,
    selectedItem,
    onSelect,
    onSubmit,
    loading,
  } = props;

  const [modalOpen, toggleModal] = useState(false);

  const itemCode = useMemo(() => {
    return selectedItem ? `${selectedItem?.code}, ${selectedItem?.description}` : '';
  }, [selectedItem]);

  return (
    <div className='bom-lookup-form__container'>
      <div className='bom-lookup-form__field'>
        <div>Item Code</div>
        <Input
          type='text'
          placeholder='Type Item Code'
          value={code}
          onChange={(e) => {
            setCode(e.target.value);
            onSelect(null);
          }}
        />
      </div>

      <div className='m-t-lg m-r-lg'>or</div>

      <div className='bom-lookup-form__field'>
        <div>Select Item</div>
        <div className='bom-lookup-form__select-box' onClick={() => toggleModal(true)}>
          {itemCode || 'Select'}
          <KeyboardArrowDownIcon style={{ fill: '#c6c8c9' }} />
        </div>

        <AddItemsModal
          open={modalOpen}
          onClose={() => toggleModal(false)}
          onSubmit={(selected) => {
            onSelect(selected);
            setCode('');
          }}
          title="Select Item"
        />
      </div>

      <div className='bom-lookup-form__button'>
        <Button
          onClick={onSubmit}
          disabled={loading}
          loading={loading}
          text='Run Report'
        />
      </div>
    </div>
  )
};

export default BOMLookUpForm;
