import { apiUrl } from '../config';

const AUTH_URL = 'auth';
const USERS_URL = 'users';
const DRIVE_URL = 'drive';
const CASES_URL = 'cases';
const HOSPITALS_URL = 'hospitals';
const SURGEONS_URL = 'surgeons';
const COUNTS_URL = 'counts';
const GOOGLE_SERVICES_URL = 'googleServices';
const KITS_URL = 'kits';
const SETS_URL = 'sets';
const TENANT_URL = 'tenant';
const ITEMS_URL = 'items';
// const FORMS_URL = 'forms';

const urls = {
  auth: {
    signUp: () => `${apiUrl}/${AUTH_URL}/sign-up`,
    login: () => `${apiUrl}/${AUTH_URL}/login`,
    resetPassword: () => `${apiUrl}/${AUTH_URL}/reset-password`,
    microsoftSignIn: () => `${apiUrl}/${AUTH_URL}/microsoft-login`
  },
  users: {
    create: () => `${apiUrl}/${USERS_URL}/create`,
    update: () => `${apiUrl}/${USERS_URL}/update`,
    sendInvite: () => `${apiUrl}/${USERS_URL}/send-invite`
  },
  drive: {
    search: (query = '', tenantId = '') => `${apiUrl}/${DRIVE_URL}/search?query=${query}&tenantId=${tenantId}`,
  },
  cases: {
    delete: (caseId, tenantId) => `${apiUrl}/${CASES_URL}/${caseId}?tenantId=${tenantId}`,
    exportSets: (caseId, tenantId) => `${apiUrl}/${CASES_URL}/export-sets/${caseId}?tenantId=${tenantId}`,
    exportUsage: (caseId, tenantId) => `${apiUrl}/${CASES_URL}/export-usage/${caseId}?tenantId=${tenantId}`,
    exportUsageSin7: (caseId, tenantId) => `${apiUrl}/${CASES_URL}/export-usage-sin7/${caseId}?tenantId=${tenantId}`,
    exportCases: (tenantId) => `${apiUrl}/${CASES_URL}/export-cases?tenantId=${tenantId}`,
    exportKitsReport: (tenantId) => `${apiUrl}/${CASES_URL}/export-kits?tenantId=${tenantId}`,
    exportChecklists: (caseId, tenantId) => `${apiUrl}/${CASES_URL}/export-checklists/${caseId}?tenantId=${tenantId}`,
    exportBilling: (tenantId) => `${apiUrl}/${CASES_URL}/export-billing?tenantId=${tenantId}`,
    exportUsageReport: (tenantId) => `${apiUrl}/${CASES_URL}/export-usage-report?tenantId=${tenantId}`,
    exportProforma: (caseId, tenantId) => `${apiUrl}/${CASES_URL}/export-proforma/${caseId}?tenantId=${tenantId}`,
    setsShippedNotification: (caseId, tenantId) => `${apiUrl}/${CASES_URL}/sets-shipped-notification/${caseId}?tenantId=${tenantId}`
  },
  hospitals: {
    delete: (hospitalId, tenantId) => `${apiUrl}/${HOSPITALS_URL}/${hospitalId}?tenantId=${tenantId}`,
  },
  surgeons: {
    delete: (surgeonId, tenantId) => `${apiUrl}/${SURGEONS_URL}/${surgeonId}?tenantId=${tenantId}`,
    export: (tenantId) => `${apiUrl}/${SURGEONS_URL}/export-surgeons?tenantId=${tenantId}`,
  },
  counts: {
    delete: (countId, tenantId) => `${apiUrl}/${COUNTS_URL}/${countId}?tenantId=${tenantId}`,
    export: (countId, tenantId) => `${apiUrl}/${COUNTS_URL}/export/${countId}?tenantId=${tenantId}`,
    exportReport: (tenantId) => `${apiUrl}/${COUNTS_URL}/export-report?tenantId=${tenantId}`,
    exportItemsReport: (tenantId) => `${apiUrl}/${COUNTS_URL}/export-items-report?tenantId=${tenantId}`,
  },
  googleServices: {
    getMapsApiKey: () => `${apiUrl}/${GOOGLE_SERVICES_URL}/get-maps-api-key`,
    getPlaceDetails: (placeId) => `${apiUrl}/${GOOGLE_SERVICES_URL}/get-place-details?placeId=${placeId}`,
  },
  kits: {
    exportBOM: (kitId, tenantId) => `${apiUrl}/${KITS_URL}/export-bom/${kitId}?tenantId=${tenantId}`,
    exportBOMExcel: (kitId, tenantId) => `${apiUrl}/${KITS_URL}/export-bom-excel/${kitId}?tenantId=${tenantId}`,
  },
  sets: {
    exportTurnsReport: (tenantId) => `${apiUrl}/${SETS_URL}/export-turns-report?tenantId=${tenantId}`,
    exportItems: (tenantId) => `${apiUrl}/${SETS_URL}/export-items?tenantId=${tenantId}`,
    exportBatchItems: (tenantId) => `${apiUrl}/${SETS_URL}/export-batch-items?tenantId=${tenantId}`,
    exportExpiringItems: (tenantId) => `${apiUrl}/${SETS_URL}/export-expiring-items?tenantId=${tenantId}`,
    exportLookupItems: (tenantId) => `${apiUrl}/${SETS_URL}/export-lookup-items?tenantId=${tenantId}`,
    exportSetSummary: (tenantId) => `${apiUrl}/${SETS_URL}/export-set-summary?tenantId=${tenantId}`,
  },
  tenant: {
    getStorageData: (tenantId) => `${apiUrl}/${TENANT_URL}/get-storage-data/${tenantId}`,
    internalReport: () => `${apiUrl}/${TENANT_URL}/get-internal-report`
  },
  forms: {
    // exportForms: (tenantId) => `${apiUrl}/${FORMS_URL}/export-forms?tenantId=${tenantId}`
    exportForms: (tenantId) => `${apiUrl}/${COUNTS_URL}/export-forms?tenantId=${tenantId}`
  },
  items: {
    getAll: (tenantId) => `${apiUrl}/${ITEMS_URL}/get-all?tenantId=${tenantId}`,
    getPaginatedItems: (tenantId) => `${apiUrl}/${ITEMS_URL}/get-paginated-items?tenantId=${tenantId}`,
    getInfiniteItems: (tenantId) => `${apiUrl}/${ITEMS_URL}/get-infinite-items?tenantId=${tenantId}`
  }
};

export default urls;
