import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { UsageReportingTable, UsageReportingForm } from '../../../components/reporting';
import LoadScreen from '../../../components/load-screen';

import { useLoading, useAlert } from '../../../hooks';

import { getCompletedCases, getUsageReportingData } from '../../../actions/casesActions';
import { getSurgeons } from '../../../actions/surgeonsActions';

import { countStatuses } from '../../../constants/enums';

import './reporting-page.scss';

// const filteredFields = ['hospitalName', 'location', 'userName'];
// const getInitialFilters = () => {
//   const initialFilter = {};
//
//   filteredFields?.forEach((field) => {
//     initialFilter[field] = [];
//   });
//
//   return initialFilter;
// };

const UsageReportingPage = () => {
  const dispatch = useDispatch();
  const counts = useSelector((state) => state?.counts?.list?.filter((count) => count.status === countStatuses.COMPLETED.value));
  const hospitals = useSelector((state) => state?.hospitals?.list);
  const items = useSelector((state) => state?.items?.list);
  const surgeons = useSelector((state) => state?.surgeons?.list);
  const tenantColor = useSelector((state) => state?.tenant?.currentTenant?.colorPrimary);

  const { loading, startLoading, stopLoading } = useLoading();
  const { showAlert } = useAlert();

  const [fetching, setFetching] = useState(false);
  const [usage, setUsage] = useState([]);

  useEffect(() => {
    onLoad().catch((err) => console.error(err));
  }, []);

  const onLoad = async () => {
    startLoading()
    try {
      await Promise.all([
        dispatch(getSurgeons()),
        dispatch(getCompletedCases()),
      ]);
    } catch (err) {
      console.error(err);
    } finally {
      stopLoading();
    }
  };

  const onSubmit = async (data, submittedDate) => {
    setFetching(true);
    try {
      const res = await dispatch(getUsageReportingData(data, submittedDate));
      if (res) {
        if (!res?.length) {
          showAlert('warning', 'No case usage according to these filters');
          setUsage([]);
        } else {
          setUsage(res);
        }
      }
    } catch (err) {
      console.error(err);
    } finally {
      setFetching(false);
    }
  };

  const types = useMemo(() => {
    const result = [];
    return result || [];
  }, [counts]);

  return (
    <div className='page-container reporting-page__container'>
      {loading && <LoadScreen />}
      <div className='page-title'>Usage Reporting</div>
      <div className='reporting-page__body'>
        <UsageReportingForm
          surgeonsList={surgeons}
          hospitalsList={hospitals}
          types={types}
          onSubmit={onSubmit}
          loading={fetching}
        />

        <UsageReportingTable
          usage={usage}
          hospitals={hospitals}
          surgeons={surgeons}
          tenantColor={tenantColor}
          items={items}
        />
      </div>
    </div>
  );
};

export default UsageReportingPage;
