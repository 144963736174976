import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';
import CaseReportingIcon from '../../../assets/icons/CaseReportingIcon';
// import CaseIcon from '@material-ui/icons/Assignment';
// import KitsIcon from '@material-ui/icons/Storage';
// import BillingIcon from '@material-ui/icons/Receipt';
// import InventoryIcon from '@material-ui/icons/ListAlt';
// import ItemsIcon from '@material-ui/icons/VerticalSplit';
// import UsageIcon from '@material-ui/icons/HowToVote';
// import SetIcon from '@material-ui/icons/ViewQuilt';
// import ConsignedIcon from '@material-ui/icons/Label';

import RestrictComponent from '../../../components/shared/RestrictComponent';

import { routes } from '../../../constants';
import { roleNames } from '../../../constants/userRoles';

const { ADMIN, OPERATIONS } = roleNames;

const options = [
  {
    title: 'Case Reporting',
    subTitle: 'Reporting for booked cases',
    icon: <CaseReportingIcon className='option-icn' />,
    url: routes.CASE_REPORTING,
    roles: [],
  },
  {
    title: 'Kit Reporting',
    subTitle: 'Kits shipped report',
    icon: <CaseReportingIcon className='option-icn' />,
    url: routes.KIT_REPORTING,
    roles: [],
  },
  {
    title: 'Billing Reporting',
    subTitle: 'Review total value of completed cases',
    icon: <CaseReportingIcon className='option-icn' />,
    url: routes.BILLING_REPORTING,
    roles: [],
  },
  {
    title: 'Count Reporting',
    subTitle: 'View completed count report',
    icon: <CaseReportingIcon className='option-icn' />,
    url: routes.COUNT_REPORTING,
    roles: [],
  },
  {
    title: 'Items Counted Reporting',
    subTitle: 'View complete list of counted items',
    icon: <CaseReportingIcon className='option-icn' />,
    url: routes.ITEMS_REPORTING,
    roles: [],
  },
  {
    title: 'Usage Reporting',
    subTitle: 'Reporting for case usage',
    icon: <CaseReportingIcon className='option-icn' />,
    url: routes.USAGE_REPORTING,
    roles: [],
  },
  {
    title: 'Set Turns Reporting',
    subTitle: 'Reporting for set life-time turns',
    icon: <CaseReportingIcon className='option-icn' />,
    url: routes.SET_TURNS_REPORTING,
    roles: [],
  },
  {
    title: 'Consignment Reporting',
    subTitle: 'Reporting for consigned set life-time turns',
    icon: <CaseReportingIcon className='option-icn' />,
    url: routes.CONSIGNMENT_TURNS_REPORTING,
    roles: [],
  },
  {
    title: 'Expiring Items Reporting',
    subTitle: 'Reporting for expiring items',
    icon: <CaseReportingIcon className='option-icn' />,
    url: routes.EXPIRING_ITEMS_REPORTING,
    roles: [],
  },
  {
    title: 'BOM Item Lookup',
    subTitle: 'Reporting for items within BOM’s',
    icon: <CaseReportingIcon className='option-icn' />,
    url: routes.BOM_LOOKUP_REPORTING,
    roles: [],
  },
  {
    title: 'Management Reports',
    subTitle: 'Full suite of department reporting',
    icon: <CaseReportingIcon className='option-icn' />,
    url: routes.MANAGEMENT_REPORTS,
    roles: [],
  },
  {
    title: 'Set Summary',
    subTitle: 'Reporting on set information',
    icon: <CaseReportingIcon className='option-icn' />,
    url: routes.SET_SUMMARY_REPORTING,
    roles: [],
  },
];

const ReportingPage = ({ currentTenant }) => {
  return (
    <div className='page-container'>
      <span className='page-title'>Reporting</span>

      <div className='settings-body'>
        <Grid container spacing={1}>
          {
            options?.map((option) => (
              <RestrictComponent key={option.title} roles={[ADMIN, OPERATIONS, ...option.roles]}>
                <Grid item xs={12} sm={12} md={4}>
                  <Link to={option.url}>
                    <div
                      className='settings-option'
                      key={`${option.title}_${Math.random()}`}
                      style={{ '--colorHover': `${currentTenant.colorPrimary}` }}
                    >
                      <span className='option-title'>{option.title}</span>
                      <span className='option-sub-t'>{option.subTitle}</span>
                      <div className='option-icn-cnt'>
                        <span>{option.icon}</span>
                      </div>
                    </div>
                  </Link>
                </Grid>
              </RestrictComponent>
            ))
          }
        </Grid>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    currentTenant: state.tenant.currentTenant,
  };
};

export default connect(mapStateToProps)(ReportingPage);
