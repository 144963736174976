import React, { useState, useMemo, useEffect } from 'react';
import { debounce } from 'lodash';
import { Column, Table, SortDirection } from 'react-virtualized';
import { useDispatch } from 'react-redux';

import { CircularProgress } from '@material-ui/core';
import TablePagination from '@material-ui/core/TablePagination';
import RemoveIcon from '@material-ui/icons/Remove';
import AddIcon from '@material-ui/icons/Add';

import Checkbox from '../../shared/Checkbox';
import Button from '../../shared/Button';
import Modal from '../../shared/modal/Modal';
import Input from '../../shared/Input';
import Select from '../../shared/Select';

import { getPaginatedItems } from '../../../actions/itemsActions';

import { itemTypeOptions, setPositionOptions } from '../../../constants/enums';

import 'react-virtualized/styles.css';

const AddButton = ({
  variant,
  disabled,
  onClick,
  color,
}) => (
  <Button
    type="outlined"
    height={25}
    width={25}
    disabled={disabled}
    onClick={onClick}
    borderColor={color}
  >
    {variant === 'minus' ? (
      <RemoveIcon fontSize="small" style={{ fill: disabled ? '#cccccc' : color }} />
    ) : (
      <AddIcon fontSize="small" style={{ fill: disabled ? '#cccccc' : color }} />
    )}
  </Button>
);

const AddItemsModal = (props) => {
  const {
    onSubmit,
    onClose,
    open,
    disabledItems,
    loading,
    withManual,
    onManualClick,
    title,
  } = props;

  const dispatch = useDispatch();

  const [items, setItems] = useState([]);
  const [selected, setSelected] = useState([]);
  const [fetching, setFetching] = useState(false);

  const [sortBy, setSortBy] = useState('code');
  const [sortDirection, setSortDirection] = useState(SortDirection.ASC);
  const [filters, setFilters] = useState({ type: '' });
  const [search, setSearch] = useState('');

  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [numberOfDocs, setNumberOfDocs] = useState(0);
  const [, setTotalPages] = useState(1);

  const debouncedOnChange = useMemo(() => {
    return debounce((value) => setFilters({ ...filters, code: value }), 500);
  }, [setFilters]);

  useEffect(() => {
    fetchItems().catch();
  }, [sortBy, sortDirection, filters, open, currentPage, rowsPerPage]);

  const handleSearch = (e) => {
    setSearch(e.target.value);
    debouncedOnChange(e.target.value);
  };

  const fetchItems = async () => {
    try {
      if (!open) {
        return;
      }

      setFetching(true);

      const res = await dispatch(getPaginatedItems({
        page: currentPage,
        docsPerPage: rowsPerPage,
        orderBy: sortBy,
        orderDirection: sortDirection?.toLowerCase(),
        filters,
      }));

      setNumberOfDocs(res?.count);
      setTotalPages(Math.ceil(res?.count / rowsPerPage));
      setItems(res?.items?.length ? res?.items : []);
    } catch (err) {
      console.error(err);
    } finally {
      setFetching(false);
    }
  };

  const handleCheck = (item) => {
    let temp = selected.slice();

    if (temp?.map((i) => i.id)?.includes(item.id)) {
      temp = temp.filter((i) => i.id !== item.id);
    } else {
      temp = [...temp, { ...item, quantity: 1 }];
    }

    setSelected(temp);
  };

  const onMinusClick = (row) => {
    const temp = selected.slice();
    const item = temp?.find((i) => i.id === row.id);
    if (!item || item.quantity <= 1) {
      return;
    }

    item.quantity = item.quantity - 1;
    setSelected(temp);
  };

  const onPlusClick = (row) => {
    const temp = selected.slice();
    const item = temp?.find((i) => i.id === row.id);
    if (!item) {
      return;
    }

    item.quantity = item.quantity + 1;
    setSelected(temp);
  };

  const handleClose = () => {
    onClose();
    setSelected([]);
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      size="lg"
    >
      <div className="form__container section-items-modal__container">
        <div className="form__body">
          <div className="form__subtitle">
            {title || 'Add Items'}
          </div>

          <div className="section-items-modal__filters">
            <div className="m-r-md">
              <Input
                type="search"
                placeholder="Search"
                value={search}
                onChange={handleSearch}
              />
            </div>

            <div className="m-l-md">
              <Select
                value={filters.type}
                onChange={(value) => setFilters({ ...filters, type: value })}
                options={[{ label: 'All', value: '' }, ...Object.values(itemTypeOptions)]}
              />
            </div>
          </div>

          <div className="secondary font-size-sm m-b-sm">Items</div>
          <div className="m-b-lg form__select-users" style={{ minWidth: 860, minHeight: 540, position: 'relative' }}>
            {fetching && (
              <div
                className='d-flex p-lg flex-center align-center'
                style={{
                  position: 'absolute',
                  height: 'calc(100% - 32px)',
                  width: 'calc(100% - 32px)',
                  background: 'rgba(227, 230, 233, 0.52)',
                  zIndex: 1000,
                  borderRadius: '8px'
                }}
              >
                <CircularProgress size={40} />
              </div>
            )}
            {items?.length ? (
              <VirtualizedTable
                data={items?.map((item) => ({
                  ...item,
                  description: item.checklistDescription || item.description || '',
                  type: itemTypeOptions[item.type]?.label
                }))}
                checked={selected}
                onCheck={handleCheck}
                onMinusClick={onMinusClick}
                onPlusClick={onPlusClick}
                sortBy={sortBy}
                setSortBy={setSortBy}
                sortDirection={sortDirection}
                setSortDirection={setSortDirection}
                disabledItems={disabledItems}
              />
            ) : (
              <div className="text-center secondary p-md">
                No Items
              </div>
            )}
          </div>

          <TablePagination
            rowsPerPageOptions={[50, 100, 200]}
            count={numberOfDocs || items.length}
            page={currentPage}
            rowsPerPage={rowsPerPage}
            onPageChange={(e, value) => setCurrentPage(value)}
            onRowsPerPageChange={(e, value) => setRowsPerPage(value)}
            style={{ padding: '0 4px 4px 4px' }}
          />

          <div className="form__actions m-t-lg">
            <div className='d-flex'>
              {withManual && (
                <div className='m-r-lg'>
                  <Button
                    type="outlined"
                    text="Manual"
                    onClick={onManualClick}
                  />
                </div>
              )}
              <Button
                type="submit"
                text={title || "Add Items"}
                loading={loading}
                disabled={!selected || loading}
                onClick={async () => {
                  await onSubmit(selected);
                  setSelected([]);
                }}
              />
            </div>
            <Button
              type="cancel"
              text="Cancel"
              loading={loading}
              disabled={loading || !selected}
              onClick={onClose}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

const VirtualizedTable = (props) => {
  const {
    data,
    checked,
    disabledItems,
    onCheck,
    onMinusClick,
    onPlusClick,
    sortBy,
    setSortBy,
    sortDirection,
    setSortDirection
  } = props;

  const handleSort = (params) => {
    setSortBy(params.sortBy);
    setSortDirection(params.sortDirection);
  };

  return (
    <div style={{ width: '100%', height: '100%' }}>
      <Table
        width={860} // Width of the table
        height={500} // Height of the table
        headerHeight={36} // Height of the table header
        rowHeight={36} // Height of each table row
        rowCount={data.length} // Total number of rows
        rowGetter={({ index }) => data[index]} // Function to get the data for each row
        sort={handleSort}
        sortBy={sortBy}
        sortDirection={sortDirection}
      >
        <Column
          label=""
          disableSort
          dataKey="id"
          width={50}
          headerRenderer={({ label, sortBy, sortDirection }) => (
            <div>
              {/*<Checkbox*/}
              {/*  input={{*/}
              {/*    onClick: (e) => onCheckAll(e.target.checked),*/}
              {/*    checked: data.every((item) => checked?.includes(item.id)),*/}
              {/*  }}*/}
              {/*  fontSize="small"*/}
              {/*/>*/}
            </div>
          )}
          cellRenderer={({ rowIndex, rowData }) => {
            return (
              <div className='p-l-md'>
                <Checkbox
                  input={{
                    onClick: () => onCheck(rowData),
                    checked: !!checked?.map((i) => i.id)?.includes(data[rowIndex].id) || !!disabledItems?.includes(data[rowIndex].id),
                  }}
                  disabled={!!disabledItems?.includes(data[rowIndex].id)}
                  index={rowIndex}
                  fontSize="small"
                />
              </div>
            )
          }}
        />
        <Column
          label="Code"
          dataKey="code"
          width={200}
          headerRenderer={({ label, sortBy, sortDirection }) => (
            <div>
              {label}
              {sortBy === 'code' && (
                <SortCaret direction={sortDirection}/>
              )}
            </div>
          )}
        />
        <Column
          label="Description"
          dataKey="description"
          width={500}
          headerRenderer={({ label, sortBy, sortDirection }) => (
            <div>
              {label}
              {sortBy === 'description' && (
                <SortCaret direction={sortDirection}/>
              )}
            </div>
          )}
        />
        <Column
          label="Type"
          dataKey="type"
          width={150}
          headerRenderer={({ label, sortBy, sortDirection }) => (
            <div>
              {label}
              {sortBy === 'type' && (
                <SortCaret direction={sortDirection}/>
              )}
            </div>
          )}
        />
        <Column
          label=""
          dataKey="quantity"
          width={150}
          cellRenderer={({ rowData }) => (
            !rowData?.allowQuantity && checked?.map((i) => i.id)?.includes(rowData.id) ? (
              <div className="d-flex flex-end">
                <AddButton
                  variant="minus"
                  onClick={() => onMinusClick(rowData)}
                  color={setPositionOptions.CONSIGNED.color}
                />
                <div
                  style={{
                    color: setPositionOptions.CONSIGNED.color,
                    textAlign: 'center',
                    marginLeft: 4,
                    marginRight: 4,
                    width: 25,
                    fontWeight: 'bold'
                  }}
                >
                  {checked?.find((i) => i.id === rowData.id)?.quantity || 1}
                </div>
                <AddButton
                  variant="plus"
                  onClick={() => onPlusClick(rowData)}
                  color={setPositionOptions.CONSIGNED.color}
                />
              </div>
            ) : null
          )}
        />
      </Table>
    </div>
  );
};

const SortCaret = ({ direction }) => {
  return (
    <span style={{ marginLeft: '5px' }}>
      {direction === SortDirection.ASC ? '▼' : '▲'}
    </span>
  );
};

export default AddItemsModal;
