import React, { useMemo, useCallback } from 'react';
import moment from 'moment';

import Table from '../../shared/table/Table';

import { setAllocationStatuses, setPositionOptions } from '../../../constants/enums';
import SetIcon from '../../../assets/icons/SetIcon';
import ActionButton from '../../shared/ActionButton';
import ExportIcon from '../../../assets/icons/DownloadIcon';

const SetSummaryTable = (props) => {
  const {
    sets,
    onCaseIdClick = () => {},
    tenantColor,
    kits = [],
    hospitals = [],
    cases = [],
    labels = [],
    onExportClick,
    exportLoading,
  } = props;

  const columns = [
    { title: 'set number', field: 'number', type: 'default', sort: true },
    { title: 'kit id', field: 'kitId', type: 'default', sort: true },
    { title: 'kit name', field: 'kitName', type: 'default', sort: true },
    { title: 'containers', field: 'containerSize', type: 'default', sort: true },
    { title: 'position', field: 'position', type: 'setPosition', sort: true },
    { title: 'set turns', field: 'turns', type: 'default', sort: true },
    {
      title: 'allocation', field: 'caseAllocation', type: 'custom', formatter: (value, row) => (
        <span
          style={{color: tenantColor}}
          onClick={() => onCaseIdClick(value)}
        >
          {row.formattedCaseId}
        </span>
      ),
    },
    { title: 'case date', field: 'caseDate', type: 'date', sort: true },
    { title: 'hospital', field: 'hospital', type: 'default', sort: true },
    { title: 'labels', field: 'labelNames', type: 'default' },
  ];

  const getKitId = useCallback((kitId) => {
    const kit = kits.find((item) => item.id === kitId);
    return kit ? kit.kitId : '';
  }, [sets, kits]);

  const getKitName = useCallback((kitId) => {
    const kit = kits.find((item) => item.id === kitId);
    return kit ? kit.name : '';
  }, [sets, kits]);

  const getHospital = useCallback((caseId, row) => {
    if (row?.position === setPositionOptions.CONSIGNED.value) {
      const hospitalId = row?.consignment?.hospital;
      const caseHospital = hospitals?.find((hospital) => hospital.id === hospitalId);
      return caseHospital && caseHospital.name ? caseHospital.name : '';
    } else {
      const caseAllocation = cases?.find((item) => item.id === caseId);
      if (!caseAllocation) {
        return null;
      }

      const caseHospital = hospitals.find((hospital) => hospital.id === caseAllocation.hospital);

      return caseHospital && caseHospital.name ? caseHospital.name : '';
    }
  }, [sets, cases, kits, hospitals]);

  const getCaseDate = useCallback((caseId, row) => {
    if (row?.position !== setPositionOptions.CONSIGNED.value) {
      const caseAllocation = cases?.find((item) => item.id === caseId);
      if (!caseAllocation) {
        return null;
      }

      return caseAllocation.date;
    } else {
      return null;
    }
  }, [sets, cases,]);

  const getLabelNames = (labelIds = []) => (
    labelIds.map((id) => {
      const label = labels.find((item) => item.id === id);
      return label ? label.name : '';
    })?.join(', ')
  );

  const getPosition = (set) => {
    if (set.quarantined) {
      return setPositionOptions.QUARANTINED.value;
    }
    if (set.consigned) {
      return setPositionOptions.CONSIGNED.value;
    }
    return set.caseAllocation ? setPositionOptions.ALLOCATED.value : setPositionOptions.AVAILABLE.value;
  };

  const formatCaseId = (caseId) => {
    const arr = caseId?.split('-');
    return arr?.length > 1 ? `${arr[0]}-${arr[1]}` : caseId;
  };

  const isTargetReached = (set) => {
    const { kit: kitId, targetTurns } = set;
    const kit = kits?.find((k) => k.id === kitId);
    const target = kit?.target;

    return target && Math.round((targetTurns / target.value) * 100) === 100
  };

  const isReviewSoon = (set) => {
    if (!set.consigned || !set.consignment) {
      return false;
    }
    const reviewDate = moment(set.consignment.reviewDate);
    return reviewDate.diff(moment(), 'weeks') < 8;
  };

  const rows = useMemo(() => (
    sets
      .map((set) => ({
        ...set,
        kitId: getKitId(set.kit),
        kitName: getKitName(set.kit),
        position: getPosition(set),
        caseAllocation: set.caseAllocation,
        formattedCaseId: formatCaseId(set.caseAllocation),
      }))
  ), [sets, cases, kits]);

  const data = rows.map((row) => ({
    ...row,
    hospital: getHospital(row.caseAllocation, row),
    labelNames: getLabelNames(row.labels || []),
    warning: isTargetReached(row) || isReviewSoon(row),
    caseDate: getCaseDate(row.caseAllocation, row),
  }));

  return (
    <div className='cases-table__container'>
      <div className='d-flex m-b-lg space-between align-start'>
        <div className='d-flex'>
          <SetIcon />
          <div className='font-size-bg font-bold m-l-md'>
            Sets
          </div>
        </div>

        <ActionButton
          onClick={() => onExportClick(data)}
          bgColor={setAllocationStatuses.SHIPPED.color}
          disabled={exportLoading}
          loading={exportLoading}
        >
          <ExportIcon color="#ffffff" />
          Export
        </ActionButton>
      </div>

      <Table
        rows={data}
        columns={columns}
        additionalData={{
          onClick: onCaseIdClick,
          tenantColor,
        }}
        pagination
        checkboxes={false}
        noCheckAll
        rowColor='#ffffff'
        rowBorderColor='#fafafa'
      />
    </div>
  );
};

export default SetSummaryTable;
