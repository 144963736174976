import React from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

import ParentKitItemRow from './ParentKitItemRow';
import Checkbox from '../../shared/Checkbox';

import './parent-kit.scss';

const ParentKitItemsTable = ({ items = [], checked = [], onCheck, onCheckAll, onQuantityChange, onReorder, readOnly }) => {
  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    const newItems = [...items];
    const [removed] = newItems.splice(result.source.index, 1);
    newItems.splice(result.destination.index, 0, removed);
    onReorder(newItems);
  }

  return (
    <table className="parent-kit-items-table">
      <thead>
      <tr>
        {!readOnly && (
          <th style={{ width: '30px' }}>
            <Checkbox
              input={{
                checked: !!items?.length && items?.every((i) => checked.includes(i.id)),
                onChange: onCheckAll
              }}
              fontSize='small'
            />
          </th>
        )}
        <th style={{ width: '50px' }}></th>
        <th>Kit/Item</th>
        <th>Name/Description</th>
        <th>Type</th>

        {!readOnly && (
          <>
            <th style={{ textAlign: 'center' }}>QTY</th>
            <th />
          </>
        )}
      </tr>
      </thead>

      {
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId='droppable'>
            {(provided) => (
              <tbody
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
              {items.map((item, index) => (
                <Draggable key={`${item.id}_${Math.random() * Math.random()}`} draggableId={item.id} index={index}>
                  {(provided, snapshot) => (
                    <ParentKitItemRow
                      key={item.id}
                      item={item}
                      checked={!!checked?.includes(item.id)}
                      onCheck={onCheck}
                      onQuantityChange={onQuantityChange}
                      isDragging={snapshot.isDragging}
                      provided={provided}
                      readOnly={readOnly}
                    />
                  )}
                </Draggable>
              ))}
              </tbody>)}
          </Droppable>
        </DragDropContext>
      }
    </table>
  )
};

export default ParentKitItemsTable;