import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import moment from 'moment';

import CircularProgress from '@material-ui/core/CircularProgress';

import Input from '../../../components/shared/Input';
import ConfirmationModal from '../../../components/shared/modal/ConfirmationModal';
import LoadScreen from '../../../components/load-screen';
import SetsTable from '../../../components/sets/SetsTable';
import QuarantineForm from '../../../components/sets/QuarantineForm';
import QuarantineModal from '../../../components/sets/QuarantineModal';
import ConsignmentModal from '../../../components/sets/ConsignmentModal';
import FilterButton from '../../../components/sets/FilterButton';
import ConsignmentNoteModal from '../../../components/sets/ConsignmentNoteModal';

import { getCasesWithSetsAllocation, setCaseView } from '../../../actions/casesActions';
import {
  subscribeToSets,
  updateSet,
  subscribeToSetLabels,
  resetTargetTurns,
  onConsignmentDocumentUploadComplete,
  deleteConsignmentDocumentFile,
  getSet,
  getConsignedSets,
} from '../../../actions/setsActions';
import { getKits, getSections } from '../../../actions/kitsActions';
import { getItemsByIds } from '../../../actions/itemsActions';

import { useLoading, useAlert } from '../../../hooks';

import routes from '../../../constants/routes';
import { billOfMaterialItemTypes, setPositionOptions } from '../../../constants/enums';

import './sets-page.scss';

const SetsPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const userRole = useSelector((state) => state.user.currentUser.role);
  const hospitals = useSelector((state) => state?.hospitals.list);
  const sets = useSelector((state) => state?.sets.list?.filter((set) => !!set.active));
  const labels = useSelector((state) => state?.sets.labels);
  const kits = useSelector((state) => state?.kits.list);
  const tenantColor = useSelector((state) => state?.tenant.currentTenant.colorPrimary);
  const tenantId = useSelector((state) => state?.tenant.currentTenant.id);

  const [itemsList, setItemsList] = useState([]);

  const [cases, setCases] = useState([]);
  const [items, setItems] = useState([]);
  const [setsSearch, setSetsSearch] = useState('');
  const [files, setFiles] = useState([]);

  const [selectedSet, selectSet] = useState(null);
  const [statusFilter, setStatusFilter] = useState({
    [setPositionOptions.AVAILABLE.value]: true,
    [setPositionOptions.ALLOCATED.value]: true,
    [setPositionOptions.QUARANTINED.value]: true,
    [setPositionOptions.CONSIGNED.value]: false,
  });

  const [quarantineLoading, setQuarantineLoading] = useState(false);
  const [consignLoading, setConsignLoading] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [resetLoading, setResetLoading] = useState(false);
  const [noteLoading, setNoteLoading] = useState(false);

  const [quarantineModal, toggleQuarantineModal] = useState(false);
  const [availableModal, toggleAvailableModal] = useState(false);
  const [consignModal, toggleConsignModal] = useState(false);
  const [noteModal, toggleNoteModal] = useState(false);

  const [consignedLoaded, setConsignedLoaded] = useState(false);

  const { loading, startLoading, stopLoading } = useLoading();
  const { showAlert } = useAlert();

  useEffect(() => {
    const unsubscribe = dispatch(subscribeToSets());
    const unsubscribeToLabels = dispatch(subscribeToSetLabels());

    onLoad().catch((err) => console.error(err));

    return () => {
      unsubscribe();
      unsubscribeToLabels();
    };
  }, []);

  // useEffect(() => {
  //   if (file && selectedSet) {
  //     onUploadDocument(file).catch(console.error);
  //   }
  // }, [file]);

  // const onUploadDocument = async (document) => {
  //   const consignmentDocument = await dispatch(uploadDocument(selectedSet.id, document));
  //   if (consignmentDocument) {
  //     selectSet((prevState) => ({ ...prevState, consignmentDocument }));
  //     setFile(null);
  //   }
  // };

  const onLoad = async () => {
    startLoading()
    try {
      const promises = [];
      promises.push(loadCases());
      if (!kits?.length) {
        promises.push(dispatch(getKits()));
      }
      await Promise.all(promises);
    } catch (err) {
      console.error(err);
    } finally {
      stopLoading();
    }
  };

  const loadCases = async () => {
    const resp = await dispatch(getCasesWithSetsAllocation());
    setCases(resp.filter((item) => !!item.setsAllocation.length));
  };

  const onSelectSet = async (set) => {
    setFetching(true);
    setFiles([]);

    try {
      let consignmentDocuments = [];
      if (set.consigned) {
        const res = await dispatch(getSet(set.id));
        if (res && res.consignmentDocuments?.length) {
          consignmentDocuments = res.consignmentDocuments;
        }
      }
      const res = await dispatch(getSections(set.kit));
      const arr = [];

      let _items = [];
      for (const section of res) {
        const res = await dispatch(getItemsByIds(section.items?.filter((i) => i.type !== billOfMaterialItemTypes.NOTES.value)?.map((i) => i.id)));
        _items = [
          ..._items,
          ...res
        ];
      }
      setItemsList(_items);

      res?.forEach((section) => {
        section?.items?.forEach((item) => {
          const _item = _items?.find((i) => i.id === item.id);
          if (!arr?.find((i) => i.id === item.id)) {
            arr?.push({ ...item, batchControl: !!_item?.batchControl });
          }
        })
      });
      setItems(arr);
      selectSet({
        ...set,
        billOfMaterial: !!res?.length,
        batchItemsLength: arr?.filter((item) => !!item.batchControl)?.length,
        consignmentDocuments,
      });
    } catch (err) {
      console.error(err);
    } finally {
      setFetching(false);
    }
  }

  const onStatusFilterChange = (key) => {
    setStatusFilter({ ...statusFilter, [key]: !statusFilter[key], [setPositionOptions.CONSIGNED.value]: false });
  };

  const onConsignedClick = async () => {
    setStatusFilter({
      [setPositionOptions.AVAILABLE.value]: false,
      [setPositionOptions.ALLOCATED.value]: false,
      [setPositionOptions.QUARANTINED.value]: false,
      [setPositionOptions.CONSIGNED.value]: true,
    });

    if (!consignedLoaded) {
      await dispatch(getConsignedSets());
      setConsignedLoaded(true);
    }
  };

  const onViewClick = (caseId) => {
    dispatch(setCaseView('SETS'));
    history.push(`${routes.CASES}/${caseId}`);
  };

  const onQuarantineClick = () => {
    toggleQuarantineModal(true);
  };

  const onAvailableClick = () => {
    toggleAvailableModal(true);
  };

  const onSubmit = (items, note) => {
    setQuarantineLoading(true);
    try {
      const doc = { quarantined: true, quarantineNote: note, quarantineItems: items };
      dispatch(updateSet(selectedSet?.id, doc));
      selectSet({
        ...selectedSet,
        position: setPositionOptions.QUARANTINED.value,
        quarantineNote: note,
        quarantineItems: items,
      });
      toggleQuarantineModal(false);
    } catch (err) {
      console.error(err);
    } finally {
      setQuarantineLoading(false);
    }
  };

  const onConsignSubmit = async (hospital, location, period, note) => {
    setConsignLoading(true);
    try {
      const doc = {
        consigned: true,
        consignment: {
          hospital: hospital.id,
          location: location || '',
          period: period || null,
          note: note || '',
          consignedDate: moment(),
          reviewDate: moment().add(period, 'month')
        },
      };
      await dispatch(updateSet(selectedSet?.id, doc));
      selectSet({
        ...selectedSet,
        position: setPositionOptions.CONSIGNED.value,
        consignment: {
          hospital: hospital.id,
          location: location || '',
          period: period || null,
          note: note || '',
          consignedDate: moment(),
          reviewDate: moment().add(period, 'month')
        },
      });
      // await dispatch(getConsignedSets());

      toggleConsignModal(false);
      showAlert('success', `Kit consigned to ${hospital.name}`);
    } catch (err) {
      console.error(err);
    } finally {
      setConsignLoading(false);
    }
  };

  const onMakeAvailable = () => {
    setQuarantineLoading(true);
    try {
      const doc = {
        quarantined: false,
        consigned: false,
        quarantineNote: '',
        quarantineItems: [],
        consignment: null,
      };
      dispatch(updateSet(selectedSet?.id, doc));
      selectSet({
        ...selectedSet,
        position: setPositionOptions.AVAILABLE.value,
        quarantined: false,
        consigned: false,
        quarantineNote: '',
        quarantineItems: [],
        consignment: null,
      });
      toggleAvailableModal(false);
    } catch (err) {
      console.error(err);
    } finally {
      setQuarantineLoading(false);
    }
  };

  const onNoteSubmit = async (note) => {
    if (selectedSet && selectedSet.consignment) {
      setNoteLoading(true);
      try {
        const doc = {
          consignment: {
            ...selectedSet.consignment,
            note: note || '',
          },
        };
        await dispatch(updateSet(selectedSet?.id, doc));
        // if (selectedSet.consigned) {
        //   await dispatch(getConsignedSets());
        // }
        selectSet({
          ...selectedSet,
          consignment: {
            ...selectedSet.consignment,
            note: note || '',
          },
        });
        toggleNoteModal(false);
        showAlert('success', 'Note successfully updated');
      } catch (err) {
        console.error(err);
      } finally {
        setNoteLoading(false);
      }
    }
  };

  const onUpload = (newFiles) => {
    setFiles([
      ...files,
      ...newFiles.filter((file) => !files.map((f) => f.name).includes(file.name))
    ]);
  };

  const onUploadComplete = (fileName, downloadUrl) => {
    const arr = [...files];
    const file = arr.find((item) => item.name === fileName);
    if (file) {
      file.downloadUrl = downloadUrl;
      setFiles(arr);

      dispatch(onConsignmentDocumentUploadComplete(selectedSet, file));

      // if (selectedSet.consigned) {
      //   dispatch(getConsignedSets());
      // }
    }
  };

  const onRemove = (fileName) => {
    setFiles([...files].filter((item) => item.name !== fileName));
    const documents = selectedSet?.consignmentDocuments?.filter((f) => f.fileName !== fileName);
    dispatch(updateSet(selectedSet?.id,  { consignmentDocuments: documents }));
  };

  const onDeleteFile = (fileName) => {
    if (selectedSet) {
      dispatch(deleteConsignmentDocumentFile(selectedSet, fileName));
      const documents = selectedSet?.consignmentDocuments?.filter((f) => f.fileName !== fileName);
      selectSet({ ...selectedSet, consignmentDocuments: documents });
      // if (selectedSet.consigned) {
      //   dispatch(getConsignedSets());
      // }
    }
  };

  const getHospitalName = useCallback((hospitalId) => {
    const hospital = hospitals.find((hospital) => hospital.id === hospitalId);
    return hospital ? hospital.name : '';
  }, [hospitals]);

  const list = useMemo(() => (
    sets.filter((set) => {
      let position = set.caseAllocation ? setPositionOptions.ALLOCATED.value : setPositionOptions.AVAILABLE.value;

      if (set.consigned) {
        position = setPositionOptions.CONSIGNED.value;
      }

      if (set.quarantined) {
        position = setPositionOptions.QUARANTINED.value;
      }

      return !!statusFilter[position];
    })
      .map((item) => ({ ...item, hospitalName: getHospitalName(item.hospital) }))
  ), [sets, statusFilter, getHospitalName]);

  const onLabelsChange = (labels) => {
    dispatch(updateSet(selectedSet?.id, { labels }));
    selectSet({
      ...selectedSet,
      labels
    });
    // if (selectedSet.consigned) {
    //   dispatch(getConsignedSets());
    // }
  };

  const onResetTarget = async (setId, note) => {
    setResetLoading(true);
    try {
      await dispatch(resetTargetTurns(setId, note));
      selectSet({ ...selectedSet, targetTurns: 0 });
    } catch (err) {
      console.error(err);
    } finally {
      setResetLoading(false);
    }
  };

  return (
    <div className='page-container sets-page__container'>
      {loading && <LoadScreen /> }

      <div className='d-flex space-between'>
        <div className='d-flex'>
          <div className='page-title'>
            Sets
          </div>
        </div>
      </div>

      <div className='sets-page__body'>
        <div>
          <div className='sets-page-filters'>
            <FilterButton
              status={setPositionOptions.AVAILABLE.value}
              value={statusFilter[setPositionOptions.AVAILABLE.value]}
              onClick={onStatusFilterChange}
            />
            <FilterButton
              status={setPositionOptions.ALLOCATED.value}
              value={statusFilter[setPositionOptions.ALLOCATED.value]}
              onClick={onStatusFilterChange}
            />
            <FilterButton
              status={setPositionOptions.QUARANTINED.value}
              value={statusFilter[setPositionOptions.QUARANTINED.value]}
              onClick={onStatusFilterChange}
            />
            <FilterButton
              status={setPositionOptions.CONSIGNED.value}
              value={statusFilter[setPositionOptions.CONSIGNED.value]}
              onClick={onConsignedClick}
            />
          </div>

          {!!statusFilter[setPositionOptions.CONSIGNED.value] && !consignedLoaded ? (
            <div className='d-flex flex-center p-b-lg'>
              <CircularProgress />
            </div>
          ) : (
            <SetsTable
              sets={list}
              kits={kits}
              cases={cases}
              hospitals={hospitals}
              tenantColor={tenantColor}
              withoutStatus
              search={setsSearch}
              checkboxes={false}
              onSelectSet={onSelectSet}
              selectedSetId={selectedSet?.id}
              labels={labels}
            />
          )}
        </div>
        <div>
          <div className='flex-1'>
            <Input
              type='search'
              placeholder='Search Set Number or Kit ID'
              value={setsSearch}
              onChange={(e) => setSetsSearch(e.target.value)}
            />
          </div>
          {selectedSet && (
            <QuarantineForm
              selectedSet={selectedSet}
              setItems={items}
              tenantColor={tenantColor}
              onViewClick={onViewClick}
              onQuarantineClick={onQuarantineClick}
              onAvailableClick={onAvailableClick}
              onConsignClick={() => toggleConsignModal(true)}
              fetching={fetching}
              items={itemsList}
              hospitals={hospitals}
              kits={kits}
              labels={labels}
              onLabelsChange={onLabelsChange}
              loading={resetLoading}
              onResetTarget={onResetTarget}
              tenantId={tenantId}
              files={files}
              onUpload={onUpload}
              onUploadComplete={onUploadComplete}
              onRemove={onRemove}
              onDeleteFile={onDeleteFile}
              onEditNoteClick={() => toggleNoteModal(true)}
              onEditConsignmentClick={() => toggleConsignModal(true)}
              userRole={userRole}
              cases={cases}
            />
          )}
        </div>
      </div>

      {selectedSet && (
        <>
          <QuarantineModal
            open={quarantineModal}
            onClose={() => toggleQuarantineModal(false)}
            onSubmit={onSubmit}
            loading={quarantineLoading}
            items={items}
            itemsList={itemsList}
            initialValues={selectedSet}
          />

          <ConsignmentModal
            open={consignModal}
            onClose={() => toggleConsignModal(false)}
            onSubmit={onConsignSubmit}
            loading={consignLoading}
            hospitals={hospitals}
            isConsigned={selectedSet.consigned}
            initialValues={selectedSet?.consignment}
          />

          <ConsignmentNoteModal
            open={noteModal}
            onClose={() => toggleNoteModal(false)}
            onSubmit={onNoteSubmit}
            loading={noteLoading}
            initialValue={selectedSet?.consignment?.note}
          />

          <ConfirmationModal
            open={availableModal}
            onClose={() => toggleAvailableModal(false)}
            onSubmit={onMakeAvailable}
            title={`Make set #${selectedSet?.number} available`}
            text='You are about to make this set available for case allocation. Any related items and quarantine notes will be removed.'
            submitText='Confirm'
            loading={quarantineLoading}
          />
        </>
      )}
    </div>
  );
};

export default SetsPage;
