import React, { useState, useMemo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { usePDF } from '@react-pdf/renderer'

import ProformaIcon from '../../../assets/icons/ProformaIcon';
import PrintIcon from '../../../assets/icons/PrintIcon';
import CheckIcon from '../../../assets/icons/CheckIcon';
import InterfaceEditIcon from '../../../assets/icons/InterfaceEditIcon';

import CaseProformaTable from '../proforma/CaseProformaTable';
import CaseProformaModal from '../proforma/CaseProformaModal';
import ProformaPDF from '../proforma/ProformaPDF';
import ProformaReferenceModal from '../proforma/ProformaReferenceModal';
import ProformaPrintModal from '../proforma/ProformaPrintModal';
import { ConfirmationModal } from '../../shared/modal';
import ActionIconButton from '../../shared/ActionIconButton';

import {
  updateCaseProforma,
  approveProforma,
  deleteProformaItem,
  simpleUpdateCase,
  amendProforma,
  getCase,
} from '../../../actions/casesActions';

import { useLoading } from '../../../hooks';

import { fromMomentToTimestampDay } from '../../../utils/date';

import { setAllocationStatuses, caseStatusOptions } from '../../../constants/enums';
import { downloadPdf } from '../../../utils/utils';

const CaseProformaContainer = (props) => {
  const {
    caseId,
    activeCase,
    proforma = [],
    status,
    proformaApproved,
    hospitals = [],
    surgeons = [],
    billingAddress,
    deliveryAddress,
  } = props;

  const currentTenant = useSelector((state) => state.tenant.currentTenant);
  const dispatch = useDispatch();

  const formattedId = useMemo(() => {
    if (!activeCase) {
      return ''
    }
    const arr = activeCase?.id?.split('-');
    return arr?.length > 1 ? `${arr[0]}-${arr[1]}` : activeCase?.id;
  }, [activeCase]);

  const hospital = useMemo(() => hospitals?.find((h) => h.id === activeCase?.hospital), [hospitals, activeCase]);
  const surgeon = useMemo(() => surgeons?.find((h) => h.id === activeCase?.surgeon), [surgeons, activeCase]);

  const [instance, updateInstance] = usePDF({
    document: <ProformaPDF
      caseId={caseId}
      tenant={currentTenant}
      proforma={proforma}
      activeCase={activeCase}
      hospital={hospital}
      surgeon={surgeon}
      billingAddress={billingAddress}
      deliveryAddress={deliveryAddress}
      formattedId={formattedId}
    />
  });

  const { loading, startLoading, stopLoading } = useLoading(false);

  const [modalOpen, toggleModal] = useState(false);
  const [referenceModalOpen, toggleReferenceModal] = useState(false);
  const [amendModalOpen, toggleAmendModal] = useState(false);
  const [printModalOpen, togglePrintModal] = useState(false);

  const [selectedItem, setSelectedItem] = useState(null);
  const [approving, setApproving] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [readyToDownload, setReadyToDownload] = useState(false);

  useEffect(() => {
    updateInstance();
  }, [activeCase, billingAddress, deliveryAddress]);

  const onUpdateProforma = async (data) => {
    if (selectedItem) {
      try {
        startLoading();
        dispatch(updateCaseProforma(caseId, selectedItem.id, data));
        setSelectedItem(null);
      } catch (err) {
        console.error(err);
      } finally {
        stopLoading();
      }
    }
  };

  const onUpdateReference = async (reference, date, notes) => {
    try {
      startLoading();

      const data = {
        proformaReference: reference || '',
        proformaDate: date ? fromMomentToTimestampDay(date) : null,
        proformaNotes: notes || '',
      };

      dispatch(simpleUpdateCase(activeCase.id, data));
      dispatch(getCase(activeCase.id));
      toggleReferenceModal(false);
    } catch (err) {
      console.error(err);
    } finally {
      stopLoading();
    }
  };

  const onApprove = async () => {
    try {
      setApproving(true);
      dispatch(approveProforma(caseId, proforma));
      dispatch(getCase(activeCase.id));
      toggleModal(false);
    } catch (err) {
      console.error(err);
    } finally {
      setApproving(false);
    }
  };

  const onDelete = (id) => {
    try {
      setDeleteLoading(true);
      dispatch(deleteProformaItem(caseId, id));
      dispatch(getCase(activeCase.id));
      setSelectedItem(null);
    } catch (err) {
      console.error(err);
    } finally {
      setDeleteLoading(false);
    }
  };

  const onAmendSubmit = async () => {
    try {
      setApproving(true);
      dispatch(amendProforma(caseId, proforma));
      dispatch(getCase(activeCase.id));
      toggleAmendModal(false);
    } catch (err) {
      console.error(err);
    } finally {
      setApproving(false);
    }
  };

  const onPrintSubmit = async (reference, date, notes) => {
    try {
      startLoading();

      const data = {
        proformaReference: reference || '',
        proformaDate: date ? fromMomentToTimestampDay(date) : null,
        proformaNotes: notes || '',
      };

      await dispatch(simpleUpdateCase(activeCase.id, data));
      await dispatch(getCase(activeCase.id));
      setReadyToDownload(true);
    } catch (err) {
      console.error(err);
    } finally {
      stopLoading();
    }
  };

  const onDownloadClick = () => {
    downloadPdf(instance, `${activeCase.id} – Proforma.pdf`);
    onPrintModalClose();
  }

  const onPrintModalClose = () => {
    togglePrintModal(false);
    setTimeout(() => setReadyToDownload(false), 200);
  };

  return (
    <div className='count-scans__container'>
      <div className='d-flex m-b-lg space-between align-start'>
        <div className='d-flex'>
          <ProformaIcon />
          <div className='font-size-bg font-bold m-l-md'>
            Proforma
          </div>
        </div>

        <div className='count-scans-actions__container'>
          {status === caseStatusOptions.completed && (
            <ActionIconButton
              onClick={() => toggleReferenceModal(true)}
              color='blue'
              tooltip='Edit Info'
              // disabled={proformaApproved}
            >
              <ProformaIcon />
            </ActionIconButton>
          )}
          {status === caseStatusOptions.completed && !!proforma?.length && (
            proformaApproved ? (
              <ActionIconButton
                onClick={() => toggleAmendModal(true)}
                bgColor={setAllocationStatuses.TRANSFERRED.color}
                color='orange'
                tooltip='Amend'
              >
                <InterfaceEditIcon />
              </ActionIconButton>
            ) : (
              <ActionIconButton
                onClick={() => toggleModal(true)}
                color='blue'
                disabled={proforma?.some((item) => !item.value)}
                tooltip='Approve'
              >
                <CheckIcon />
              </ActionIconButton>
            )
          )}
          {status === caseStatusOptions.completed && !!proforma?.length && (
            <ActionIconButton
              color='green'
              disabled={loading || !proformaApproved}
              onClick={() => togglePrintModal(true)}
              tooltip='Print PDF'
            >
              <PrintIcon />
            </ActionIconButton>
          )}
        </div>
      </div>

      <CaseProformaTable
        list={proforma}
        onSelect={(row) => {
          if (!proformaApproved) {
            setSelectedItem(row);
          }
        }}
      />

      <CaseProformaModal
        open={!!selectedItem}
        onClose={() => setSelectedItem(null)}
        initialValues={selectedItem}
        onSubmit={onUpdateProforma}
        loading={loading}
        deleteLoading={deleteLoading}
        onDelete={onDelete}
      />

      <ConfirmationModal
        open={modalOpen}
        onClose={() => toggleModal(false)}
        onSubmit={onApprove}
        title='Approve Proforma Invoice'
        text='Are you sure you want to approve the information in this proforma?'
        submitText='Approve'
        loading={approving}
      />

      <ConfirmationModal
        open={amendModalOpen}
        onClose={() => toggleAmendModal(false)}
        onSubmit={onAmendSubmit}
        title='Amend Proforma'
        text='Are you sure you want to make changes to the proforma? You’ll need to approve changes before you can print to pdf.'
        submitText='Amend'
        loading={approving}
      />

      <ProformaReferenceModal
        open={referenceModalOpen}
        onClose={() => toggleReferenceModal(false)}
        onSubmit={onUpdateReference}
        loading={loading}
        activeCase={activeCase}
        caseId={formattedId}
      />

      <ProformaPrintModal
        open={printModalOpen}
        onClose={onPrintModalClose}
        onSubmit={onPrintSubmit}
        loading={loading}
        activeCase={activeCase}
        caseId={formattedId}
        onDownloadClick={onDownloadClick}
        readyToDownload={readyToDownload}
      />
    </div>
  );
};

export default CaseProformaContainer;
