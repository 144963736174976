import React from 'react';

import { caseChecklistStatuses } from '../../../constants/enums';

import './checklists.scss';

const ChecklistsContainer = (props) => {
  const { checklists = [], selected, onSelect } = props;

  const handleSelect = (checklist) => {
    onSelect(checklist);
    const container = document.querySelector('.app-wrapper');
    if (container) {
      container.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }

  return (
    <div className='case-checklists__container'>
      {checklists?.map((checklist) => (
        <div
          key={checklist.id}
          className={`case-checklist-item${selected?.id === checklist.id ? ' selected' : ''}`}
          onClick={() => handleSelect(checklist)}
          style={{ borderColor: caseChecklistStatuses[checklist.status]?.color }}
        >
          <div className='font-bold m-b-sm'>
            {checklist.kitName}
          </div>
          <div className='m-b-sm'>{checklist.setNumber}</div>
          <div className='uppercase' style={{ color: caseChecklistStatuses[checklist.status]?.color }}>
            {caseChecklistStatuses[checklist.status]?.label}
          </div>
        </div>
      ))}
    </div>
  );
};

export default ChecklistsContainer;
