import React from 'react';
import PropTypes from 'prop-types';

import Dialog from '@material-ui/core/Dialog';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme, withStyles } from '@material-ui/core/styles';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';

import Button from '../Button';

import './modal.scss';

const styles = theme => ({
  root: {
    bottom: theme.spacing(1),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  actions: {
    paddingBottom: "24px !important"
  }
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, subtitle, showCloseButton, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <div className='d-flex'>
        <span className='font-bold font-size-bg'>{children}</span>
        {subtitle && <div className='secondary font-bold m-l-md'>{subtitle}</div>}
      </div>
      {onClose && showCloseButton && (
        <IconButton aria-label='close' className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      )}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(0),
    borderRadius: '30px'
  },
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(3),
    justifyContent: 'space-between',
  }
}))(MuiDialogActions);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});

const Modal = (props) => {
  const {
    open,
    onClose,
    onCloseClick,
    onSubmit,
    title,
    subtitle,
    closeButton,
    submitButton,
    children,
    disabled,
    loading,
    size,
    fullWidth,
    autoSize,
    disableBackdropClick,
    showCloseButton = true,
  } = props;

  const theme = useTheme();

  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <Dialog
      onClose={(event, reason) => {
        if (disableBackdropClick && reason === 'backdropClick') {
          return
        }
        onClose();
      }}
      TransitionComponent={Transition}
      aria-labelledby='customized-dialog-title'
      open={open}
      fullWidth={fullWidth}
      fullScreen={fullScreen}
      maxWidth={size}
      scroll={autoSize ? 'body' : 'paper'}
    >
      { title && (
        <DialogTitle id='customized-dialog-title' onClose={onClose} subtitle={subtitle} showCloseButton={showCloseButton}>
          { title }
        </DialogTitle>
      )}
      <DialogContent>
        { children }
      </DialogContent>
      { (closeButton || submitButton) && (
        <DialogActions style={{ justifyContent: 'space-between !important' }}>
          { closeButton && (
            <Button type='cancel' text={closeButton} onClick={onCloseClick || onClose} />
          )}
          { submitButton && (
            <Button text={submitButton} onClick={onSubmit} disabled={disabled} loading={loading} />
          )}
        </DialogActions>
      )}
    </Dialog>
  );
};

Modal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  onTabChange: PropTypes.func,
  activeTab: PropTypes.number,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  closeButton: PropTypes.string,
  submitButton: PropTypes.string,
  disabled: PropTypes.bool,
  tabs: PropTypes.array,
  size: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl']),
  fullWidth: PropTypes.bool,
  isCase: PropTypes.bool,
  autoSize: PropTypes.bool
};

export default Modal;
