export const lightGreenColor = '#e6f0d0';
export const lightOrangeColor = '#ffeedb';
export const lightBlueColor = '#e5f7fe';
export const lightGreyColor = '#f1f1f1';
export const greenBorderColor = '#81B214';
export const orangeBorderColor = '#F58634';
export const blueBorderColor = '#00AEEF';
export const greyBorderColor = '#CCCCCC';

export const getBorderColor = (color) => {
  switch (color) {
    case 'green':
      return greenBorderColor;
    case 'blue':
      return blueBorderColor;
    case 'orange':
      return orangeBorderColor;
    default:
      return greyBorderColor;
  }
};

export const getBackgroundColor = (color) => {
  switch (color) {
    case 'green':
      return lightGreenColor;
    case 'blue':
      return lightBlueColor;
    case 'orange':
      return lightOrangeColor;
    default:
      return lightGreyColor;
  }
}