import { orderBy } from 'lodash';

import { GET_ITEMS, SET_ITEMS, UPDATE_ITEM, CREATE_ITEM, UPLOAD_ITEMS, BULK_UPDATE_ITEMS } from './actionTypes';

import firebase, { collections, storageRefs } from '../firebase';

import { chunkArray } from '../utils/utils';
import { get, post } from '../utils/api';

import { itemTypeOptions } from '../constants/enums';
import urls from '../constants/urls';

export const setItems = items => ({ type: SET_ITEMS, items });

export const getItems = (noReducer, tenant) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = tenant || state?.tenant?.currentTenant?.id;
  const path = collections.ITEMS_COLLECTION(tenantId);
  // const idToken = await firebase.auth.currentUser.getIdToken();

  dispatch({ type: GET_ITEMS });

  const snapshot = await firebase.db.collection(path).get();
  const items = snapshot.docs.map(doc => ({
    id: doc.id,
    ...doc.data(),
  }));

  if (noReducer) {
    return orderBy(items, 'code', 'asc');
  }

  return dispatch(setItems(orderBy(items, 'code', 'asc')));

  // if (tenantId) {
  //   const { items } = await post(urls.items.getAll(tenantId), idToken);
  //
  //   if (noReducer) {
  //     return orderBy(items, 'code', 'asc');
  //   }
  //
  //   return dispatch(setItems(orderBy(items, 'code', 'asc')));
  // } else {
  //   throw new Error('Invalid tenant');
  // }
};

export const getPaginatedItems = (params) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const idToken = await firebase.auth.currentUser.getIdToken();

  if (tenantId) {
    return post(urls.items.getPaginatedItems(tenantId), params, idToken);
  } else {
    throw new Error('Invalid tenant');
  }
};

export const getInfiniteItems = (params) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const idToken = await firebase.auth.currentUser.getIdToken();

  if (tenantId) {
    return post(urls.items.getInfiniteItems(tenantId), params, idToken);
  } else {
    throw new Error('Invalid tenant');
  }
};

export const getAllItems = () => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const idToken = await firebase.auth.currentUser.getIdToken();

  if (tenantId) {
    const { items } = await get(urls.items.getAll(tenantId), idToken);
    return items || [];
  } else {
    throw new Error('Invalid tenant');
  }
};

export const getItemsByProducts = (products, tenant) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = tenant || state?.tenant?.currentTenant?.id;
  const path = collections.ITEMS_COLLECTION(tenantId);

  const snapshot = await firebase.db.collection(path)
    .where('products', 'array-contains-any', products)
    .get();

  const items = snapshot.docs.map(doc => ({
    id: doc.id,
    ...doc.data(),
  }));

  return orderBy(items, 'code', 'asc');
};

export const getItemsByIds = (ids, tenant) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = tenant || state?.tenant?.currentTenant?.id;
  const path = collections.ITEMS_COLLECTION(tenantId);

  if (!ids || ids.length === 0) return [];

  try {
    const db = firebase.db;

    // Split IDs into chunks to handle large lists
    const chunks = chunkArray(ids, 50); // Adjust chunk size based on performance needs

    // Fetch documents in parallel for each chunk
    const batchPromises = chunks.map(async (chunk) => {
      const chunkPromises = chunk.map((itemId) =>
        db.collection(path).doc(itemId).get()
      );
      return Promise.all(chunkPromises); // Fetch all documents in the chunk
    });

    // Resolve all batches
    const batchSnapshots = await Promise.all(batchPromises);

    // Flatten the results and map to items
    const items = batchSnapshots.flatMap((snapshots) =>
      snapshots
        .filter((doc) => doc.exists) // Exclude non-existing documents
        .map((doc) => ({ ...doc.data(), id: doc.id }))
    );

    return items;
  } catch (error) {
    console.error("Error fetching items:", error);
    throw error;
  }
};

export const getItemsByCodes = (itemCodes, tenant) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = tenant || state?.tenant?.currentTenant?.id;
  const path = collections.ITEMS_COLLECTION(tenantId);

  if (!itemCodes || itemCodes.length === 0) return [];

  try {
    const db = firebase.db;

    // Split IDs into chunks to handle large lists
    const chunks = chunkArray(itemCodes, 50); // Adjust chunk size based on performance needs

    // Fetch documents in parallel for each chunk
    const batchPromises = chunks.map(async (chunk) => {
      const chunkPromises = chunk.map((itemCode) =>
        db.collection(path).where('code', '==', itemCode).get()
      );
      return Promise.all(chunkPromises); // Fetch all documents in the chunk
    });

    // Resolve all batches
    const batchSnapshots = await Promise.all(batchPromises);
    // Flatten the results and map to items
    const items = batchSnapshots.flatMap((snapshots) =>
      snapshots.flatMap((querySnapshot) =>
        querySnapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }))
      )
    );

    return items;
  } catch (error) {
    console.error("Error fetching items:", error);
    throw error;
  }
};

export const createItem = (itemData, image) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const path = collections.ITEMS_COLLECTION(tenantId);
  const storageRef = firebase.storage.ref();

  const doc = {
    ...itemData,
    active: true,
  };

  if (tenantId) {
    const docRef = await firebase.db.collection(path).add(doc);
    let imageObj = null;

    if (image && docRef) {
      const itemId = docRef.id;
      const storagePath = storageRefs.ITEM_IMAGES(tenantId, itemId);
      const filePath = `${storagePath}/${image.name}`;
      const ref = storageRef.child(filePath);
      const snap = await ref.put(image);
      const downloadUrl = await snap?.ref?.getDownloadURL();
      imageObj = {
        fileName: image.name,
        path: filePath,
        downloadUrl
      };

      await firebase.db.collection(path).doc(itemId).set({ image: imageObj }, { merge: true });
      dispatch({ type: CREATE_ITEM, data: { ...doc, id: docRef.id, image: imageObj } });
    } else {
      dispatch({ type: CREATE_ITEM, data: { ...doc, id: docRef.id } });
    }
  } else {
    throw new Error('Invalid tenant');
  }
};

export const updateItem = (itemId, itemData, image) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const path = collections.ITEMS_COLLECTION(tenantId);
  const storageRef = firebase.storage.ref();

  if (tenantId) {
    const doc = { ...itemData };
    let imageObj = null;

    if (image) {
      const storagePath = storageRefs.ITEM_IMAGES(tenantId, itemId);
      const filePath = `${storagePath}/${image.name}`;
      const ref = storageRef.child(filePath);
      const snap = await ref.put(image);
      const downloadUrl = await snap?.ref?.getDownloadURL();
      imageObj = {
        fileName: image.name,
        path: filePath,
        downloadUrl
      };

      doc.image = imageObj;

      await firebase.db.collection(path).doc(itemId).set(doc, { merge: true });
      dispatch({ type: UPDATE_ITEM, data: { id: itemId, ...itemData, image: imageObj } });
    } else {
      await firebase.db.collection(path).doc(itemId).set(doc, { merge: true });
      dispatch({ type: UPDATE_ITEM, data: { id: itemId, ...itemData } });
    }
  } else {
    throw new Error('Invalid tenant');
  }
};

export const deleteItem = (itemId) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const path = collections.ITEMS_COLLECTION(tenantId);

  if (tenantId) {
    await firebase.db.collection(path).doc(itemId).delete();
  } else {
    throw new Error('Invalid tenant');
  }
};

export const bulkDeleteItems = (items) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const itemsList = state?.items?.list || [];
  const path = collections.ITEMS_COLLECTION(tenantId);

  if (tenantId) {
    const chunks = chunkArray(items, 500);

    for (const chunk of chunks) {
      const batch = firebase.db.batch();
      chunk.forEach((itemId) => {
        const ref = firebase.db.collection(path).doc(itemId);
        batch.delete(ref);
      });
      await batch.commit();
    }

    dispatch(setItems(itemsList?.filter((item) => !items?.includes(item.id))));
  } else {
    throw new Error('Invalid tenant');
  }
};

export const uploadItems = (items) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const path = collections.ITEMS_COLLECTION(tenantId);

  if (tenantId) {
    const docs = items.map((item) => {
      const code = item[0] ? `${item[0]}` : '';
      const description = item[1] || '';

      const label = item[2] ? item[2] : '';
      const itemType = Object.values(itemTypeOptions)?.find((i) => i.label?.toLowerCase() === label?.toLowerCase())?.value || '';
      const type = item[2] ? itemType : itemTypeOptions.OTHER.value;
      const rebateCode = item[3] || '';
      const value = item[4] || '';
      const gtin = item[5] || '';
      const checklistDescription = item[6] || '';
      const batchControl = item[7] && item[7]?.toLowerCase() === 'yes';
      const imageUrl = item[8] || '';

      const doc = {
        active: true,
        code,
        description,
        type,
        rebateCode,
        value,
        gtin,
        checklistDescription,
        batchControl,
        imageUrl,
      };
      return doc;
    });

    const chunks = chunkArray(docs, 500);

    for (const chunk of chunks) {
      const batch = firebase.db.batch();
      const newItems = [];
      chunk.forEach((document) => {
        const ref = firebase.db.collection(path).doc();
        batch.set(ref, document);
        newItems?.push({ ...document, id: ref?.id });
      });
      await batch.commit();
      dispatch({ type: UPLOAD_ITEMS, items: newItems });
    }
  } else {
    throw new Error('Invalid tenant');
  }
};

export const bulkUpdateItems = (items) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const itemsList = state?.items?.list || [];
  const path = collections.ITEMS_COLLECTION(tenantId);

  if (tenantId) {
    const docs = items.map((item) => {
      const code = item[0] ? `${item[0]}` : '';
      const rebateCode = item[1] || '';
      const value = item[2] || '';
      const gtin = item[3] || '';
      const checklistDescription = item[4] || '';

      const doc = { code };

      if (rebateCode) {
        doc.rebateCode = rebateCode;
      }
      if (value) {
        doc.value = value;
      }
      if (gtin) {
        doc.gtin = gtin;
      }
      if (checklistDescription) {
        doc.checklistDescription = checklistDescription;
      }
      if (item[5]) {
        doc.batchControl = item[5]?.toLowerCase() === 'yes';
      }

      return doc;
    });

    const chunks = chunkArray(docs, 500);

    for (const chunk of chunks) {
      const batch = firebase.db.batch();
      const updatedItems = [];
      chunk.forEach((document) => {
        const updatedItem = itemsList?.find((i) => `${i.code}` === document.code);
        if (updatedItem && updatedItem.id) {
          const ref = firebase.db.collection(path).doc(updatedItem.id);
          batch.set(ref, document, { merge: true });
          updatedItems?.push({ ...document, id: updatedItem.id });
        }
      });
      await batch.commit();
      dispatch({ type: BULK_UPDATE_ITEMS, items: updatedItems });
    }
  } else {
    throw new Error('Invalid tenant');
  }
};

// Subscriptions
export const subscribeToItems = () => (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const path = collections.ITEMS_COLLECTION(tenantId);

  return firebase
    .db
    .collection(path)
    .onSnapshot({
      error: (e) => console.error(e),
      next: (querySnapshot) => {
        let items = [];
        querySnapshot.forEach((documentSnapshot) => {
          const id = documentSnapshot.id;
          items = [...items, {
            ...documentSnapshot.data(),
            id,
          }];
        });

        return dispatch(setItems(orderBy(items, 'code', 'asc')));
      },
    });
};
