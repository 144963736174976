import React, { useState, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { HospitalsTable, HospitalInfo } from '../../../components/customers/hospitals';
import Input from '../../../components/shared/Input';

import { getSurgeons } from '../../../actions/surgeonsActions';
import { subscribeToHospitals } from '../../../actions/hospitalsActions';
import { getTerritories } from '../../../actions/territoriesActions';
import { getKits } from '../../../actions/kitsActions';

import { useLoading } from '../../../hooks';

const CustomerSurgeonsPage = () => {
  const dispatch = useDispatch();
  const surgeons = useSelector((state) => state?.surgeons.list);
  const kits = useSelector((state) => state?.kits.list);
  const hospitals = useSelector((state) => state?.hospitals.list);
  const territories = useSelector((state) => state?.territories.list);

  const [selectedHospital, setSelectedHospital] = useState(null);
  const [search, setSearch] = useState('');

  const { startLoading, stopLoading } = useLoading();

  useEffect(() => {
    const unsubscribe = dispatch(subscribeToHospitals());

    onLoad().catch((err) => console.error(err));

    return () => {
      unsubscribe();
    };
  }, []);

  const onLoad = async () => {
    startLoading()
    try {
      await Promise.all([
        dispatch(getSurgeons()),
        dispatch(getKits()),
        dispatch(getTerritories()),
      ]);
    } catch (err) {
      console.error(err);
    } finally {
      stopLoading();
    }
  };

  const list = useMemo(() => (
    hospitals?.filter((hospital) => !search || hospital?.name?.toLowerCase()?.includes(search?.toLowerCase()))
  ), [hospitals, search]);

  return (
    <div className='settings-cmp__main'>
      <span className='settings-title'>Hospitals</span>
      <div className='settings-cmp__body'>
        <div className='filters-container'>
          <div style={{ height: 20 }} />
          <HospitalsTable
            onSelectHospital={setSelectedHospital}
            hospitals={list}
            surgeons={surgeons}
            selectedHospitalId={selectedHospital ? selectedHospital.id : null}
            territories={territories}
          />
        </div>
        { hospitals && !!hospitals.length && (
          <div className='form-container'>
            <Input
              type='search'
              placeholder='Search Hospitals'
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
            {selectedHospital && (
              <HospitalInfo
                hospital={selectedHospital}
                surgeons={surgeons}
                kits={kits}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default CustomerSurgeonsPage;
