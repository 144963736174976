const statusOptions = {
  active: 'ACTIVE',
  deactivated: 'DEACTIVATED'
};

const kitTypeOptions = {
  INSTRUMENTS: { label: 'Instruments', value: 'INSTRUMENTS' },
  IMPLANTS: { label: 'Implants', value: 'IMPLANTS'},
  COMBINED: { label: 'Combined (Inst + Imp)', value: 'COMBINED'},
  CONSUMABLES: { label: 'Consumables', value: 'CONSUMABLES'},
  CAPITAL: { label: 'Capital', value: 'CAPITAL'},
  OTHER: { label: 'Other', value: 'OTHER'}
};

const kitTypeBookingOptions = {
  INSTRUMENTS: { label: 'Instrument Sets', value: 'INSTRUMENTS' },
  IMPLANTS: { label: 'Implant Sets', value: 'IMPLANTS'},
  COMBINED: { label: 'Instrument & Implant sets', value: 'COMBINED'},
  CONSUMABLES: { label: 'Consumable Sets', value: 'CONSUMABLES'},
  CAPITAL: { label: 'Capital', value: 'CAPITAL'},
  OTHER: { label: 'Other', value: 'OTHER'}
};

const itemTypeOptions = {
  INSTRUMENTS: { label: 'Instrument', value: 'INSTRUMENTS' },
  IMPLANTS: { label: 'Implant', value: 'IMPLANTS'},
  COMBINED: { label: 'Combined', value: 'COMBINED'},
  CONSUMABLES: { label: 'Consumable', value: 'CONSUMABLES'},
  CAPITAL: { label: 'Capital', value: 'CAPITAL'},
  OTHER: { label: 'Other', value: 'OTHER'}
};

const caseStatusOptions = {
  request: 'REQUEST',
  booked: 'BOOKED',
  overdue: 'OVERDUE',
  completed: 'COMPLETED',
};

const caseUsageTypes = {
  implants: 'IMPLANTS',
  images: 'IMAGES',
};

const implantScanTypes = {
  text: 'TEXT',
  qr: 'QRCODE',
  datamatrix: 'DATAMATRIX',
  datamatrix2: 'DATAMATRIX_2PART',
  hibcc: 'HIBCC',
  hibcc2: 'HIBCC_2PART',
  billOfMaterial: 'BOM',
};

const setAllocationStatuses = {
  AVAILABLE: { label: 'Available', value: 'AVAILABLE', color: '#81B214' },
  ASSIGNED: { label: 'Assigned', value: 'ASSIGNED', color: '#F58634' },
  SHIPPED: { label: 'Shipped', value: 'SHIPPED', color: '#81B214' },
  RETURNED: { label: 'Returned', value: 'RETURNED', color: '#00AEEF' },
  TRANSFERRED: { label: 'Transferred', value: 'TRANSFERRED', color: '#F58634' },
  DISABLED: { label: 'Disabled', value: 'DISABLED', color: '#DDDDDD' },
  DISPATCH: { label: 'Dispatch', value: 'DISPATCH', color: '#00AEEF' },
  QUARANTINED: { label: 'Quarantined', value: 'QUARANTINED', color: '#F53434' },
  USAGE: { label: 'Usage', value: 'USAGE', color: '#81B214' },
  CONFIRMED: { label: 'Confirmed', value: 'CONFIRMED', color: '#81B214' },
};

const consumableStatuses = {
  RETURNED: { label: 'Returned', value: 'RETURNED', color: '#00AEEF' },
  USAGE: { label: 'Usage', value: 'USAGE', color: '#81B214' },
};

const setPositionOptions = {
  AVAILABLE: { label: 'Available', value: 'AVAILABLE', color: '#81B214' },
  ALLOCATED: { label: 'Allocated', value: 'ALLOCATED', color: '#F58634' },
  QUARANTINED: { label: 'Quarantined', value: 'QUARANTINED', color: '#F53434' },
  CONSIGNED: { label: 'Consigned', value: 'CONSIGNED', color: '#00AEEF' },
  INCOMPLETE: { label: 'Incomplete', value: 'INCOMPLETE', color: '#F58634' },
};

const setAllocationTypes = {
  SET: 'SET',
  ITEM: 'ITEM'
};

const countStatusOptions = {
  pending: 'PENDING',
  completed: 'COMPLETED',
  overdue: 'OVERDUE'
};

const countStatuses = {
  PENDING: { label: 'Pending', value: 'PENDING', color: '#81B214' },
  REPEATING: { label: 'Repeating', value: 'REPEATING', color: '#F58634' },
  COMPLETED: { label: 'Completed', value: 'COMPLETED', color: '#00AEEF' },
  OVERDUE: { label: 'Overdue', value: 'OVERDUE', color: '#F53434' },
};

const notificationTypes = {
  caseRequested: 'CASE_REQUESTED',
  caseBooked: 'CASE_BOOKED',
  caseOverdue: 'CASE_OVERDUE',
  caseNoteAdded: 'CASE_NOTE_ADDED',
  caseDateUpdated: 'CASE_DATE_UPDATED',
  caseUsageSubmitted: 'CASE_USAGE_SUBMITTED',
  caseShipmentsSent: 'CASE_SHIPMENTS_SENT',
  caseCancelled: 'CASE_CANCELLED',
  caseClosed: 'CASE_CLOSED',
  caseDeleted: 'CASE_DELETED',
  checklistSubmitted: 'CHECKLIST_SUBMITTED',
  caseSetsReturned: 'CASE_SETS_RETURNED',
  countAdded: 'COUNT_ADDED',
  countCompleted: 'COUNT_COMPLETED',
  preferenceAdded: 'PREFERENCE_ADDED',
  taskDue: 'TASK_DUE',
  transferRequested: 'TRANSFER_REQUESTED',
  transferAccepted: 'TRANSFER_ACCEPTED',
  transferDeclined: 'TRANSFER_DECLINED',
  formSubmitted: 'FORM_SUBMITTED',
  proformaApproved: 'PROFORMA_APPROVED',
  proformaAmended: 'PROFORMA_AMENDED',
  shippingReversed: 'SHIPPING_REVERSED',
  flowStepCompleted: 'FLOW_STEP_COMPLETED'
};

const kitVariantTypes = {
  consignment: 'Consignment',
  loan: 'Loan Kit',
};

const flowStepStatuses = {
  PENDING: { label: 'Pending', value: 'PENDING', color: '#F58634' },
  COMPLETED: { label: 'Completed', value: 'COMPLETED', color: '#81B214' },
};

const caseChecklistStatuses = {
  INSPECT: { label: 'Inspect', value: 'INSPECT', color: '#00AEEF' },
  COMPLETE: { label: 'Complete', value: 'COMPLETE', color: '#81B214' },
  INCOMPLETE: { label: 'Incomplete', value: 'INCOMPLETE', color: '#F58634' },
};

const countTypes = {
  FREE: 'FREE',
  CONSIGNMENT: 'CONSIGNMENT',
};

const formSectionTypes = {
  TEXT: { label: 'Text Input', value: 'TEXT' },
  TEXT_AREA: { label: 'Text Area', value: 'TEXT_AREA' },
  FILE_UPLOAD: { label: 'File Upload', value: 'FILE_UPLOAD' },
  DATE_PICKER: { label: 'Date Picker', value: 'DATE_PICKER' },
  DROPDOWN: { label: 'Dropdown', value: 'DROPDOWN' },
  CHECKBOXES: { label: 'Checkboxes', value: 'CHECKBOXES' },
  RADIO: { label: 'Radio Group', value: 'RADIO' },
  CUSTOM: { label: 'Custom Dropdown', value: 'CUSTOM' },
  DESCRIPTION: { label: 'Description', value: 'Description' },
  IMAGE_UPLOAD: { label: 'Image Upload', value: 'IMAGE_UPLOAD' },
};

const formDropdownOptions = {
  SURGEONS: { label: 'Surgeons', value: 'SURGEONS' },
  HOSPITALS: { label: 'Hospitals', value: 'HOSPITALS' },
  PROCEDURES: { label: 'Procedures', value: 'PROCEDURES' },
  PRODUCTS: { label: 'Products', value: 'PRODUCTS' },
  KITS: { label: 'Kits', value: 'KITS' },
};

const formStatuses = {
  PENDING: 'PENDING',
  SUBMITTED: 'SUBMITTED',
  CLOSED: 'CLOSED',
};

const kitPreferenceItemTypes = {
  ITEM: { label: 'Item', value: 'ITEM' },
  KIT: { label: 'Kit', value: 'KIT' },
  TITLE: { label: 'Title', value: 'TITLE' }
};

const billOfMaterialItemTypes = {
  ITEM: { label: 'Item', value: 'ITEM' },
  NOTES: { label: 'Notes', value: 'NOTES' }
};

const kitItemTypes = {
  ITEM: { label: 'Item', value: 'ITEM' },
  KIT: { label: 'Kit', value: 'KIT' },
};

const usageExportTypes = {
  DEFAULT_EXCEL: 'DEFAULT_EXCEL',
  DEFAULT_CSV: 'DEFAULT_CSV',
  UNLEASHED_CSV: 'UNLEASHED_CSV',
  CIN_7: 'CIN_7'
};

export {
  statusOptions,
  kitTypeOptions,
  kitTypeBookingOptions,
  caseStatusOptions,
  caseUsageTypes,
  implantScanTypes,
  setAllocationStatuses,
  setPositionOptions,
  countStatusOptions,
  notificationTypes,
  kitVariantTypes,
  flowStepStatuses,
  caseChecklistStatuses,
  itemTypeOptions,
  countStatuses,
  countTypes,
  formSectionTypes,
  formDropdownOptions,
  formStatuses,
  setAllocationTypes,
  kitPreferenceItemTypes,
  billOfMaterialItemTypes,
  consumableStatuses,
  kitItemTypes,
  usageExportTypes,
};
