import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import LoadScreen from '../../../components/load-screen';
import SetTurnsForm from '../../../components/reporting/set-turns/SetTurnsForm';
import SetTurnsTable from '../../../components/reporting/set-turns/SetTurnsTable';

import { getKits } from '../../../actions/kitsActions';
import { getSetLogs, getSets, getLabels } from '../../../actions/setsActions';

import useLoading from '../../../hooks/useLoading';

import { setLogTypes } from '../../../constants/setTurnsReporting';

const SetTurnsReportingPage = () => {
  const dispatch = useDispatch();
  const kits = useSelector((state) => state?.kits?.list);
  const sets = useSelector((state) => state?.sets?.list);
  const labels = useSelector((state) => state?.sets.labels);

  const { loading: fetching, startLoading, stopLoading } = useLoading(false);

  const [kit, setKit] = useState(null);
  const [target, setTarget] = useState(5);
  const [range, setRange] = useState(3);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    onLoad().catch((err) => console.error(err));
  }, []);

  const onLoad = async () => {
    startLoading()
    try {
      await Promise.all([
        dispatch(getKits()),
        dispatch(getSets()),
        dispatch(getLabels()),
      ]);
    } catch (err) {
      console.error(err);
    } finally {
      stopLoading();
    }
  };

  const onSubmit = async () => {
    setLoading(true);
    try {
      const list = sets?.filter((item) => item.kit === kit);
      const kitId = kits?.find((item) => item.id === kit)?.kitId;
      const kitName = kits?.find((item) => item.id === kit)?.name;
      const result = [];

      for (const set of list) {
        const logs = await dispatch(getSetLogs(set.id, range));
        const turns = logs?.filter((log) => log.type === setLogTypes.SET_TURNS)?.length;
        const average = Math.round(turns / range);
        const performance = average >= target;

        result?.push({
          id: set.id,
          kitId,
          kitName,
          setId: set.id,
          setNumber: set.number,
          labels: set.labels || [],
          turns,
          average,
          performance
        });
      }

      setData(result);
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className='page-container reporting-page__container'>
      {fetching && <LoadScreen />}
      <div className='page-title'>Set Turns Report</div>
      <div className='reporting-page__body'>
        <SetTurnsForm
          kits={kits?.filter((k) => !k?.isParent)}
          kit={kit}
          setKit={setKit}
          target={target}
          setTarget={setTarget}
          range={range}
          setRange={setRange}
          onSubmit={onSubmit}
          loading={loading}
        />

        {data && !!data.length && (
          <SetTurnsTable
            data={data}
            labels={labels}
          />
        )}
      </div>
    </div>
  );
};

export default SetTurnsReportingPage;
