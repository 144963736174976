/* eslint-disable no-throw-literal */
import React, { useState, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Alert from '@material-ui/lab/Alert';

import { ProfileForm, CompanyConnections } from '../../../components/profile';

import { updateProfile, updatePassword, getCurrentUser, addConnection } from '../../../actions/currentUserActions';
import { getTenants } from '../../../actions/tenantActions';
import { withFirebase } from '../../../firebase';

import { useLoading, useAlert } from '../../../hooks';

import { settings } from '../../../constants';

import './profile-page.scss';

const DEFAULT_PASSWORD_LENGTH = 10;

const ProfilePage = ({ firebase }) => {
  const dispatch = useDispatch();
  const { loading, startLoading, stopLoading } = useLoading();
  const { showAlert } = useAlert();

  const currentUser = useSelector((state) => state.user.currentUser);
  const currentTenant = useSelector((state) => state.tenant.currentTenant);
  const tenants = useSelector((state) => state.tenant?.list);

  const [minLength, setMinLength] = useState(DEFAULT_PASSWORD_LENGTH);

  useEffect(() => {
    firebase.getSettingsConfig(settings.PASSWORD_MIN_LENGTH)
      .then((res) => setMinLength(res && res.value ? res.value : DEFAULT_PASSWORD_LENGTH))
      .catch((err) => console.error(err));
    dispatch(getTenants()).catch(console.error);
  }, []);

  const onSubmit = async (formObj, files) => {
    startLoading()
    const userObj = {
      firstName: formObj.firstName,
      lastName: formObj.lastName,
      phone: formObj.phone,
      disableAppNotifications: !formObj.enableAppNotifications,
      disableBookingEmails: !formObj.enableBookingEmails,
    };

    try {
      if (formObj.currentPassword && formObj.newPassword) {
        await changePassword(formObj);
        userObj.adminPassword = false;
      }

      await dispatch(updateProfile(userObj, files));
      await dispatch(getCurrentUser());
      showAlert('success', 'Profile has been successfully updated');
    } catch (err) {
      showAlert('error', err.message);
      console.error(err);
    } finally {
      stopLoading();
    }
  };

  const changePassword = async ({ currentPassword, newPassword, confirmPassword }) => {
    try {
      if (newPassword.length < minLength) {
        throw `Password must be of minimum ${minLength} characters length`;
      }
      if (newPassword !== confirmPassword) {
        throw 'Password does not match';
      }

      await dispatch(updatePassword(currentPassword, newPassword));
    } catch (err) {
      throw new Error(err);
    }
  };

  const initialValues = useMemo(() => {
    return {
      ...currentUser,
      profilePictureName: currentUser.profilePicture ? currentUser.profilePicture.fileName : '',
      enableAppNotifications: !currentUser.disableAppNotifications,
      enableBookingEmails: !currentUser.disableBookingEmails,
      currentPassword: '',
      newPassword: '',
      confirmPassword: '',
    };
  }, [currentUser]);

  const onAddConnection = async (tenantId) => {
    try {
      await dispatch(addConnection(tenantId));
      await dispatch(getCurrentUser());
    } catch (err) {
      throw err;
    }
  };

  return (
    <div className='profile-page__container'>
      <div className='d-flex'>
        <span className='profile-page__title'>Profile</span>
        {currentUser?.adminPassword && (
          <div style={{ marginBottom: 24, marginLeft: 16 }}>
            <Alert severity='warning'>You have default password set by admin. Please change to a new one.</Alert>
          </div>
        )}
      </div>

      <div className='d-flex'>
        <ProfileForm
          initialValues={initialValues}
          loading={loading}
          onSubmit={onSubmit}
          adminPassword={currentUser?.adminPassword}
        />

        <CompanyConnections
          currentTenant={currentTenant}
          tenants={tenants}
          connectedTenants={currentUser?.connectedTenants}
          userRole={currentUser?.role}
          onAddConnection={onAddConnection}
          assignedSurgeons={currentUser?.assignedSurgeons}
        />
      </div>
    </div>
  );
};

export default withFirebase(ProfilePage);
