import React, { useEffect, useState } from 'react';
import { Route, Switch, Redirect, useHistory } from 'react-router-dom';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useIdleTimer } from 'react-idle-timer'

import HomePage from './home';
import {
  CompanyPage, GroupsPage, HospitalsPage, KitsSettingsPage,
  ProceduresPage, ProductsPage, SettingsPage, SurgeonsPage,
  UsersPage, DriveSettingsPage, ScanPage, SetsSettingsPage,
  CustomQRPage, GtinReferencePage, NotificationsSettingsPage,
  EmailTriggersPage, ProductTriggersPage, UsageTriggersPage,
  FlowsPage, ItemsPage, BillOfMaterialPage, RepeatingCountsPage,
  FormsSettingsPage, ResupplyTriggersPage, OverdueShippingTriggersPage,
  TerritoriesPage, HospitalsLocationsPage, TagsPage,
  KitPreferencesPage, KitParentPage,
} from './settings';
import {
  CasesPage,
  CasePage,
  CaseChecklistsPage,
  CaseReturnSetsPage,
  CaseFormPage,
  CaseReturnAdditionalSetsPage,
} from './cases';
import {
  CustomersPage, CustomerSurgeonsPage, CustomerFormsPage,
  CustomerHospitalsPage, CustomerTasksPage
} from './customers';
import AnalyticsPage from './analytics';
import DrivePage from './drive';
import NotificationsPage from './notifications';
import KitsPage from './kits';
import SetsPage, { BatchControlPage } from './sets';
import ProfilePage from './profile';
import UsagePage from './usage';
import { InventoryPage, CountPage, CountBOMPage } from './inventory';
import { SubmittedFormsPage, FormPage } from './forms';
import {
  ReportingPage, CaseReportingPage, KitReportingPage, UsageReportingPage,
  BillingReportingPage, CountReportingPage, ItemsReportingPage, BOMLookupReportingPage,
  SetTurnsReportingPage, ConsignmentTurnsReportingPage, ExpiringItemsReportPage,
  ManagementReportsPage, SetSummaryReportingPage
} from './reporting';
import BOMViewPage from './bill-of-material';
import AdminPage from './admin';

import LoadScreen from '../../components/load-screen';
import SideMenu from '../../components/sidebar';
import Header from '../../components/app-header';
import ConfirmationModal from '../../components/shared/modal/ConfirmationModal';
import Snackbar from '../../components/shared/Snackbar';

import { signOut } from '../../actions/authActions';
import { subscribeToCurrentUser } from '../../actions/currentUserActions';
import { subscribeToCurrentTenant } from '../../actions/tenantActions';
import { getUsers, getConnectedUsers } from '../../actions/usersActions';
import { getGroups } from '../../actions/groupsActions';
import {
  subscribeToCases,
  getSetsAllocation,
  setOverdueSetsNumber,
  subscribeToTransferRequests,
  subscribeToAssignedCases,
  getConnectedCases,
} from '../../actions/casesActions';
import { subscribeToNotifications, closeNotification } from '../../actions/notificationsActions';
import { getQuarantinedSets } from '../../actions/setsActions';
import { fetchNewForms } from '../../actions/formsActions';
import { getHospitals } from '../../actions/hospitalsActions';

import { routes } from '../../constants';
import { roleNames } from '../../constants/userRoles';
// import { setAllocationStatuses } from '../../constants/enums';

import './app-page.scss';

const { ADMIN, OPERATIONS, CUSTOMER_SERVICE, LOGISTICS, SALES_MANAGER, SALES_REP, MARKETING, FINANCE, PRACTICE_MANAGER } = roleNames;
// const { SHIPPED, ASSIGNED, AVAILABLE } = setAllocationStatuses;

const TIMEOUT = 1000 * 60 * 30; // 15 minutes
const PROMPT_TIMEOUT = 1000 * 30; // 30 seconds

const AppPage = (props) => {
  const {
    currentUser,
    signOut,
    getUsers,
    getConnectedUsers,
    getGroups,
    isLoading,
    isLoaded,
    subscribeToCurrentTenant,
    subscribeToCases,
    subscribeToTransferRequests,
    subscribeToAssignedCases,
    subscribeToNotifications,
    fetchNewForms,
    getHospitals,
    getConnectedCases,
    // cases,
    // getSetsAllocation,
    // setOverdueSetsNumber,
    // getQuarantinedSets,
  } = props;

  const history = useHistory();
  const dispatch = useDispatch();

  const lastDocTimestamp = useSelector((state) => state.notifications.lastDocTimestamp);
  const notificationOpen = useSelector((state) => state.notifications.open);
  const notification = useSelector((state) => state.notifications.notification);
  const tenantColor = useSelector((state) => state.tenant.currentTenant.colorPrimary);

  const [compressed, setCompressed] = useState(false);
  const [open, setOpen] = useState(false);
  // const [currentPath, setCurrentPath] = useState(window.location.pathname);
  // const [, setPreviousPath] = useState(null);

  const onPrompt = () => {
    setOpen(true);
  }

  const handleOnIdle = async () => {
    console.log('last active', getLastActiveTime());
    setOpen(false);
    history.push('/');
    setTimeout(() => {
      signOut().then(() => {
        window.location = '/';
      });
    }, 1000);
  };

  const { getLastActiveTime } = useIdleTimer({
    timeout: TIMEOUT,
    promptBeforeIdle: PROMPT_TIMEOUT,
    onIdle: handleOnIdle,
    onPrompt,
    debounce: 500,
  });

  useEffect(() => {
    const isCompressed = JSON.parse(localStorage.getItem('collapsedCaseSidebar'));
    if (isCompressed) {
      setCompressed(!!isCompressed);
    }
  }, []);

  // useEffect(() => {
  //   const unlisten = history.listen((location) => {
  //     setPreviousPath(currentPath);
  //     setCurrentPath(location.pathname);
  //
  //     const arr = currentPath?.split('/');
  //     if (arr.length === 3 && arr[1] === 'cases') {
  //       const caseId = arr[2];
  //       dispatch(turnOffEditingState(caseId)).catch(console.error)
  //     }
  //   });
  //
  //   return () => {
  //     unlisten();
  //   };
  // }, [currentPath, history]);

  useEffect(() => {
    const unsubscribeToCurrentTenant = subscribeToCurrentTenant();
    const unsubscribeToCases = subscribeToCases();
    const unsubscribeToTransferRequests = subscribeToTransferRequests();
    const unsubscribeToAssignedCases = subscribeToAssignedCases();
    const unsubscribeToNotifications = subscribeToNotifications(lastDocTimestamp);
    // let unsubscribe;

    onLoad()
      .then(() => {
        // Subscribe to current user changes
        // unsubscribe = subscribeToCurrentUser();
      })
      .catch((err) => console.error(err));

    return () => {
      unsubscribeToCurrentTenant();
      unsubscribeToNotifications();
      if (unsubscribeToCases && typeof unsubscribeToCases === 'function') {
        unsubscribeToCases();
      }
      if (unsubscribeToTransferRequests && typeof unsubscribeToTransferRequests === 'function') {
        unsubscribeToTransferRequests();
      }
      if (unsubscribeToAssignedCases && typeof unsubscribeToAssignedCases === 'function') {
        unsubscribeToAssignedCases();
      }
      // unsubscribe();
    };
  }, [lastDocTimestamp]);

  useEffect(() => {
    if (currentUser?.adminPassword) {
      history.push(routes.PROFILE);
    }
  }, [currentUser]);

  // useEffect(() => {
  //   if (cases && cases.length) {
  //     checkOverdueSets(cases).catch(console.error);
  //   }
  // },[cases]);

  // const checkOverdueSets = async (cases) => {
  //   let result = 0;
  //   const filteredCases = cases?.filter((item) => getDiffDays(item.date, moment()) < 4);
  //   for (const item of filteredCases) {
  //     const allocations = await getSetsAllocation(item.id);
  //     const total = allocations?.filter((item) => [ASSIGNED.value, SHIPPED.value, AVAILABLE.value].includes(item.status));
  //     const shipped = allocations?.filter((item) => item.status === SHIPPED.value);
  //     const date = moment().subtract(7, 'days').endOf('day');
  //     const caseDate = moment(item.date).endOf('day');
  //     const shipDate = moment(item.date).subtract(3, 'days');
  //     const days = getDiffDays(shipDate, moment());
  //
  //     if (!!allocations?.length && total.length !== shipped.length && days < 1 && !date.isAfter(caseDate)) {
  //       result = result + 1;
  //     }
  //   }
  //   setOverdueSetsNumber(result);
  // };

  const onLoad = async () => {
    await getUsers();
    await getConnectedUsers();
    await getHospitals();
    await getGroups();
    await fetchNewForms();
    await getConnectedCases();
  };

  if (isLoading && !isLoaded) {
    return <LoadScreen />;
  }

  const onCollapse = (value) => {
    setCompressed(value);
    localStorage.setItem('collapsedCaseSidebar', value);
  };

  return (
    <div className='app-page'>
      <Header
        user={currentUser}
        signOut={signOut}
        compressed={compressed}
        setCompressed={onCollapse}
      />
      <div className='body'>
        <SideMenu compressed={compressed} />
        <div className='app-wrapper'>
          <Switch>
            <Route exact path={routes.HOME} component={HomePage} />
            <Route exact path={routes.CASES} component={CasesPage} />
            <Route exact path={`${routes.CASES}/:id`} component={CasePage} />
            <Route exact path={`${routes.CASES}/:id${routes.CHECKLISTS}`} component={CaseChecklistsPage} />
            <Route exact path={`${routes.CASES}/:id${routes.RETURN_SETS}`} component={CaseReturnSetsPage} />
            <Route exact path={`${routes.CASES}/:id${routes.RETURN_ADDITIONAL_SETS}`} component={CaseReturnAdditionalSetsPage} />
            <Route exact path={`${routes.CASES}/:id${routes.FORMS}/:formId`} component={CaseFormPage} />
            <Route exact path={routes.DRIVE} component={DrivePage} />
            <Route exact path={routes.ANALYTICS} component={AnalyticsPage} />
            <Route exact path={routes.NOTIFICATIONS} component={NotificationsPage} />
            <Route exact path={routes.PROFILE} component={ProfilePage} />

            {[ADMIN, OPERATIONS].includes(currentUser.role) && (
              <Route path={routes.REPORTING}>
                <Switch>
                  <Route exact path={routes.REPORTING} component={ReportingPage} />
                  <Route exact path={routes.CASE_REPORTING} component={CaseReportingPage} />
                  <Route exact path={routes.KIT_REPORTING} component={KitReportingPage} />
                  <Route exact path={routes.BILLING_REPORTING} component={BillingReportingPage} />
                  <Route exact path={routes.COUNT_REPORTING} component={CountReportingPage} />
                  <Route exact path={routes.ITEMS_REPORTING} component={ItemsReportingPage} />
                  <Route exact path={routes.USAGE_REPORTING} component={UsageReportingPage} />
                  <Route exact path={routes.SET_TURNS_REPORTING} component={SetTurnsReportingPage} />
                  <Route exact path={routes.CONSIGNMENT_TURNS_REPORTING} component={ConsignmentTurnsReportingPage} />
                  <Route exact path={routes.EXPIRING_ITEMS_REPORTING} component={ExpiringItemsReportPage} />
                  <Route exact path={routes.BOM_LOOKUP_REPORTING} component={BOMLookupReportingPage} />
                  <Route exact path={routes.MANAGEMENT_REPORTS} component={ManagementReportsPage} />
                  <Route exact path={routes.SET_SUMMARY_REPORTING} component={SetSummaryReportingPage} />
                  <Redirect to={routes.REPORTING} />
                </Switch>
              </Route>
            )}

            {[ADMIN, OPERATIONS, CUSTOMER_SERVICE].includes(currentUser.role) && (
              <Route exact path={routes.USAGE} component={UsagePage} />
            )}

            {[ADMIN, OPERATIONS, LOGISTICS].includes(currentUser.role) && (
              <Route exact path={routes.KITS} component={KitsPage} />
            )}

            {[ADMIN, OPERATIONS, LOGISTICS].includes(currentUser.role) && (
              <Route path={routes.SETS}>
                <Route exact path={routes.SETS} component={SetsPage} />
                <Route exact path={`${routes.SETS}/:id${routes.BATCH_CONTROL}`} component={BatchControlPage} />
              </Route>
            )}

            <Route exact path={`${routes.BILL_OF_MATERIAL}/:id`} component={BOMViewPage} />

            {[ADMIN, OPERATIONS, CUSTOMER_SERVICE, SALES_MANAGER, MARKETING, FINANCE, PRACTICE_MANAGER, LOGISTICS].includes(currentUser.role) && (
              <Route exact path={routes.SUBMITTED_FORMS} component={SubmittedFormsPage} />
            )}

            <Route path={routes.FORMS}>
              <Route exact path={`${routes.FORMS}/:id`} component={FormPage} />
            </Route>

            {[ADMIN, OPERATIONS, SALES_REP, LOGISTICS].includes(currentUser.role) && (
              <Route path={routes.INVENTORY}>
                <Route exact path={routes.INVENTORY} component={InventoryPage} />
                <Route exact path={`${routes.INVENTORY}/:id`} component={CountPage} />
                <Route exact path={`${routes.INVENTORY}/:id${routes.BILL_OF_MATERIAL}`} component={CountBOMPage} />
              </Route>
            )}

            {[ADMIN, OPERATIONS, SALES_REP, CUSTOMER_SERVICE, SALES_MANAGER, MARKETING, FINANCE].includes(currentUser.role) && (
              <Route path={routes.TOOLS}>
                <Switch>
                  <Route exact path={routes.TOOLS} component={CustomersPage} />
                  <Route exact path={routes.TOOLS_SURGEONS} component={CustomerSurgeonsPage} />
                  <Route exact path={routes.TOOLS_HOSPITALS} component={CustomerHospitalsPage} />
                  <Route exact path={routes.TOOLS_TASKS} component={CustomerTasksPage} />
                  <Route exact path={routes.TOOLS_FORMS} component={CustomerFormsPage} />
                  <Redirect to={routes.TOOLS} />
                </Switch>
              </Route>
            )}

            {[ADMIN].includes(currentUser.role) && (
              <Route path={routes.SETTINGS}>
                <Switch>
                  <Route exact path={routes.SETTINGS} component={SettingsPage} />
                  <Route exact path={routes.USERS} component={UsersPage} />
                  <Route exact path={routes.GROUPS} component={GroupsPage} />
                  <Route exact path={routes.PROCEDURES} component={ProceduresPage} />
                  <Route exact path={routes.LOCATIONS} component={HospitalsLocationsPage} />
                  <Route exact path={routes.HOSPITALS} component={HospitalsPage} />
                  <Route exact path={routes.TERRITORIES} component={TerritoriesPage} />
                  <Route exact path={routes.PRODUCTS} component={ProductsPage} />
                  <Route exact path={routes.KITS_SETTINGS} component={KitsSettingsPage} />
                  <Route exact path={routes.SETS_SETTINGS} component={SetsSettingsPage} />
                  <Route exact path={routes.SURGEONS} component={SurgeonsPage} />
                  <Route exact path={routes.COMPANY} component={CompanyPage} />
                  <Route exact path={routes.DRIVE_SETTINGS} component={DriveSettingsPage} />
                  <Route exact path={routes.SCAN} component={ScanPage} />
                  <Route exact path={routes.SCAN_QR} component={CustomQRPage} />
                  <Route exact path={routes.SCAN_GTIN} component={GtinReferencePage} />
                  <Route exact path={routes.NOTIFICATIONS_SETTINGS} component={NotificationsSettingsPage} />
                  <Route exact path={routes.EMAIL_NOTIFICATIONS} component={EmailTriggersPage} />
                  <Route exact path={routes.PRODUCT_NOTIFICATIONS} component={ProductTriggersPage} />
                  <Route exact path={routes.USAGE_NOTIFICATIONS} component={UsageTriggersPage} />
                  <Route exact path={routes.RESUPPLY_NOTIFICATIONS} component={ResupplyTriggersPage} />
                  <Route exact path={routes.OVERDUE_SHIPPING_NOTIFICATIONS} component={OverdueShippingTriggersPage} />
                  <Route exact path={routes.FLOWS} component={FlowsPage} />
                  <Route exact path={routes.KIT_PREFERENCES} component={KitPreferencesPage} />
                  <Route exact path={routes.ITEMS} component={ItemsPage} />
                  <Route exact path={routes.REPEATING_COUNTS} component={RepeatingCountsPage} />
                  <Route exact path={`${routes.KITS_SETTINGS}/:id`} component={BillOfMaterialPage} />
                  <Route exact path={`${routes.KITS_SETTINGS}/:id${routes.PARENT_KIT_SETTINGS}`} component={KitParentPage} />
                  <Route exact path={routes.FORMS_SETTINGS} component={FormsSettingsPage} />
                  <Route exact path={routes.TAGS} component={TagsPage} />
                  <Redirect to={routes.SETTINGS} />
                </Switch>
              </Route>
            )}

            {currentUser.role === MARKETING && (
              <Route path={routes.SETTINGS}>
                <Switch>
                  <Route exact path={routes.SETTINGS} component={SettingsPage} />
                  <Route exact path={routes.DRIVE_SETTINGS} component={DriveSettingsPage} />
                  <Redirect to={routes.SETTINGS} />
                </Switch>
              </Route>
            )}

            {!!currentUser.isGlobalAdmin && (
              <Route exact path={routes.ADMIN} component={AdminPage} />
            )}

            <Redirect to={routes.HOME} />
          </Switch>
        </div>
      </div>

      <ConfirmationModal
        open={open}
        onClose={handleOnIdle}
        onSubmit={handleOnIdle}
        title='Session Timout'
        text='Your session has timed out due to inactivity. Please sign into your account to continue.'
        submitText='OK'
      />

      <Snackbar
        open={notificationOpen}
        notification={notification}
        onClose={() => dispatch(closeNotification())}
        tenantColor={tenantColor}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    currentUser: state.user.currentUser,
    cases: state.cases.list,
    isLoading: state.users.isLoading,
    isLoaded: state.users.isLoaded
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    signOut: () => dispatch(signOut()),
    getConnectedUsers: () => dispatch(getConnectedUsers(true)),
    getGroups: () => dispatch(getGroups()),
    getHospitals: () => dispatch(getHospitals()),
    subscribeToCases: () => dispatch(subscribeToCases()),
    subscribeToTransferRequests: () => dispatch(subscribeToTransferRequests()),
    subscribeToAssignedCases: () => dispatch(subscribeToAssignedCases()),
    getUsers: () => dispatch(getUsers()),
    subscribeToCurrentUser: () => dispatch(subscribeToCurrentUser()),
    subscribeToCurrentTenant: () => dispatch(subscribeToCurrentTenant()),
    subscribeToNotifications: (lastDocTimestamp) => dispatch(subscribeToNotifications(lastDocTimestamp)),
    getSetsAllocation: (caseId) => dispatch(getSetsAllocation(caseId, true)),
    setOverdueSetsNumber: (value) => dispatch(setOverdueSetsNumber(value)),
    getQuarantinedSets: () => dispatch(getQuarantinedSets()),
    fetchNewForms: () => dispatch(fetchNewForms()),
    getConnectedCases: () => dispatch(getConnectedCases(true)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AppPage);
