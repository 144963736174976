import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import moment from 'moment';
import { v4 as uuid } from 'uuid';
import { pdf } from '@react-pdf/renderer';

import Menu from '@material-ui/core/Menu';
import { withStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';

import ExportIcon from '../../../assets/icons/DownloadIcon';
import ImportIcon from '../../../assets/icons/UploadIcon';
import PrintIcon from '../../../assets/icons/PrintIcon';

import Input from '../../../components/shared/Input';
import LoadScreen from '../../../components/load-screen';
import Button from '../../../components/shared/Button';
import SetInfo from '../../../components/sets/batch-control/SetInfo';
import BatchSection from '../../../components/sets/batch-control/BatchSection';
import BatchImportModal from '../../../components/sets/batch-control/BatchImportModal';
import ChecklistsPDF from '../../../components/cases/checklists/ChecklistsPDF';

import { getKit, getSections, } from '../../../actions/kitsActions';
import {
  exportBatchItems,
  exportItems,
  getSet,
  updateBatchItems,
  updateItemQuantity,
  updateSet,
} from '../../../actions/setsActions';
import { getItemsByIds } from '../../../actions/itemsActions';

import { useLoading } from '../../../hooks';

import { fromMomentToTimestampDay } from '../../../utils/date';
import { downloadFile } from '../../../utils/utils';

import routes from '../../../constants/routes';
import { billOfMaterialItemTypes, setPositionOptions } from '../../../constants/enums';

const ITEM_HEIGHT = 48;

const StyledMenuItem = withStyles((theme) => ({
  root: {
    paddingTop: '12px',
    paddingBottom: '12px',
  }
}))(MenuItem);

const getPosition = (set) => {
  if (set.quarantined) {
    return setPositionOptions.QUARANTINED.value;
  }
  if (set.consigned) {
    return setPositionOptions.CONSIGNED.value;
  }
  return set.caseAllocation ? setPositionOptions.ALLOCATED.value : setPositionOptions.AVAILABLE.value;
};

const BatchControlPage = () => {
  const { id } = useParams();
  const setId = id.replace(':', '');
  const history = useHistory();

  const dispatch = useDispatch();
  const tenant = useSelector((state) => state?.tenant.currentTenant);
  const kits = useSelector((state) => state?.kits.list);
  const tenantColor = useSelector((state) => state.tenant.currentTenant.colorPrimary);

  const { loading, startLoading, stopLoading } = useLoading(false);
  const { loading: fetching, startLoading: startFetching, stopLoading: stopFetching } = useLoading(false);
  const { loading: exporting, startLoading: startExporting, stopLoading: stopExporting } = useLoading(false);
  const { loading: printing, startLoading: startPrinting, stopLoading: stopPrinting } = useLoading(false);

  const [itemsList, setItemsList] = useState([]);

  const [set, setSet] = useState(null);
  const [kit, setKit] = useState(null);

  const [search, setSearch] = useState('');
  const [modalOpen, toggleModal] = useState(false);
  const [sections, setSections] = useState([]);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    onLoad().catch((err) => console.error(err));
  }, []);

  const onLoad = async () => {
    startFetching();

    try {
      const res = await dispatch(getSet(setId));
      if (res && res?.id) {
        setSet({ ...res, position: getPosition(res), itemsQuantity: res.itemsQuantity || {} });

        const kitRes = await dispatch(getKit(res?.kit));
        setKit(kitRes);

        const sectionsRes = await dispatch(getSections(res.kit));

        let _items = [];
        for (const section of sectionsRes) {
          const itemIds = section.items?.filter((i) => i.type !== billOfMaterialItemTypes.NOTES.value).map((i) => i.id) || [];
          const newItems = await dispatch(getItemsByIds(itemIds));
          _items = [..._items, ...newItems];
        }
        setItemsList(_items);

        if (sectionsRes && Array.isArray(sectionsRes)) {
          const _sections = sectionsRes.map((section) => {
            const updatedItems = section.items?.map((item) => {
              const _item = _items.find((i) => i.id === item.id);
              return _item || item.type === billOfMaterialItemTypes.NOTES.value ? { ...item, ..._item } : null;
            }).filter((item) => !!item);
            return { ...section, items: updatedItems };
          });

          setSections(_sections);
        }
      }
    } catch (err) {
      console.error(err);
    } finally {
      stopFetching();
    }
  };

  const onBackClick = () => {
    history.push(routes.SETS);
  };

  const onItemsChange = async (item, values) => {
    try {
      startLoading();
      await dispatch(updateBatchItems(set, item.id, values));
      const res = await dispatch(getSet(setId));
      if (res && res?.id) {
        setSet({ ...res, position: getPosition(res) });
      }
    } catch (err) {
      console.error(err);
    } finally {
      stopLoading();
    }
  };

  const onQuantityChange = (item, value) => {
    setSet({
      ...set,
      itemsQuantity: {
        ...set.itemsQuantity,
        [item.id]: value,
      }
    });
    dispatch(updateItemQuantity(set, item.id, value));
  };

  const onExportClick = async () => {
    handleClose();
    try {
      startExporting();

      const items = [];

      sections?.forEach((section) => {
        section?.items?.forEach((item) => {
          if (item?.type !== billOfMaterialItemTypes.NOTES.value) {
            let allocated = 0;

            if (!!item.batchControl) {
              const batch = set?.batchItems[item?.id];
              allocated = batch !== undefined ? batch?.length : item.quantity;
            } else {
              const itemsQuantity = set?.itemsQuantity[item?.id];
              allocated = itemsQuantity !== undefined ? itemsQuantity : item.quantity;
            }

            items?.push({
              setNumber: set?.number,
              kitId: kit?.kitId,
              kitName: kit?.name,
              sectionTitle: section.name,
              sectionSubtitle: section.subtitle,
              code: item.code,
              description: item.description,
              checklistDescription: item.checklistDescription,
              type: item.type,
              ref: item.ref,
              quantity: item.quantity || 1,
              allocated: allocated || 0,
            });
          }
        });
      });

      await dispatch(exportItems(items, set.number));
    } catch (err) {
      console.error(err);
    } finally {
      stopExporting();
    }
  };

  const onExportBatchClick = async () => {
    handleClose();
    try {
      startExporting();

      const items = [];

      sections?.forEach((section) => {
        section?.items?.forEach((item) => {
          if (!!item.batchControl) {
            const values = set?.batchItems[item.id] || [];
            items?.push({
              code: item.code,
              description: item.checklistDescription || item.description,
              quantity: item.quantity,
              batchValues: values?.map((i) => ({
                code: i.code || '',
                expDate: i.expDate ? moment(i.expDate)?.format('yyyy/MM/DD') : '',
              })),
            });
          }
        });
      });

      await dispatch(exportBatchItems(items, set.number));
    } catch (err) {
      console.error(err);
    } finally {
      stopExporting();
    }
  };

  const onImport = async (data) => {
    try {
      startExporting();

      const batchItems = set?.batchItems || {};

      for (const item of data) {
        const code = item[0] || '';
        const _item = itemsList?.find((i) => i.code === code);

        if (_item) {
          const values = set?.batchItems?.[_item.id] || [];
          const quantity = item[2] || _item.quantity;

          for (let i = 0; i < quantity; i++) {
            values[i] = {
              id: values[i]?.id || uuid(),
              code: item[3 + i * 2] || values[i]?.code || '',
              expDate: fromMomentToTimestampDay(item[4 + i * 2] ? moment(item[4 + i * 2]) : values[i]?.expDate) || null
            }
          }

          batchItems[_item.id] = values?.filter((i) => !!i.code);
        }
      }

      await dispatch(updateSet(set?.id, { batchItems }))
      const res = await dispatch(getSet(setId));
      if (res && res?.id) {
        setSet({ ...res, position: getPosition(res) });
      }
      toggleModal(false);
    } catch (err) {
      console.error(err);
    } finally {
      stopExporting();
    }
  };

  const onPrintClick = async () => {
    startPrinting();
    await downloadChecklistsDoc();
    stopPrinting();
  };

  const downloadChecklistsDoc = async () => {
    const checklists = [{
      kitId: kit?.kitId,
      kitName: kit?.name,
      setNumber: set?.number,
      sections: sections?.map((section) => ({
        ...section,
        items: section?.items?.map((item) => {
          if (item.type === billOfMaterialItemTypes.NOTES.value) {
            return item;
          } else {
            const _item = itemsList?.find((i) => i.id === item.id);
            return { ...item, code: _item.code || '', description: _item.checklistDescription || _item.description || '' };
          }
        })
      })),
    }];

    const blob = await pdf(
      <ChecklistsPDF
        tenant={tenant}
        checklists={checklists}
        sets={[set]}
        kits={kits}
      />
    ).toBlob();
    downloadFile(blob, `${set.number} – BOM.pdf`);
  };

  return (
    <div className='settings-cmp__main flex-1 bom-page__container'>
      {fetching && <LoadScreen />}

      <span className='settings-title'>Replenish</span>
      <div className='bom-page__body align-start'>
        <div className='d-flex' style={{ flex: 0.3 }}>
          <Button
            type='outlined'
            text='Back to Sets'
            onClick={onBackClick}
          />
          <div className='m-l-lg d-flex'>
            <div className='m-r-md'>
              <Button
                onClick={handleClick}
                disabled={exporting}
                loading={exporting}
                width={60}
              >
                {!exporting && <ExportIcon color="#ffffff" />}
              </Button>
            </div>
            <div className='m-l-md'>
              <Button
                type='icon'
                onClick={onPrintClick}
                disabled={printing}
                loading={printing}
                width={60}
              >
                {!printing && <PrintIcon color="#000000" />}
              </Button>
            </div>
            <Menu
              id='long-menu'
              anchorEl={anchorEl}
              keepMounted
              open={open}
              onClose={handleClose}
              PaperProps={{
                style: {
                  maxHeight: ITEM_HEIGHT * 4.5,
                  width: 200,
                },
                elevation: 3,
              }}
            >
              <StyledMenuItem onClick={onExportClick}>
                <ExportIcon />
                <span className='m-l-md'>Export Items</span>
              </StyledMenuItem>
              <StyledMenuItem onClick={onExportBatchClick}>
                <ExportIcon />
                <span className='m-l-md'>Export Batch Items</span>
              </StyledMenuItem>
              <StyledMenuItem
                onClick={() => {
                  handleClose();
                  toggleModal(true);
                }}
              >
                <ImportIcon />
                <span className='m-l-md'>Import Items</span>
              </StyledMenuItem>
            </Menu>
          </div>
        </div>
        {set && !!set.id && (
          <div style={{ flex: 0.7 }}>
            <div>
              <SetInfo setNumber={set?.number} position={set?.position} />
              <div>
                <Input
                  type='search'
                  placeholder='Search'
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
              </div>
            </div>
          </div>
        )}
      </div>

      {set && !!set.id && (
        <div>
          {sections && !!sections?.length && (
            sections.map((section, index) => (
              <BatchSection
                key={`${section.name}_${section.subtitle}`}
                sectionIndex={index}
                onChange={onItemsChange}
                onQuantityChange={onQuantityChange}
                tenantColor={tenant?.colorPrimary}
                batchItems={set?.batchItems}
                itemsQuantity={set?.itemsQuantity}
                loading={loading}
                {...section}
              />
            ))
          )}
        </div>
      )}

      <BatchImportModal
        open={modalOpen}
        onClose={() => toggleModal(false)}
        onSubmit={onImport}
        loading={exporting}
        tenantColor={tenantColor}
      />
    </div>
  );
};

export default BatchControlPage;
