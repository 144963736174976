const defaultHeaders = {
  'Content-Type': 'application/json'
};

const getHeaders = (idToken) => {
  let headers = defaultHeaders;

  if (idToken) {
    headers = {
      ...defaultHeaders,
      Authorization: `Bearer ${idToken}`
    };
  }

  return headers;
};

const responseHandler = (response) => {
  if (response && response.statusCode === 200) {
    return response.body;
  }
  if (response && response.statusCode >= 400) {
    throw new Error(response.body.error);
  }
  return response;
};

export const get = (url, idToken) => fetch(url, {
  method: 'GET',
  headers: getHeaders(idToken)
})
  .catch((error) => console.log(error))
  .then((res) => res && res.json())
  .then(responseHandler);

export const post = (url, data, idToken) => fetch(url, {
  method: 'POST',
  headers: getHeaders(idToken),
  body: JSON.stringify(data)
})
  .catch((error) => console.log(error))
  .then((res) => res && res.json())
  .then(responseHandler);

export const remove = (url, idToken) => fetch(url, {
  method: 'DELETE',
  headers: getHeaders(idToken)
})
  .catch((error) => console.log(error))
  .then((res) => res && res.json())
  .then(responseHandler);

export const put = (url, data, idToken) => fetch(url, {
  method: 'PUT',
  headers: getHeaders(idToken),
  body: JSON.stringify(data)
})
  .catch((error) => console.log(error))
  .then((res) => res && res.json())
  .then(responseHandler);

export const getFile = (url, idToken, fileName = 'filename', type = 'xlsx') => fetch(url, {
  method: 'GET',
  headers: getHeaders(idToken)
})
  .catch((error) => console.log(error))
  .then((res) => res.blob())
  .then((blob) => {
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `${fileName}.${type}`;
    document.body.appendChild(a);
    a.click();
    a.remove();
  });

export const getFileWithParameters = (url, idToken, fileName = 'filename', type = 'xlsx', body) => fetch(url, {
  method: 'POST',
  headers: getHeaders(idToken),
  body: JSON.stringify(body)
})
  .catch((error) => console.log(error))
  .then((res) => res.blob())
  .then((blob) => {
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `${fileName}.${type}`;
    a.target = '_blank';
    document.body.appendChild(a);
    a.click();
    a.remove();
  });

export const openFileInNewTab = (url, idToken) => fetch(url, {
  method: 'GET',
  headers: getHeaders(idToken)
})
  .catch((error) => console.log(error))
  .then((res) => res.blob())
  .then((blob) => {
    const url = window.URL.createObjectURL(blob);
    const pdfWindow = window.open();
    pdfWindow.location.href = url;
  });
