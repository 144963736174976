import React from 'react';

import Chip from '@material-ui/core/Chip';
import IconButton from '@material-ui/core/IconButton';
import FilterIcon from '@material-ui/icons/FilterList';

import './kits.scss';

const KitFiltersList = (props) => {
  const {
    onClick,
    activeTab = 0,
    hideShipped,
    hideConsignment,
    hideLoan,
    hideClosed,
    surgeon,
    state,
    hospital,
    onDelete,
    showCanceled,
  } = props;

  return (
    <div className='kit-filters-list__container'>
      <IconButton onClick={onClick}>
        <FilterIcon />
      </IconButton>

      {hideShipped && activeTab === 1 && (
        <Chip label='Hide All Shipped' onDelete={() => onDelete('shipped')} />
      )}

      {/*{hideReturned && activeTab === 1 && (*/}
      {/*  <div className='kit-filters-list__item'>*/}
      {/*    Hide All Returned*/}
      {/*  </div>*/}
      {/*)}*/}

      {hideConsignment && (
        <Chip label='Hide Consignment' onDelete={() => onDelete('consignment')} />
      )}

      {hideLoan && (
        <Chip label='Hide Loan' onDelete={() => onDelete('loan')} />
      )}

      {hideClosed && (
        <Chip label='Hide Closed' onDelete={() => onDelete('closed')} />
      )}

      {showCanceled && (
        <Chip label='Show Canceled' onDelete={() => onDelete('canceled')} />
      )}

      {surgeon && (
        <Chip label={surgeon} onDelete={() => onDelete('surgeon')} />
      )}

      {hospital && (
        <Chip label={hospital} onDelete={() => onDelete('hospital')} />
      )}

      {state && (
        <Chip label={state} onDelete={() => onDelete('state')} />
      )}
    </div>
  );
};

export default KitFiltersList;
