import React from 'react';

import IconButton from '@material-ui/core/IconButton';
import ArrowIcon from '@material-ui/icons/KeyboardArrowLeft';
import FullScreenIcon from '@material-ui/icons/Fullscreen';
import CircularProgress from '@material-ui/core/CircularProgress';

import { billOfMaterialItemTypes } from '../../../constants/enums';

const BOMSectionsView = ({ sections, kitName, onBackClick, items = [], loading }) => {
  return (
    <div>
      <div className="d-flex">
        <IconButton
          color="primary"
          className="m-r-md"
          onClick={onBackClick}
          size="small"
        >
          <ArrowIcon fontSize="large" color="primary" />
        </IconButton>
        <div className="m-l-sm font-bold font-size-bg">
          {kitName}
        </div>
      </div>

      {loading && <div className="d-flex flex-center p-md">
        <CircularProgress size={36} />
      </div>}

      {sections?.map((section) => (
        <div key={section.id} className='m-b-md'>
          {!!section.image && (
            <div className="image-container" style={{ maxHeight: '280px', flexDirection: 'column' }}>
              <img src={section?.image?.downloadUrl} alt="section-preview" />
              <div className='d-flex space-between width-100 flex-end' style={{ padding: '0 16px 16px 16px' }}>
                <div>
                  <div className='font-bold'>{section.name}</div>
                  <div className='m-t-sm secondary'>{section.subtitle}</div>
                </div>
                <div
                  className="pointer"
                  onClick={() => window.open(section?.image?.downloadUrl, '_blank')}
                >
                  <FullScreenIcon color="secondary" fontSize="large" />
                </div>
              </div>
            </div>
          )}

          {!section?.image && (
            <div className='p-l-lg p-t-sm p-b-md'>
              <div className='font-bold'>{section.name}</div>
              <div className='m-t-sm secondary'>{section.subtitle}</div>
            </div>
          )}

          <table className="bom-items-table">
            <thead>
            <tr>
              <th>Item Code</th>
              <th>Description</th>
              <th>Type</th>
              <th>Ref</th>
              <th>QTY</th>
            </tr>
            </thead>
            <tbody>
            {section?.items?.filter((item) => item.type !== billOfMaterialItemTypes.NOTES.value).map((item) => {
              const _item = items?.find((i) => i.id === item.id);
              return _item ? (
                <tr key={item.id}>
                  <td>{_item.code}</td>
                  <td>{_item.checklistDescription || _item.description}</td>
                  <td>{item.type}</td>
                  <td>{item.ref}</td>
                  <td>{item.quantity}</td>
                </tr>
              ) : null;
            })}
            </tbody>
          </table>
        </div>
      ))}
    </div>
  );
};

export default BOMSectionsView;
