import React, { useCallback, useMemo } from 'react';
import moment from 'moment';

import Table from '../shared/table/TableRemote';

import { getDiffDays, formatDate } from '../../utils/date';
import { getComparator, stableSort } from '../../utils/table';

import { setAllocationStatuses } from '../../constants/enums';

const { ASSIGNED, SHIPPED, RETURNED, TRANSFERRED, USAGE } = setAllocationStatuses;

const columns = [
  { title: '', field: 'caseStatus', type: 'caseStatus', sort: false },
  { title: 'case date', field: 'date', type: 'date', sort: true },
  { title: 'surgeon', field: 'surgeonName', type: 'default', sort: true },
  { title: 'hospital', field: 'hospitalName', type: 'default', sort: true },
  { title: 'state', field: 'hospitalState', type: 'default', sort: true },
  { title: 'procedure', field: 'procedureName', type: 'default', sort: true },
  { title: 'patient ref', field: 'patientReference', type: 'default', sort: true },
  { title: 'transferred', field: 'transferredSets', type: 'colored', sort: true },
  { title: '% resolved', field: 'returnedSets', type: 'progress', sort: true },
  { title: 'status', field: 'allocationStatus', type: 'colored', sort: true },
  {
    title: 'collection',
    field: 'collectionDate',
    type: 'custom',
    sort: true,
    formatter: (value) => (
      <span style={{ color: SHIPPED.color }}>{value ? formatDate(value) : ''}</span>
    )
  },
  { title: 'resolved', field: 'returnDate', type: 'default', sort: true },
  { title: '', field: 'warning', type: 'warning', sort: false },
];

const ReturnedKitsTable = (props) => {
  const {
    cases,
    onRowClick,
    order,
    setOrder,
    orderBy,
    setOrderBy,
    page,
    setPage,
    rowsPerPage,
    setRowsPerPage
  } = props;

  const getTransferredSets = useCallback((allocations) => {
    const transferred = allocations?.filter((item) => item.status === TRANSFERRED.value);
    return { value: transferred.length, color: ASSIGNED.color };
  }, [cases]);

  const getReturnedSets = useCallback((allocations) => {
    const total = allocations?.filter((item) => [ASSIGNED.value, SHIPPED.value, RETURNED.value, USAGE.value, TRANSFERRED.value].includes(item.status));
    const returned = allocations?.filter((item) => [RETURNED.value, USAGE.value, TRANSFERRED.value].includes(item.status));
    return { value: returned.length / total.length * 100, color: RETURNED.color };
  }, [cases]);

  const getStatus = useCallback((allocations, date) => {
    const today = moment();
    const caseDate = moment(date).endOf('day');

    if (!today.isAfter(caseDate)) {
      return { value: 'All sets shipped', color: SHIPPED.color };
    }

    const total = allocations.filter((item) => [ASSIGNED.value, SHIPPED.value, RETURNED.value, USAGE.value, TRANSFERRED.value].includes(item.status));
    const returned = allocations.filter((item) => [RETURNED.value, USAGE.value, TRANSFERRED.value].includes(item.status));

    if (total.length === returned.length) {
      return { value: 'All sets returned', color: RETURNED.color };
    } else {
      const returnDate = moment(date).add(7, 'days');
      const days = getDiffDays(returnDate, moment());
      return { value: days > 0 ? `${days} day(s) to return` : 'Overdue', color: ASSIGNED.color };
    }
  }, [cases]);

  // const getReturnByDate = useCallback((date) => {
  //   return moment(date).add(7, 'days');
  // }, [cases]);

  const getReturnDate = useCallback((allocations) => {
    const dates = allocations.filter((item) => !!item.returnDate).map((item) => formatDate(item.returnDate));
    const uniq = Array.from(new Set(dates));
    return uniq.join(', ');
  }, [cases]);

  const isWarning = useCallback((allocations, date) => {
    const total = allocations.filter((item) => [ASSIGNED.value, SHIPPED.value, RETURNED.value, USAGE.value].includes(item.status));
    const returned = allocations.filter((item) => item.status === RETURNED.value || item.status === USAGE.value);

    const returnDate = moment(date).add(7, 'days');
    const days = getDiffDays(returnDate, moment());

    return total.length !== returned.length && days < 5;
  }, [cases]);

  const rows = useMemo(() => {
    if (cases && cases.length) {
      const sortedRows = stableSort(cases, getComparator(order, orderBy)) || [];
      return sortedRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
    }
    return [];
  },[cases, order, orderBy, page, rowsPerPage]);

  return (
    <div style={{ width: '100%' }}>
      <Table
        rows={rows.map((item) => ({
          ...item,
          transferredSets: getTransferredSets(item.setsAllocation || []),
          returnedSets: getReturnedSets(item.setsAllocation || []),
          allocationStatus: getStatus(item.setsAllocation || [], item.date),
          // returnByDate: getReturnByDate(item.date),
          returnDate: getReturnDate(item.setsAllocation || []),
          warning: isWarning(item.setsAllocation, item.date),
          caseStatus: {
            kitVariant: item.kitVariant,
            additional: !!item.additionalKits?.length || !!item.additionalItems?.length,
          },
        }))}
        columns={columns}
        onRowClick={onRowClick}
        pagination
        checkboxes={false}
        order={order}
        setOrder={setOrder}
        orderBy={orderBy}
        setOrderBy={setOrderBy}
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        totalRows={cases?.length}
      />
    </div>
  );
};

export default ReturnedKitsTable;
