import { orderBy } from 'lodash';
import moment from 'moment';

import { GET_HOSPITALS, SET_HOSPITALS } from './actionTypes';

import firebase, { collections } from '../firebase';

import { remove } from '../utils/api';
import { nowTimestampUTC } from '../utils/date';

import urls from '../constants/urls';

export const setHospitals = hospitals => ({ type: SET_HOSPITALS, hospitals });

export const getHospitals = (tenant, withReducer) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = tenant || state?.tenant?.currentTenant?.id;
  const path = collections.HOSPITALS_COLLECTION(tenantId);

  dispatch({ type: GET_HOSPITALS });

  const snapshot = await firebase.db.collection(path).get();
  const hospitals = snapshot.docs.map(doc => {
    const data = doc.data();

    return ({
      ...data,
      id: doc.id,
      locations: data.locations ? orderBy(data.locations) : [],
    });
  });

  if (tenant && !withReducer) {
    return orderBy(hospitals, 'name', 'asc');
  }

  return dispatch(setHospitals(orderBy(hospitals, 'name', 'asc')));
};

export const createHospital = (hospitalData) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const path = collections.HOSPITALS_COLLECTION(tenantId);

  const hospitalDoc = {
    ...hospitalData,
    active: true,
    locations: [],
    deliveryAddresses: [{
      id: 'default_address',
      label: 'Hospital Address',
      street: hospitalData.street || '',
      city: hospitalData.city || '',
      state: hospitalData.state || '',
      country: hospitalData.country || '',
      default: true,
      formattedAddress: '',
    }],
  };

  if (tenantId) {
    await firebase.db.collection(path).add(hospitalDoc);
  } else {
    throw new Error('Invalid tenant');
  }
};

export const updateHospital = (hospitalId, hospitalData) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const path = collections.HOSPITALS_COLLECTION(tenantId);

  if (tenantId) {
    await firebase.db.collection(path).doc(hospitalId).set(hospitalData, { merge: true });
  } else {
    throw new Error('Invalid tenant');
  }
};

export const deleteHospital = (hospitalId) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const idToken = await firebase.auth.currentUser.getIdToken();

  if (tenantId) {
    await remove(urls.hospitals.delete(hospitalId, tenantId), idToken);
  } else {
    throw new Error('Invalid tenant');
  }
};

export const bulkDeleteHospitals = (hospitals) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const idToken = await firebase.auth.currentUser.getIdToken();

  if (tenantId) {
    const promises = [];
    hospitals.forEach((hospitalId) => {
      promises.push(remove(urls.hospitals.delete(hospitalId, tenantId), idToken));
    });

    await Promise.all(promises);
  } else {
    throw new Error('Invalid tenant');
  }
};

export const getHospitalContacts = (hospitalId) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const path = collections.HOSPITAL_CONTACTS_COLLECTION(tenantId, hospitalId);

  const snapshot = await firebase.db.collection(path).get();
  const contacts = snapshot.docs.map(doc => {
    const data = doc.data();
    return {
      ...data,
      id: doc.id,
      createdAt: data.createdAt ? data.createdAt.toDate() : '',
    };
  });

  return contacts;
};

export const createHospitalContact = (hospitalId, contactData) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const path = collections.HOSPITAL_CONTACTS_COLLECTION(tenantId, hospitalId);

  if (tenantId) {
    await firebase.db.collection(path).add({
      ...contactData,
      active: true,
    });
  } else {
    throw new Error('Invalid tenant');
  }
};

export const getHospitalNotes = (hospitalId) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const path = collections.HOSPITAL_NOTES_COLLECTION(tenantId, hospitalId);

  const snapshot = await firebase.db.collection(path).get();
  const notes = snapshot.docs.map(doc => {
    const data = doc.data();
    return {
      ...data,
      id: doc.id,
      createdAt: data.createdAt ? data.createdAt.toDate() : '',
    };
  });

  return orderBy(notes, 'createdAt', 'desc');
};

export const getHospitalTasks = (hospitalId) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const path = collections.TASKS_COLLECTION(tenantId, hospitalId);

  const snapshot = await firebase.db.collection(path).where('hospitalId', '==', hospitalId).get();
  const notes = snapshot.docs?.map(doc => {
    const data = doc.data();
    return {
      ...data,
      id: doc.id,
      createdAt: data.createdAt ? data.createdAt.toDate() : '',
      dueDate: data.dueDate ? data.dueDate.toDate() : '',
    };
  });

  const list = notes?.filter((note) => !note.dueDate || moment(note.dueDate).isAfter(moment()));
  return orderBy(list, 'createdAt', 'desc');
};

export const createHospitalNote = (hospitalId, noteData) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const currentUser = state.user.currentUser;
  const path = collections.HOSPITAL_NOTES_COLLECTION(tenantId, hospitalId);

  if (tenantId) {
    await firebase.db.collection(path).add({
      ...noteData,
      active: true,
      createdAt: nowTimestampUTC(),
      authorFullName: `${currentUser.firstName} ${currentUser.lastName}`,
    });
  } else {
    throw new Error('Invalid tenant');
  }
};

export const tagTerritories = (hospitalIds, territoryIds) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const hospitals = state?.hospitals?.list || [];
  const path = collections.HOSPITALS_COLLECTION(tenantId);

  if (tenantId) {
    for (const hospitalId of hospitalIds) {
      const hospital = hospitals?.find((h) => h.id === hospitalId);
      const hospitalTerritories = hospital?.territories || [];
      const territories = territoryIds?.filter((id) => !hospital?.territories?.includes(id)) || [];
      if (hospital && territories?.length) {
        await firebase.db.collection(path).doc(hospitalId).set({ territories: [...hospitalTerritories, ...territories] }, { merge: true });
      }
    }
  } else {
    throw new Error('Invalid tenant');
  }
};

export const uploadHospitals = (data) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const path = collections.HOSPITALS_COLLECTION(tenantId);

  const hospitals = [...data];
  hospitals.shift(); // Remove Excel headers

  if (tenantId) {
    const promises = [];

    hospitals.forEach((item) => {
      const hospital = item?.map((value) => value ? `${value}` : '')
      const name = hospital[0] || '';
      const country = hospital[1] || '';
      const street = hospital[2] || '';
      const city = hospital[3] || '';
      const state = hospital[4] || '';
      const postCode = hospital[5] || '';
      const accountNumber = hospital[6] || '';
      const businessNumber = hospital[7] || '';

      const billingAddress = {
        street: hospital[8] || '',
        city: hospital[9] || '',
        state: hospital[10] || '',
        postCode: hospital[11] || '',
        apartment: hospital[12] || '',
        buildingName: hospital[13] || '',
        neighborhood: hospital[14] || '',
        department: hospital[15] || '',
        attn: hospital[16] || '',
        phone: hospital[17] || '',
        country: country || '',
      };
      const hasBillingAddress = billingAddress.street && billingAddress.city && billingAddress.state;

      let deliveryAddresses = [];

      if (hospital[18] && hospital[19] && hospital[20]) {
        deliveryAddresses = parseDeliveryAddresses(hospital, country || '');
      } else {
        deliveryAddresses = [{
          id: 'default_address',
          label: 'Hospital Address',
          street: street || '',
          city: city || '',
          state: state || '',
          country: country || '',
          default: true,
          formattedAddress: '',
        }];
      }

      const doc = {
        name,
        country,
        street,
        city,
        state,
        postCode,
        accountNumber,
        businessNumber,
        active: true,
        locations: [],
        customBillingAddress: !!hasBillingAddress,
        billingAddress: hasBillingAddress ? billingAddress : null,
        deliveryAddresses,
      };

      promises.push(firebase.db.collection(path).add(doc));
    });

    await Promise.all(promises);
  } else {
    throw new Error('Invalid tenant');
  }
};

export const getConsignmentSets = (hospitalId) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const path = collections.SETS_COLLECTION(tenantId);

  if (tenantId) {
    const snapshot = await firebase.db.collection(path).where('consignment.hospital', '==', hospitalId).get();
    return snapshot.docs.map((doc) => {
      const data = doc.data();
      const obj =  {
        id: doc.id,
        ...data,
      };

      return obj;
    });
  } else {
    throw new Error('Invalid tenant');
  }
};

// Subscriptions
export const subscribeToHospitals = () => (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const path = collections.HOSPITALS_COLLECTION(tenantId);

  return firebase
    .db
    .collection(path)
    .onSnapshot({
      error: (e) => console.error(e),
      next: (querySnapshot) => {
        let hospitals = [];
        querySnapshot.forEach((documentSnapshot) => {
          const id = documentSnapshot.id;
          const data = documentSnapshot.data();

          hospitals = [...hospitals, {
            ...data,
            id,
            locations: data.locations ? orderBy(data.locations) : [],
          }];
        });

        return dispatch(setHospitals(orderBy(hospitals, 'name', 'asc')));
      },
    });
};

const parseDeliveryAddresses = (hospital, country) => {
  let deliveryAddresses = [];
  deliveryAddresses?.push({
    id: 'default_address',
    label: hospital[18] || '',
    street: hospital[19] || '',
    city: hospital[20] || '',
    state: hospital[21] || '',
    postCode: hospital[22] || '',
    apartment: hospital[23] || '',
    buildingName: hospital[24] || '',
    neighborhood: hospital[25] || '',
    department: hospital[26] || '',
    attn: hospital[27] || '',
    phone: hospital[28] || '',
    default: true,
    formattedAddress: '',
    country: country || '',
  });

  if (hospital[29] && hospital[30] && hospital[31]) {
    deliveryAddresses?.push({
      id: 'delivery_address_2',
      label: hospital[29] || '',
      street: hospital[30] || '',
      city: hospital[31] || '',
      state: hospital[32] || '',
      postCode: hospital[33] || '',
      apartment: hospital[34] || '',
      buildingName: hospital[35] || '',
      neighborhood: hospital[36] || '',
      department: hospital[37] || '',
      attn: hospital[38] || '',
      phone: hospital[39] || '',
      default: false,
      formattedAddress: '',
      country: country || '',
    });
  }

  if (hospital[40] && hospital[41] && hospital[42]) {
    deliveryAddresses?.push({
      id: 'delivery_address_3',
      label: hospital[40] || '',
      street: hospital[41] || '',
      city: hospital[42] || '',
      state: hospital[43] || '',
      postCode: hospital[44] || '',
      apartment: hospital[45] || '',
      buildingName: hospital[46] || '',
      neighborhood: hospital[47] || '',
      department: hospital[48] || '',
      attn: hospital[49] || '',
      phone: hospital[50] || '',
      default: false,
      formattedAddress: '',
      country: country || '',
    });
  }

  if (hospital[51] && hospital[52] && hospital[53]) {
    deliveryAddresses?.push({
      id: 'delivery_address_4',
      label: hospital[51] || '',
      street: hospital[52] || '',
      city: hospital[53] || '',
      state: hospital[54] || '',
      postCode: hospital[55] || '',
      apartment: hospital[56] || '',
      buildingName: hospital[57] || '',
      neighborhood: hospital[58] || '',
      department: hospital[59] || '',
      attn: hospital[60] || '',
      phone: hospital[61] || '',
      default: false,
      formattedAddress: '',
      country: country || '',
    });
  }

  return deliveryAddresses;
};
