import React, { useMemo } from 'react';
import { Field, Form } from 'react-final-form';

import KitsList from './KitsList';
import ItemsList from './ItemsList';
import Button from '../../shared/Button';

import ItemsIcon from '../../../assets/icons/ItemIcon';
import KitIcon from '../../../assets/icons/KitIcon';

import { kitTypeOptions } from '../../../constants/enums';

const Step3Additional = (props) => {
  const {
    initialValues,
    onClickNext,
    onClickBack,
    onClose,
    kits = [],
    items = [],
    status,
  } = props;
  const { products } = initialValues;

  const validate = (values) => {
    const errors = {};
    return errors;
  };

  const kitsList = useMemo(() => {
    if (!kits) {
      return [];
    }
    if (!products) {
      return kits;
    }
    return kits.filter((kit) => kit.products.some((product) => products.includes(product)));
  }, [kits, products]);

  const itemsList = useMemo(() => {
    if (!items) {
      return [];
    }
    return items
  }, [items]);

  return (
    <div>
      <Form
        onSubmit={onClickNext}
        validate={validate}
        initialValues={{ ...initialValues }}
        subscription={{ pristine: true, invalid: true, values: true }}
      >
        {
          ({ handleSubmit, submitting, invalid, pristine, form, values }) => (
            <form onSubmit={handleSubmit} className='form__body'>

              <div className='font-bold'>
                Add Additional Loan Equipment?
              </div>
              <div className='m-t-md m-b-lg'>
                Select any additional loan equipment or items that need to be shipped for this loan booking from the list below.
              </div>

              <div className='m-b-lg'>
                <div className='m-b-lg form__select-users'>
                  <Field name='additionalKits'>
                    {({ input, meta }) => (
                      !!kitsList.length && (
                        <div className='p-b-sm'>
                          <div className='d-flex font-bold font-size-bg m-b-md'>
                            <KitIcon />
                            <span className='m-l-sm'>
                              Select Loan Equipment
                            </span>
                          </div>
                          {Object.values(kitTypeOptions).map((kitType) => {
                              if (!!kitsList.filter((kit) => kit.type === kitType.value).length) {
                                return (
                                  <KitsList
                                    key={kitType.value}
                                    kitType={kitType}
                                    kitsList={kitsList}
                                    status={status}
                                    input={input}
                                  />
                                );
                              } else {
                                return null;
                              }
                            })}
                        </div>
                      )
                    )}
                  </Field>

                  {!!itemsList.length && (
                    <Field name='additionalItems'>
                      {({ input, meta }) => (
                        <div className='m-t-sm p-t-sm' style={kitsList?.length ? { borderTop: '1px solid #eeeeee' } : {}}>
                          <div className='d-flex font-bold font-size-bg p-t-md p-b-sm'>
                            <ItemsIcon />
                            <span className='m-l-sm'>Additional Items</span>
                          </div>
                          <ItemsList
                            itemsList={itemsList}
                            input={input}
                            status={status}
                          />
                        </div>
                      )}
                    </Field>
                  )}
                </div>
              </div>

              <div className='form__actions'>
                <div className='d-flex'>
                  <div className='m-r-md'>
                    <Button
                      type='cancel'
                      text='Back'
                      onClick={onClickBack}
                    />
                  </div>
                  <Button
                    type='submit'
                    text={values?.additionalKits?.length || values?.additionalItems?.length ? 'Next' : 'Skip'}
                    disabled={invalid}
                    onClick={handleSubmit}
                  />
                </div>
                <Button
                  type='cancel'
                  onClick={onClose}
                />
              </div>
            </form>
          )}
      </Form>
    </div>
  );
};

export default Step3Additional;
