import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

import defaultColor from './defaultColor';

export default function SplitViewIcon(props) {
  const color = props.color === 'secondary' ? '#b1b1b1' : props.color;
  return <SvgIcon {...props} viewBox="0 0 14 14" fill="none">
    <path id="Vector 1105" stroke={color || defaultColor} fill="none" strokeLinecap="round" strokeLinejoin="round" d="m4.5 3.99988 -1.5 0"
          strokeWidth="1"></path>
    <path id="Vector 1106" stroke={color || defaultColor} fill="none" strokeLinecap="round" strokeLinejoin="round" d="m4.5 6.49988 -1.5 0"
          strokeWidth="1"></path>
    <path id="Intersect" stroke={color || defaultColor} fill="none" strokeLinecap="round" strokeLinejoin="round"
          d="M0.959867 10.2685C1.114 11.7092 2.2727 12.8679 3.71266 13.0284 4.78221 13.1476 5.88037 13.25 7 13.25s2.21779 -0.1024 3.2873 -0.2216c1.44 -0.1605 2.5987 -1.3192 2.7528 -2.7599 0.1138 -1.06348 0.2099 -2.15535 0.2099 -3.2685 0 -1.11316 -0.0961 -2.20502 -0.2099 -3.26853 -0.1541 -1.44065 -1.3128 -2.59936 -2.7528 -2.759861C9.21779 0.852392 8.11963 0.75 7 0.75S4.78221 0.852392 3.71266 0.971609C2.2727 1.13211 1.114 2.29082 0.959867 3.73147 0.846083 4.79498 0.75 5.88684 0.75 7c0 1.11315 0.096084 2.20502 0.209867 3.2685Z"
          strokeWidth="1"></path>
    <path id="Intersect_2" stroke={color || defaultColor} fill="none" strokeLinecap="round" strokeLinejoin="round" d="m6.5 0.756592 0 12.486908"
          strokeWidth="1"></path>
  </SvgIcon>
}
