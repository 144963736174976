import React from 'react';

import { makeStyles, withStyles } from '@material-ui/core/styles';
import MuiButton from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Tooltip from '@material-ui/core/Tooltip';

import { getBackgroundColor, getBorderColor } from '../../utils/colors';

const StyledButton = withStyles((theme) => ({
  root: {
    height: '40px !important',
    width: '40px !important',
    minWidth: '30px !important',
    borderRadius: '8px',
    textTransform: 'capitalize',
    fontSize: '10px',
    border: props => `1px solid ${getBorderColor(props.color)}`,
    background: props => getBackgroundColor(props.color),
    cursor: 'pointer',
    '&:hover': {
      background: props => getBackgroundColor(props.color)
    }
  }
}))(MuiButton);

const useStyles = makeStyles(() => ({
  tooltip: {
    fontSize: '12px',
    padding: '4px 8px',
    borderRadius: 8,
    cursor: 'pointer'
  }
}));

const ActionIconButton = ({
  onClick,
  disabled,
  color, // ['green', 'orange', 'blue']
  loading,
  children,
  tooltip,
}) => {
  const classes = useStyles();

  return (
    <Tooltip
      title={tooltip}
      aria-label={`action_button_tooltip`}
      classes={{ tooltip: classes.tooltip }}
    >
      <StyledButton
        className={`action-icon-button${disabled ? '--disabled' : ''}`}
        onClick={onClick}
        disabled={disabled}
        color={disabled? 'grey' : color}
      >
        <div className='d-flex direction-column space-between'>
          { loading ? <CircularProgress size={24} /> : children }
        </div>
      </StyledButton>
    </Tooltip>
  )
};

export default ActionIconButton;
