import React, { useState, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { debounce } from 'lodash';

import ItemsTable from '../../../components/items/ItemsTable';
import ItemsFilters from '../../../components/items/ItemsFilters';
import ItemForm from '../../../components/items/ItemForm';
import ConfirmUploadModal from '../../../components/items/ConfirmUploadModal';
import UploadItemsModal from '../../../components/items/UploadItemsModal';
import BulkUpdateModal from '../../../components/items/BulkUpdateModal';
import Input from '../../../components/shared/Input';
import Modal, { ConfirmationModal } from '../../../components/shared/modal';
import Alert from '../../../components/shared/Alert';

import {
  getItems,
  createItem,
  updateItem,
  bulkDeleteItems,
  uploadItems,
  bulkUpdateItems,
  getPaginatedItems,
  getAllItems
} from '../../../actions/itemsActions';
import { getProducts } from '../../../actions/productsActions';

import { filterItems } from '../../../utils/table';

import { itemTypeOptions, statusOptions } from '../../../constants/enums';

const statusFilterOptions = [
  { label: 'Active', value: statusOptions.active },
  { label: 'Deactivated', value: statusOptions.deactivated }
];

const ItemsPage = (props) => {
  const {
    tenantColor,
    createItem,
    updateItem,
    bulkDeleteItems,
    uploadItems,
    products = [],
    getProducts,
    deletePermission,
    bulkUpdateItems,
    getPaginatedItems,
    getAllItems
  } = props;

  const [items, setItems] = useState([]);

  const [isModalCreateOpen, toggleModalCreate] = useState(false);
  const [isModalDeleteOpen, toggleModalDelete] = useState(false);
  const [isUploadModalOpen, toggleUploadModal] = useState(false);
  const [isBulkUpdateModalOpen, toggleBulkUpdateModal] = useState(false);
  const [isModalDuplicateOpen, toggleModalDuplicate] = useState(false);

  const [fetching, setFetching] = useState({});
  const [loading, setLoading] = useState(false);
  const [uploading, setUploading] = useState(false);

  const [successMessage, setSuccessMessage] = useState(null);

  const [selectedItem, setSelectedItem] = useState(null);
  const [checkedItems, setCheckedItems] = useState([]);

  const [search, setSearch] = useState('');

  const [confirmModalOpen, toggleConfirmModal] = useState(false);
  const [confirmModalItems, setConfirmModalItems] = useState([]);
  const [failedModalItems, setFailedModalItems] = useState([]);

  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [numberOfDocs, setNumberOfDocs] = useState(0);
  const [, setTotalPages] = useState(1);

  const [orderDirection, setOrderDirection] = useState('asc');
  const [orderBy, setOrderBy] = useState('code');
  const [filters, setFilters] = useState({});

  useEffect(() => {
    onLoad().catch((err) => console.error(err));
  }, []);

  const debouncedOnChange = useMemo(() => {
    return debounce((value) => setFilters({ ...filters, code: value }), 500);
  }, [setFilters]);

  useEffect(() => {
    fetchItems().catch();
  }, [currentPage, rowsPerPage, orderDirection, orderBy, filters]);

  const fetchItems = async () => {
    try {
      setFetching(true);

      const res = await getPaginatedItems({
        page: currentPage,
        docsPerPage: rowsPerPage,
        orderBy: orderBy,
        orderDirection,
        filters,
      });

      setNumberOfDocs(res?.count);
      setTotalPages(Math.ceil(res?.count / rowsPerPage));
      setItems(res?.items?.length ? res?.items : []);
    } catch (err) {
      console.error(err);
    } finally {
      setFetching(false);
    }
  };

  const onLoad = async () => {
    await getProducts();
  };

  const onSelectItem = (item) => {
    setSelectedItem(item);
  };

  // Create item
  const onCreateItem = async (formObj, image) => {
    setLoading(true);
    const itemObj = {
      code: formObj.code,
      description: formObj.description,
      type: formObj.type,
      imageUrl: formObj.imageUrl || '',
      products: formObj.products || [],
      rebateCode: formObj.rebateCode || '',
      value: formObj.value || '',
      gtin: formObj.gtin || '',
      checklistDescription: formObj.checklistDescription || '',
      batchControl: formObj.batchControl || false,
    };
    try {
      await createItem(itemObj, image);
      await fetchItems();
      setLoading(false);
      toggleModalCreate(false);
      setSuccessMessage('Item has been successfully created');
    } catch (err) {
      setLoading(false);
      console.error(err);
    }
  };

  // Update item
  const onUpdateItem = async (formObj, image) => {
    setLoading(true);
    const itemObj = {
      active: formObj.active || false,
      code: formObj.code || '',
      description: formObj.description || '',
      type: formObj.type || '',
      imageUrl: formObj.imageUrl || '',
      products: formObj.products || [],
      rebateCode: formObj.rebateCode || '',
      value: formObj.value || '',
      gtin: formObj.gtin || '',
      image: formObj.image || null,
      checklistDescription: formObj.checklistDescription || '',
      batchControl: formObj.batchControl || false,
    };

    try {
      await updateItem(formObj.id, itemObj, image);
      await fetchItems();
      setLoading(false);
      setSuccessMessage('Item has been successfully updated');
      setSelectedItem(formObj);
    } catch (err) {
      setLoading(false);
      console.error(err);
    }
  };

  // Delete checked items
  const onDeleteItems = async () => {
    await bulkDeleteItems(checkedItems);
    await fetchItems();
    toggleModalDelete(false);
    setCheckedItems([]);
    setSelectedItem(null);
  };

  const handleDeleteClick = () => {
    if (checkedItems.length) {
      toggleModalDelete(true);
    }
  };

  // Upload items
  const onUpload = async (newItems) => {
    setUploading(true);

    try {
      const successful = [];
      const failed = [];

      newItems.forEach((item) => {
        if (
          items?.find((i) => item && `${item[0]}` === `${i.code}`)
          || !item[2]
          || !Object.values(itemTypeOptions)?.map((t) => t?.label?.toLowerCase())?.includes(item[2]?.toLowerCase())
        ) {
          failed?.push(item);
        } else {
          successful?.push(item);
        }
      });

      if (!!failed?.length) {
        setUploading(false);
        toggleUploadModal(false);
        toggleConfirmModal(true);
        setConfirmModalItems(successful);
        setFailedModalItems(failed);
        return;
      }

      await uploadItems(successful);
      await fetchItems();

      setUploading(false);
      toggleUploadModal(false);
      setSuccessMessage(`${successful?.length} item${successful?.length !== 1 ? 's' : ''} have been successfully uploaded`);
    } catch (err) {
      setUploading(false);
      console.error(err);
    }
  };

  const finishUpload = async () => {
    const length = confirmModalItems?.length;

    if (length > 0) {
      setUploading(true);

      try {
        await uploadItems(confirmModalItems);
        await fetchItems();
        setConfirmModalItems([]);
        setFailedModalItems([]);
        setUploading(false);
        toggleConfirmModal(false);
        setSuccessMessage(`${length} item${length !== 1 ? 's' : ''} have been successfully uploaded`);
      } catch (err) {
        setUploading(false);
        console.error(err);
      }
    } else {
      toggleConfirmModal(false);
      setConfirmModalItems([]);
      setFailedModalItems([]);
    }
  };

  const onBulkUpdateUpload = async (updatedItems) => {
    try {
      setUploading(true);
      await bulkUpdateItems(updatedItems);
      await fetchItems();
      toggleBulkUpdateModal(false);
      setSuccessMessage(`${updatedItems?.length} item${updatedItems?.length !== 1 ? 's' : ''} successfully updated`);
    } catch (err) {
      console.error(err);
    } finally {
      setUploading(false);
    }
  };

  // Duplicate item
  const onDuplicateClick = async () => {
    if (!checkedItems || checkedItems.length !== 1) {
      return;
    }

    const itemId = checkedItems[0];
    const item = items.find((k) => k.id === itemId);
    setSelectedItem(item);
    toggleModalDuplicate(true);
  };

  const onDuplicateItem = async (formObj) => {
    setLoading(true);
    const itemObj = {
      code: formObj.code || '',
      description: formObj.description || '',
      type: formObj.type || '',
      imageUrl: formObj.imageUrl || '',
      rebateCode: formObj.rebateCode || '',
      value: formObj.value || '',
      image: formObj.image || null,
      batchControl: formObj.batchControl || false
    };

    try {
      await createItem(itemObj);
      await fetchItems();
      setLoading(false);
      toggleModalDuplicate(false);
      setSuccessMessage('Item has been successfully duplicated');
      setCheckedItems([]);
    } catch (err) {
      setLoading(false);
      console.error(err);
    }
  };

  const handleSearch = (e) => {
    setSearch(e.target.value);
    debouncedOnChange(e.target.value);
  };

  const itemsList = useMemo(() => {
    return items.filter((item) => filterItems(item, '', filters.status));
  }, [items, filters]);

  const handleCheck = (itemId) => {
    const checked = checkedItems.slice();

    if (checked.includes(itemId)) {
      const index = checked.indexOf(itemId);

      checked.splice(index, 1);
      setCheckedItems(checked);

      return;
    }

    checked.push(itemId);
    setCheckedItems(checked);
  };

  const handleCheckAll = () => {
    const checked = checkedItems.length === itemsList.length;
    const temp = [];

    if (!checked) {
      itemsList.forEach((item) => {
        temp.push(item.id);
      });

      setCheckedItems(temp);
      return;
    }

    setCheckedItems(temp);
  };

  const initialValues = useMemo(() => {
    if (selectedItem) {
      return {
        ...selectedItem,
        imageName: selectedItem.image ? selectedItem?.image?.fileName : '',
      };
    } else {
      return {};
    }
  }, [selectedItem]);

  return (
    <div className='settings-cmp__main'>
      {/*{!isLoaded && <LoadScreen />}*/}
      <span className='settings-title'>Items</span>
      <div className='settings-cmp__body'>
        <div className='filters-container'>
          <ItemsFilters
            items={items}
            onAddItemClick={() => {
              setSelectedItem(null);
              toggleModalCreate(true);
            }}
            onDelete={handleDeleteClick}
            filters={filters}
            setFilters={setFilters}
            statuses={statusFilterOptions}
            onUploadClick={() => toggleUploadModal(true)}
            onDuplicateClick={onDuplicateClick}
            onBulkUpdateClick={() => toggleBulkUpdateModal(true)}
            deletePermission={deletePermission}
            getAllItems={getAllItems}
          />
          <ItemsTable
            onSelectItem={onSelectItem}
            items={items}
            selectedItemId={selectedItem ? selectedItem.id : null}
            handleCheck={handleCheck}
            checkedItems={checkedItems}
            handleCheckAll={handleCheckAll}
            products={products}
            order={orderDirection}
            setOrder={setOrderDirection}
            orderBy={orderBy}
            setOrderBy={setOrderBy}
            page={currentPage}
            setPage={setCurrentPage}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            totalRows={numberOfDocs}
            loading={fetching}
          />
        </div>
        <div className='form-container'>
          <Input
            type='search'
            placeholder='Search Items'
            value={search}
            onChange={handleSearch}
          />
          {selectedItem && (
            <ItemForm
              initialValues={{ ...initialValues, products: initialValues.products || [] }}
              buttonText='Save Changes'
              onSubmit={onUpdateItem}
              loading={loading}
              mode='update'
              products={products}
              items={items}
            />
          )}
        </div>
      </div>

      <ConfirmationModal
        open={isModalDeleteOpen}
        onClose={() => toggleModalDelete(false)}
        onSubmit={onDeleteItems}
        title='Are you sure you want to delete these items?'
        submitText='Delete'
      />

      <Modal
        open={isModalCreateOpen}
        onClose={() => toggleModalCreate(false)}
      >
        <ItemForm
          buttonText='Add Item'
          onSubmit={onCreateItem}
          loading={loading}
          mode='create'
          onClose={() => toggleModalCreate(false)}
          items={items}
        />
      </Modal>

      <Modal
        open={isModalDuplicateOpen}
        onClose={() => toggleModalDuplicate(false)}
      >
        <ItemForm
          initialValues={selectedItem}
          buttonText='Duplicate'
          onSubmit={onDuplicateItem}
          loading={loading}
          mode='create'
          onClose={() => toggleModalDuplicate(false)}
          items={items}
        />
      </Modal>

      <UploadItemsModal
        open={isUploadModalOpen}
        onClose={() => toggleUploadModal(false)}
        tenantColor={tenantColor}
        onSubmit={onUpload}
        loading={uploading}
      />

      <BulkUpdateModal
        open={isBulkUpdateModalOpen}
        onClose={() => toggleBulkUpdateModal(false)}
        tenantColor={tenantColor}
        onSubmit={onBulkUpdateUpload}
        loading={uploading}
      />

      <ConfirmUploadModal
        open={!!confirmModalOpen}
        onClose={() => {
          toggleConfirmModal(false);
          setConfirmModalItems([]);
          setFailedModalItems([]);
        }}
        onSubmit={finishUpload}
        loading={uploading}
        failedItems={failedModalItems}
        items={items}
      />

      <Alert
        variant='success'
        message={successMessage}
        open={!!successMessage}
        onClose={() => setSuccessMessage(null)}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    items: state.items.list,
    tenantColor: state.tenant.currentTenant.colorPrimary,
    products: state.products.list,
    deletePermission: state.user.currentUser?.deletePermission,
    isLoaded: state?.items?.isLoaded,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getItems: () => dispatch(getItems()),
    createItem: (item, image) => dispatch(createItem(item, image)),
    updateItem: (id, itemData, image) => dispatch(updateItem(id, itemData, image)),
    bulkDeleteItems: (itemIds) => dispatch(bulkDeleteItems(itemIds)),
    uploadItems: (items) => dispatch(uploadItems(items)),
    bulkUpdateItems: (items) => dispatch(bulkUpdateItems(items)),
    getProducts: () => dispatch(getProducts()),
    getPaginatedItems: (params) => dispatch(getPaginatedItems(params)),
    getAllItems: () => dispatch(getAllItems()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ItemsPage);
