import React, { useState, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { pdf } from '@react-pdf/renderer';

import FullScreenIcon from '@material-ui/icons/Fullscreen';

import ExportIcon from '../../../assets/icons/DownloadIcon';

import {
  BillOfMaterialActions,
  BillOfMaterialBreadcrumbs,
  SectionsList,
  SectionItemsList,
} from '../../../components/bill-of-material';
import Input from '../../../components/shared/Input';
import LoadScreen from '../../../components/load-screen';
import ChecklistsPDF from '../../../components/cases/checklists/ChecklistsPDF';
import { ParentKitBreadcrumbs, ParentKitItemsTable } from '../../../components/kits/parent-kit';
import Button from '../../../components/shared/Button';

import { getKit, getKits, subscribeToSections, getSections } from '../../../actions/kitsActions';
import { getItemsByIds } from '../../../actions/itemsActions';

import { useLoading } from '../../../hooks';

import { downloadFile } from '../../../utils/utils';

import routes from '../../../constants/routes';
import { billOfMaterialItemTypes, kitItemTypes, setAllocationStatuses } from '../../../constants/enums';

const BOMViewPage = () => {
  const { id } = useParams();
  const kitId = id.replace(':', '');
  const history = useHistory();

  const { search: params } = useLocation();
  const redirect = params ? params?.split('?redirect=')[1] : null;

  const dispatch = useDispatch();
  const tenant = useSelector((state) => state?.tenant.currentTenant);
  const kits = useSelector((state) => state?.kits.list);

  const { loading, startLoading, stopLoading } = useLoading(false);
  const { fetching, startLoading: startFetching, stopLoading: stopFetching } = useLoading(false);

  const [itemsList, setItemsList] = useState([]);
  const [itemsFetching, setItemsFetching] = useState(false);

  const [kit, setKit] = useState(null);

  const [search, setSearch] = useState('');

  const [sections, setSections] = useState([]);
  const [selectedSection, selectSection] = useState(null);

  const [items, setItems] = useState([]);
  const [selectedItem, selectItem] = useState(null);

  useEffect(() => {
    const unsubscribe = dispatch(subscribeToSections(kitId, setSections));

    onLoad().catch((err) => console.error(err));

    return () => {
      unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (selectedSection) {
      setItems(selectedSection?.items || []);
    } else {
      setItems([]);
    }
  }, [selectedSection]);

  const onLoad = async () => {
    startFetching();

    try {
      await dispatch(getKits());
      const res = await dispatch(getKit(kitId));

      if (res && res?.id) {
        setKit(res);
        const _items = await dispatch(getItemsByIds(res?.children?.filter((k) => k.type === kitItemTypes.ITEM.value).map((k) => k.itemId)));
        setItems(_items || []);
      }
    } catch (err) {
      console.error(err);
    } finally {
      stopFetching();
    }
  };

  const onSelectSection = async (section) => {
    try {
      setItemsFetching(true);
      selectItem(null);
      selectSection(section);

      const res = await dispatch(getItemsByIds(section.items?.filter((i) => i.type !== billOfMaterialItemTypes.NOTES.value)?.map((i) => i.id)));
      setItemsList(res || []);
      // setItems(section?.items || []);
    } catch (err) {
      console.error(err);
    } finally {
      setItemsFetching(false);
    }
  };

  const onBackClick = () => {
    if (redirect === 'drive') {
      history.push(routes.DRIVE);
    } else {
      history.push(routes.SETS);
    }
  };

  const onExportClick = async () => {
    try {
      startLoading();

      if (!!kit.isParent) {
        await downloadParentKitPDF();
      } else {
        await downloadChecklistsDoc();
      }
    } catch (err) {
      console.error(err);
    } finally {
      stopLoading();
    }
  };

  const downloadParentKitPDF = async () => {
    let checklists = [];

    for (const kitChild of kit?.children?.filter((item) => item.type === kitItemTypes.KIT.value)) {
      const bomSections = await dispatch(getSections(kitChild.kitId));
      let kitItems = [];
      const _kit = kits?.find((k) => k.id === kitChild.kitId);
      for (const section of bomSections) {
        const res = await dispatch(getItemsByIds(section.items?.filter((i) => i.type !== billOfMaterialItemTypes.NOTES.value)?.map((i) => i.id)));
        kitItems = [
          ...kitItems,
          ...res
        ];
      }
      checklists = [
        ...checklists,
        {
          kitId: _kit?.kitId || '',
          kitName: _kit?.name || '',
          sections: bomSections?.map((section) => ({
            ...section,
            items: section?.items?.map((item) => {
              if (item.type === billOfMaterialItemTypes.NOTES.value) {
                return item;
              } else {
                const _item = kitItems?.find((i) => i.id === item.id);
                return { ...item, code: _item.code || '', description: _item.checklistDescription || _item.description || '' };
              }
            })
          })),
        }
      ]
    }

    const additionalItems =  kit?.children?.filter((item) => item.type === kitItemTypes.ITEM.value).map((kitItem) => {
      const _item = items?.find((i) => i.id === kitItem.itemId);
      return { ..._item, name: _item.code, status: setAllocationStatuses.CONFIRMED.value } || {};
    });

    const blob = await pdf(
      <ChecklistsPDF
        tenant={tenant}
        checklists={checklists}
        kits={kits}
        sets={[]}
        withItems
        items={additionalItems}
      />
    ).toBlob();
    downloadFile(blob, `${kit.kitId}.pdf`);
  };

  const downloadChecklistsDoc = async () => {
    let _items = [];
    for (const section of sections) {
      const res = await dispatch(getItemsByIds(section.items?.filter((i) => i.type !== billOfMaterialItemTypes.NOTES.value)?.map((i) => i.id)));
      _items = [
        ..._items,
        ...res
      ];
    }

    const checklists = [{
      kitId: kit?.kitId || '',
      kitName: kit?.name || '',
      sections: sections?.map((section) => ({
        ...section,
        items: section?.items?.map((item) => {
          if (item.type === billOfMaterialItemTypes.NOTES.value) {
            return item;
          } else {
            const _item = _items?.find((i) => i.id === item.id);
            return { ...item, code: _item.code || '', description: _item.checklistDescription || _item.description || '' };
          }
        })
      })),
    }];

    const blob = await pdf(
      <ChecklistsPDF
        tenant={tenant}
        checklists={checklists}
        kits={kits}
        sets={[]}
      />
    ).toBlob();
    downloadFile(blob, `${kit.kitId} – BOM.pdf`);
  };

  const list = useMemo(() => {
    if (!kit?.children) {
      return [];
    }
    let result = kit?.children?.map((item) => {
      const arr = item?.type === kitItemTypes.KIT.value ? kits : items;
      const kitItem = arr?.find((i) => i.id === item.id);

      return {
        ...item,
        code: kitItem?.code || kitItem?.kitId || '',
        description: kitItem?.description || kitItem?.name || '',
        itemType: kitItem?.type || '',
      };
    });

    if (!!search) {
      result = result?.filter((i) => i?.code?.toLowerCase()?.includes(search?.toLowerCase()) || i?.description?.toLowerCase()?.includes(search?.toLowerCase()));
    }

    return result;
  }, [kit?.children, kits, items, search]);

  if (!kit) {
    return null;
  }

  if (!!kit.isParent) {
    return (
      <div className="settings-cmp__main flex-1 bom-page__container">
        {fetching && <LoadScreen />}

        <span className="settings-title">Parent Kit</span>
        <div className="bom-page__body">
          <div className='settings-cmp__actions'>
            <Button
              type='outlined'
              text={redirect === 'drive' ? 'Back to Drive' : 'Back to Sets'}
              onClick={onBackClick}
            />
            <Button
              onClick={onExportClick}
              disabled={loading}
              loading={loading}
              width={60}
            >
              {!loading && <ExportIcon color="#ffffff" />}
            </Button>
          </div>
          <div className="flex-1 m-l-lg m-r-lg">
            <ParentKitBreadcrumbs
              kitId={kit?.kitId}
              kitName={kit?.name}
            />
          </div>

          <Input
            type="search"
            placeholder="Search Bundle"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>

        <div className="m-t-lg">
          <ParentKitItemsTable
            items={list}
            readOnly
          />
        </div>
      </div>
    );
  }

  return (
    <div className='settings-cmp__main flex-1 bom-page__container'>
      {(fetching || itemsFetching) && <LoadScreen />}

      <span className='settings-title'>BOM</span>
      <div className='bom-page__body flex-1'>
        <div className='settings-block__left' style={{ flex: 0.25 }}>
          <BillOfMaterialActions
            isDrive={redirect === 'drive'}
            onBackClick={onBackClick}
            onExportClick={onExportClick}
            loading={loading}
          />
          <SectionsList
            kitId={kit?.kitId}
            sections={sections}
            onClick={onSelectSection}
            tenantColor={tenant.colorPrimary}
            selectedSection={selectedSection}
          />
        </div>
        <div style={{ flex: 0.75 }}>
          <div>
            <div>
              <BillOfMaterialBreadcrumbs
                kitId={kit?.kitId}
                selectedSection={selectedSection}
                tenantColor={tenant.colorPrimary}
              />
            </div>
            <div>
              <Input
                type='search'
                placeholder='Search'
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
            </div>
          </div>

          {selectedSection && selectedSection?.image && (
            <div>
              <div className='image-container' style={{ marginLeft: 0, marginRight: 0, minHeight: 250 }}>
                {selectedSection && selectedSection.image && (
                  <>
                    <img src={selectedSection?.image?.downloadUrl} alt='section-preview' />
                    <div
                      className='full-screen-icon'
                      onClick={() => window.open(selectedSection?.image?.downloadUrl, '_blank')}
                    >
                      <FullScreenIcon color='secondary' fontSize='large' />
                    </div>
                  </>
                )}
              </div>
            </div>
          )}

          {selectedSection && (
            <SectionItemsList
              items={items}
              search={search}
              selectedItem={selectedItem}
              tenantColor={tenant.colorPrimary}
              itemsList={itemsList}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default BOMViewPage;
