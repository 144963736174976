const routes = {
  HOME: '/',
  SIGN_IN: '/',
  SIGN_UP: '/sign-up',
  FORGOT_PASSWORD: '/forgot-password',
  SET_PASSWORD: '/set-password',
  SETTINGS: '/settings',
  USERS: '/settings/users',
  GROUPS: '/settings/groups',
  PROCEDURES: '/settings/procedures',
  LOCATIONS: '/settings/locations',
  HOSPITALS: '/settings/locations/hospitals',
  TERRITORIES: '/settings/locations/territories',
  PRODUCTS: '/settings/products',
  KITS_SETTINGS: '/settings/kits',
  SURGEONS: '/settings/surgeons',
  COMPANY: '/settings/company',
  DRIVE_SETTINGS: '/settings/drive',
  SCAN: '/settings/scan',
  SCAN_QR: '/settings/scan/custom-qr',
  SCAN_GTIN: '/settings/scan/gtin-reference',
  REPEATING_COUNTS: '/settings/scan/repeating-counts',
  TAGS: '/settings/scan/tags',
  SETS_SETTINGS: '/settings/sets',
  NOTIFICATIONS_SETTINGS: '/settings/notifications',
  EMAIL_NOTIFICATIONS: '/settings/notifications/emails',
  PRODUCT_NOTIFICATIONS: '/settings/notifications/products',
  USAGE_NOTIFICATIONS: '/settings/notifications/usage',
  RESUPPLY_NOTIFICATIONS: '/settings/notifications/resupply',
  OVERDUE_SHIPPING_NOTIFICATIONS: '/settings/notifications/overdue-shipping',
  FLOWS: '/settings/flows',
  ITEMS: '/settings/items',
  FORMS_SETTINGS: '/settings/forms',
  KIT_PREFERENCES: '/settings/kit-preferences',
  CASES: '/cases',
  CHECKLISTS: '/checklists',
  RETURN_SETS: '/return',
  RETURN_ADDITIONAL_SETS: '/return-additional',
  TOOLS: '/tools',
  TOOLS_SURGEONS: '/tools/surgeons',
  TOOLS_HOSPITALS: '/tools/hospitals',
  TOOLS_TASKS: '/tools/tasks',
  TOOLS_FORMS: '/tools/forms',
  ANALYTICS: '/analytics',
  DRIVE: '/drive',
  NOTIFICATIONS: '/notifications',
  KITS: '/shipping',
  SETS: '/sets',
  PROFILE: '/profile',
  USAGE: '/usage',
  INVENTORY: '/counts',
  REPORTING: '/reporting',
  CASE_REPORTING: '/reporting/cases',
  KIT_REPORTING: '/reporting/kits',
  BILLING_REPORTING: '/reporting/billing',
  COUNT_REPORTING: '/reporting/counts',
  ITEMS_REPORTING: '/reporting/items',
  USAGE_REPORTING: '/reporting/usage',
  SET_TURNS_REPORTING: '/reporting/set-turns',
  CONSIGNMENT_TURNS_REPORTING: '/reporting/consignment-turns',
  EXPIRING_ITEMS_REPORTING: '/reporting/expiring-items',
  BOM_LOOKUP_REPORTING: '/reporting/bom-lookup',
  MANAGEMENT_REPORTS: '/reporting/management-reports',
  SET_SUMMARY_REPORTING: '/reporting/set-summary',
  BILL_OF_MATERIAL: '/bill-of-material',
  PARENT_KIT_SETTINGS: '/parent-kit-settings',
  FORMS: '/forms',
  SUBMITTED_FORMS: '/submitted-forms',
  BATCH_CONTROL: '/batch-control',
  // Global Admin
  ADMIN: '/admin'
};

export default routes;
