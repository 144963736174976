import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

import defaultColor from './defaultColor';

export default function ArrowDownIcon(props) {
  const color = props.color === 'secondary' ? '#b1b1b1' : props.color;
  return <SvgIcon {...props} viewBox="0 0 14 14" fill="none">
    <path
      d="M6.830964 0.27769C6.6724000000000006 0.341404 6.21355 0.604338 5.9027780000000005 0.8095640000000001C4.79171 1.543304 3.711638 2.6236840000000003 2.976162 3.7370200000000002C2.669772 4.200826 2.431548 4.643702 2.414328 4.781560000000001C2.387588 4.995508 2.5331180000000004 5.227698 2.744 5.307554C2.851156 5.34814 3.012604 5.340173999999999 3.124744 5.28878C3.242848 5.234656 3.311756 5.15578 3.421642 4.94893C3.65113 4.516918 3.882144 4.159652 4.177488 3.7800000000000002C4.786208 2.997554 5.573259999999999 2.249604 6.3420000000000005 1.723036L6.489 1.6223480000000001 6.496 7.492674000000001C6.501992 12.514586 6.50587 13.370084 6.522838 13.412C6.574918 13.540604 6.673366 13.64825 6.79 13.704124C6.8565000000000005 13.735974 6.885144 13.741 7 13.741C7.1148560000000005 13.741 7.1435 13.735974 7.21 13.704124C7.326634 13.64825 7.425082000000001 13.540604 7.477162 13.412C7.494129999999999 13.370084 7.4980080000000005 12.514543999999999 7.5040000000000004 7.49238L7.511 1.621774 7.7 1.752436C8.451253999999999 2.27185 9.222066000000002 3.0081800000000003 9.822512 3.7800000000000002C10.117856 4.159652 10.348870000000002 4.516918 10.578358 4.94893C10.688188 5.155682 10.75718 5.234698 10.874976 5.288626000000001C10.980129999999999 5.336758000000001 11.138064 5.347524 11.240432 5.313518C11.457502 5.241418 11.613042 5.000548 11.585392 4.779278000000001C11.561746000000001 4.5900680000000005 11.110554 3.820446 10.715642 3.29574C10.108462000000001 2.48899 9.335732 1.716456 8.542506 1.123164C8.142666 0.82411 7.680105999999999 0.5335260000000001 7.288792000000001 0.33556600000000003C7.14392 0.26229 7.13223 0.258846 7.016212 0.255486C6.924778 0.252826 6.879264 0.258286 6.830964 0.27769"
      stroke={color || defaultColor} fill="none" fillRule="evenodd" />
  </SvgIcon>
}
