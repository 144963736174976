import React, { useCallback, useMemo } from 'react';

import Table from '../shared/table/TableRemote';

import { getComparator, stableSort } from '../../utils/table';

const columns = [
  { title: '', field: 'caseStatus', type: 'caseStatus', sort: false },
  { title: 'case date', field: 'date', type: 'date', sort: true },
  { title: 'surgeon', field: 'surgeonName', type: 'default', sort: true },
  { title: 'hospital', field: 'hospitalName', type: 'default', sort: true },
  { title: 'state', field: 'hospitalState', type: 'default', sort: true },
  { title: 'patient ref', field: 'patientReference', type: 'default', sort: true },
  { title: 'procedure', field: 'procedureName', type: 'default', sort: true },
];

const reportColumns = [
  { title: '', field: 'caseStatus', type: 'caseStatus', sort: false },
  { title: 'case date', field: 'date', type: 'date', sort: true },
  { title: 'surgeon', field: 'surgeonName', type: 'default', sort: true, filter: true, filterTitle: 'Filter Surgeons' },
  {
    title: 'hospital',
    field: 'hospitalName',
    type: 'default',
    sort: true,
    filter: true,
    filterTitle: 'Filter Hospitals',
  },
  { title: 'state', field: 'hospitalState', type: 'default', sort: true },
  { title: 'created by', field: 'userName', type: 'default', sort: true },
  {
    title: 'procedure',
    field: 'procedureName',
    type: 'default',
    sort: true,
    filter: true,
    filterTitle: 'Filter Procedures',
  },
];

const CasesTable = (props) => {
  const {
    onSelectCase,
    cases,
    selectedCaseId,
    handleCheck,
    checkedCases,
    handleCheckAll,
    users = [],
    withoutCheck,
    rowColor,
    rowBorderColor,
    pagination,
    checkboxes,
    isReport,
    filter,
    filterOptions,
    onFilterChange,
    order,
    setOrder,
    orderBy,
    setOrderBy,
    page,
    setPage,
    rowsPerPage,
    setRowsPerPage,
  } = props;

  const getUserName = useCallback((uid) => {
    const user = users?.find((u) => u.uid === uid);
    return user ? `${user.firstName} ${user.lastName}` : '';
  }, [users]);

  const rows = useMemo(() => {
    if (cases && cases.length) {
      const sortedRows = stableSort(cases, getComparator(order, orderBy)) || [];
      return pagination ? sortedRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : sortedRows;
    }
    return [];
  },[cases, order, orderBy, page, rowsPerPage]);

  return (
    <div style={{ width: '100%' }}>
      <Table
        className="cases-table"
        rows={rows.map((item) => ({
          ...item,
          caseStatus: {
            status: item.status,
            canceled: !item.active,
            kitVariant: item.kitVariant,
            additional: !!item.additionalKits?.length || !!item.additionalItems?.length,
            proformaSent: !!item.proformaSent,
            orderClosed: !!item.orderClosed
          },
          userName: getUserName(item.createdBy),
        }))}
        columns={isReport ? reportColumns : columns}
        onRowClick={onSelectCase}
        selected={selectedCaseId}
        handleCheck={handleCheck}
        checkedcases={checkedCases}
        handleCheckAll={handleCheckAll}
        withoutCheck={withoutCheck}
        rowColor={rowColor}
        rowBorderColor={rowBorderColor}
        pagination={pagination}
        checkboxes={checkboxes}
        currentFilter={filter}
        filterOptions={filterOptions}
        onFilterChange={onFilterChange}
        noCheckAll
        order={order}
        setOrder={setOrder}
        orderBy={orderBy}
        setOrderBy={setOrderBy}
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        totalRows={cases?.length}
      />
    </div>
  );
};

export default CasesTable;
