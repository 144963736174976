import React from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import CircularProgress from '@material-ui/core/CircularProgress';

import ItemIcon from '../../assets/icons/ItemIcon';
import KitIcon from '../../assets/icons/KitIcon';
import TitleIcon from '../../assets/icons/TitleIcon';

import { kitPreferenceItemTypes } from '../../constants/enums';

const PreferenceItemsList = (props) => {
  const { loading, items, onClick, selectedItem, tenantColor, onReorder, kitsList = [], itemsList = [] } = props;

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    const newItems = [...items];
    const [removed] = newItems.splice(result.source.index, 1);
    newItems.splice(result.destination.index, 0, removed);
    onReorder(newItems);
  }

  const getItemIcon = (type, selected) => {
    switch (type) {
      case kitPreferenceItemTypes.ITEM.value:
        return <ItemIcon color={selected ? tenantColor : ''} />;
      case kitPreferenceItemTypes.KIT.value:
        return <KitIcon color={selected ? tenantColor : ''} />;
      case kitPreferenceItemTypes.TITLE.value:
        return <TitleIcon color={selected ? tenantColor : ''} />;
      default:
        return null;
    }
  }

  const getLabel = (item) => {
    if (item.type === kitPreferenceItemTypes.ITEM.value) {
      const _item = itemsList?.find((i) => i.id === item.value);
      return _item?.code || '';
    } else if (item.type === kitPreferenceItemTypes.KIT.value) {
      const _kit = kitsList?.find((k) => k.id === item.value);
      return _kit?.kitId || '';
    } else {
      return item?.title || '';
    }
  };

  const getDescription = (item) => {
    if (item.type === kitPreferenceItemTypes.ITEM.value) {
      const _item = itemsList?.find((i) => i.id === item.value);
      return _item?.checklistDescription || _item?.description || '';
    } else if (item.type === kitPreferenceItemTypes.KIT.value) {
      const _kit = kitsList?.find((k) => k.id === item.value);
      return _kit?.name || '';
    } else {
      return item?.subtitle || '';
    }
  };

  return (
    <div className='drive-files-list__container'>
      {loading ? (
        <CircularProgress size={24} />
      ) : (
        <div className='width-100'>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId='droppable'>
              {(provided) => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                >
                  {items.map((item, index) => (
                    <Draggable key={item.id} draggableId={item.id} index={index} style={{ width: '100%' }}>
                      {(provided) => (
                        <div
                          className='drive-files-item'
                          onClick={() => onClick(item)}
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                        >
                          <div style={{ display: 'flex' }}>
                            {getItemIcon(item.type, selectedItem?.id === item.id)}
                          </div>
                          <div className='drive-files-item__name'>
                            <div style={{ color: selectedItem?.id === item.id ? tenantColor : 'inherit' }}>
                              {getLabel(item)}
                            </div>
                            <div className='secondary font-size-xs'>{getDescription(item)}</div>
                          </div>

                          <div className='p-sm d-flex flex-center' {...provided.dragHandleProps}>
                            <DragIndicatorIcon />
                          </div>
                        </div>
                      )}
                    </Draggable>
                  ))}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>
      )}
    </div>
  );
};

export default PreferenceItemsList;
