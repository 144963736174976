import { GET_SURGEONS, SET_QUALIFICATIONS, SET_SURGEONS, SET_TRANSFERRED_SURGEONS } from '../actions/actionTypes';

const initialState = {
  list: [],
  transferredSurgeons: [],
  qualifications: [],
  isLoading: false,
  isLoaded: false
};

const surgeonsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_SURGEONS:
      return { ...state, isLoading: true };
    case SET_SURGEONS:
      return {
        ...state,
        list: action.surgeons,
        isLoaded: true,
        isLoading: false
      };
    case SET_TRANSFERRED_SURGEONS:
      return {
        ...state,
        transferredSurgeons: action.surgeons,
      };
    case SET_QUALIFICATIONS:
      return {
        ...state,
        qualifications: action.qualifications || [],
      };
    default:
      return state;
  }
};

export default surgeonsReducer;
