import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import ArrowIcon from '@material-ui/icons/KeyboardArrowLeft';
import Grid from '@material-ui/core/Grid';

import ItemIcon from '../../../assets/icons/ItemIcon';

import BOMSectionsView from './BOMSectionsView';
import KitFile from '../../drive/KitFile';

import { getKit, getSections } from '../../../actions/kitsActions';
import { getItemsByIds } from '../../../actions/itemsActions';

import { billOfMaterialItemTypes, kitItemTypes } from '../../../constants/enums';

const views = {
  PARENT: 'PARENT_VEW',
  BOM: 'BOM_VIEW',
};

const KitBOM = ({ kitId, onBack }) => {
  const dispatch = useDispatch();
  const kits = useSelector((state) => state?.kits?.list);

  const [kit, setKit] = useState(null);
  const [child, selectedChild] = useState(null);
  const [sections, setSections] = useState([]);
  const [view, setView] = useState(views.BOM)
  const [items, setItems] = useState([]);

  const [fetching, setFetching] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    onLoad().catch((err) => console.error(err));
  }, []);

  const onLoad = async () => {
    setFetching(true);

    try {
      const res = await dispatch(getKit(kitId));
      if (res && res?.id) {
        setKit(res);
      }
      if (res && !res?.isParent) {
        const sectionsRes = await dispatch(getSections(kitId));
        if (sectionsRes) {
          let _items = [];
          for (const section of sectionsRes) {
            const itemIds = section.items?.filter((i) => i.type !== billOfMaterialItemTypes.NOTES.value).map((i) => i.id) || [];
            const newItems = await dispatch(getItemsByIds(itemIds));
            _items = [..._items, ...newItems];
          }

          setSections(sectionsRes);
          setItems(_items);
        }
      } else {
        if (res.children) {
          const itemIds = res.children?.filter((i) => i.type === kitItemTypes.ITEM.value).map((i) => i.id) || [];
          const _items = await dispatch(getItemsByIds(itemIds));
          setItems(_items);
        }
        setView(views.PARENT);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setFetching(false);
    }
  };

  const onChildSelect = async (kit) => {
    try {
      setLoading(true);
      selectedChild(kit)
      const sectionsRes = await dispatch(getSections(kit.id));
      if (sectionsRes?.length) {
        let _items = [];
        for (const section of sectionsRes) {
          const itemIds = section.items?.filter((i) => i.type !== billOfMaterialItemTypes.NOTES.value).map((i) => i.id) || [];
          const newItems = await dispatch(getItemsByIds(itemIds));
          _items = [..._items, ...newItems];
        }

        setSections(sectionsRes);
        setItems(_items);
        setView(views.BOM);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const onBackClick = () => {
    if (view === views.BOM && !!kit?.isParent) {
      setView(views.PARENT);
      setSections([]);
    } else {
      onBack();
    }
  };

  return (
    <div className='case-kit-bom__container'>
      {view === views.PARENT ? (
        <div>
          <div className='d-flex'>
            <IconButton
              color="primary"
              className="m-r-md"
              onClick={onBackClick}
              size="small"
            >
              <ArrowIcon fontSize="large" color="primary" />
            </IconButton>
            <div className="m-l-sm font-bold font-size-bg">
              {kit?.name}
            </div>
          </div>

          {loading && <div className='d-flex flex-center p-md'>
            <CircularProgress size={36} />
          </div>}

          <div className='m-t-lg'>
            <Grid container spacing={0}>
              {kit?.children?.map((c) => {
                const arr = c.type === kitItemTypes.KIT.value ? kits : items;
                const kit = arr?.find((k) => k?.id === c?.id);
                return kit ? (
                  <Grid key={kit.id} item xs={12} sm={6} md={6} lg={4}>
                    {c.type === kitItemTypes.KIT.value ? (
                      <KitFile
                        {...kit}
                        onClick={() => onChildSelect(kit)}
                      />
                    ) : (
                      <div className='drive-folder__container'>
                        <div className='drive-folder-icon'>
                          <ItemIcon fontSize='large' />
                        </div>
                        <div className='drive-folder-name__container'>
                          <div className='font-size-bg font-bold'>
                            {kit?.code}
                          </div>
                          <div className='d-flex secondary font-size-sm'>
                            <span className='m-l-sm'>{kit?.description}</span>
                          </div>
                        </div>
                      </div>
                    )}
                  </Grid>
                ) : null;
              })}
            </Grid>
          </div>
        </div>
      ) : (
        <BOMSectionsView
          sections={sections}
          onBackClick={onBackClick}
          kitName={child?.name || kit?.name || ''}
          items={items}
          loading={fetching}
        />
      )}
    </div>
  );
};

export default KitBOM;
