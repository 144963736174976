import React from 'react';

import Button from '../../shared/Button';
import Select from '../../shared/Select';

import './styles.scss';

const statusOptions = [
  { label: 'Loan Fleet', value: 'NOT_CONSIGNED' },
  { label: 'Consigned', value: 'CONSIGNED' },
];

const SetSummaryForm = (props) => {
  const {
    status,
    setStatus,
    onSubmit,
    loading,
  } = props;

  return (
    <div className='set-turns-form__container'>
      {/*<div className='set-turns-form__field'>*/}
      {/*  <div>Kit ID</div>*/}
      {/*  <div className='set-turns-form__select-box' onClick={() => toggleModal(true)}>*/}
      {/*    {kitId || 'Select'}*/}
      {/*    <KeyboardArrowDownIcon style={{ fill: '#c6c8c9' }} />*/}
      {/*  </div>*/}

      {/*  <ModalSelect*/}
      {/*    title='Select Kit'*/}
      {/*    listTitle='Kits'*/}
      {/*    options={kits}*/}
      {/*    onChange={(value) => {*/}
      {/*      if (value && value.length) {*/}
      {/*        setKit(value[0]);*/}
      {/*      } else {*/}
      {/*        setKit(null);*/}
      {/*      }*/}
      {/*    }}*/}
      {/*    selected={[kit]}*/}
      {/*    onClose={() => toggleModal(false)}*/}
      {/*    isOpen={isOpen}*/}
      {/*    singleSelect={true}*/}
      {/*  />*/}
      {/*</div>*/}
      <div style={{ flex: '0.3' }}>
        <div>Select Status</div>
        <Select
          value={status}
          onChange={setStatus}
          options={statusOptions}
        />
      </div>

      <div className='set-turns-form__button'>
        <Button
          onClick={() => onSubmit(status)}
          disabled={loading}
          loading={loading}
          text='Run Report'
        />
      </div>
    </div>
  )
};

export default SetSummaryForm;
