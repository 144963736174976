import React from 'react';
import { ResponsivePie } from '@nivo/pie';

import { SetsTooltip } from './chart-tooltips/SetsTooltip';

const SetsPieChart = ({
  data,
  margin,
  innerRadius,
  padAngle,
  cornerRadius,
  sortByValue,
  enableArcLabels,
  arcLabel,
  arcLabelsSkipAngle,
  enableArcLinkLabels,
  arcLinkLabel,
  arcLinkLabelsSkipAngle,
  arcLinkLabelsThickness,
  arcLinkLabelsDiagonalLength,
  arcLinkLabelsStraightLength,
  activeOuterRadiusOffset,
  arcLinkLabelsOffset,
  arcLabelsTextColor,
  arcLinkLabelsTextColor,
  arcLinkLabelsColor,
  borderWidth,
  borderColor,
  animate,
  theme,
  isInteractive,
  transitionMode,
  height,
  width,
}) => (
  <div style={{ height, width }}>
    <ResponsivePie
      data={data}
      margin={margin}
      borderWidth={borderWidth}
      borderColor={borderColor}
      innerRadius={innerRadius}
      padAngle={padAngle}
      cornerRadius={cornerRadius}
      activeOuterRadiusOffset={activeOuterRadiusOffset}
      enableArcLabels={enableArcLabels}
      arcLabel={arcLabel}
      enableArcLinkLabels={enableArcLinkLabels}
      arcLabelsSkipAngle={arcLabelsSkipAngle}
      arcLabelsTextColor={arcLabelsTextColor}
      arcLinkLabel={arcLinkLabel}
      arcLinkLabelsSkipAngle={arcLinkLabelsSkipAngle}
      arcLinkLabelsOffset={arcLinkLabelsOffset}
      arcLinkLabelsTextColor={arcLinkLabelsTextColor}
      arcLinkLabelsThickness={arcLinkLabelsThickness}
      arcLinkLabelsColor={arcLinkLabelsColor}
      arcLinkLabelsDiagonalLength={arcLinkLabelsDiagonalLength}
      arcLinkLabelsStraightLength={arcLinkLabelsStraightLength}
      sortByValue={sortByValue}
      theme={theme}
      tooltip={({ datum: { id, formattedValue, color } }) => (
        <SetsTooltip datum={{ id, formattedValue, color }} />
      )}
      animate={animate}
      colors={{ datum: 'data.color' }}
      isInteractive={isInteractive}
      transitionMode={transitionMode}
    />
  </div>
);

SetsPieChart.defaultProps = {
  animate: true,
  colors: { scheme: 'nivo' },
  enableArcLabels: false,
  enableArcLinkLabels: false,
  sortByValue: false,
  arcLabel: 'value',
  arcLinkLabel: 'id',
  arcLabelsSkipAngle: 0,
  arcLinkLabelsSkipAngle: 10,
  arcLinkLabelsThickness: 1,
  arcLinkLabelsDiagonalLength: 16,
  arcLinkLabelsStraightLength: 24,
  arcLinkLabelsOffset: 0,
  arcLinkLabelsTextColor: '#333333',
  arcLabelsTextColor: { from: 'color', modifiers: [['darker', 2]] },
  arcLinkLabelsColor: { from: 'color' },
  margin: { top: 40, right: 80, bottom: 80, left: 80 },
  borderColor: ({ data }) => data.borderColor,
  innerRadius: 0.5,
  padAngle: 1.5,
  cornerRadius: 3,
  activeOuterRadiusOffset: 8,
  borderWidth: 1,
  isInteractive: true,
  transitionMode: 'innerRadius',
  theme: {
    fontSize: '12px',
    fontFamily: 'Inter',
  },
  height: '100%',
  width: '100%',
};

export default SetsPieChart;
