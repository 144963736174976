import React, { useState, useMemo, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { debounce } from 'lodash';
import { Column, Table, SortDirection } from 'react-virtualized';

import { CircularProgress } from '@material-ui/core';
import TablePagination from '@material-ui/core/TablePagination';

import Checkbox from '../../shared/Checkbox';
import Button from '../../shared/Button';
import Modal from '../../shared/modal/Modal';
import Input from '../../shared/Input';
import Select from '../../shared/Select';

import { getPaginatedItems } from '../../../actions/itemsActions';

import { itemTypeOptions } from '../../../constants/enums';

import '../../shared/modal-select/modal-select.scss';
import 'react-virtualized/styles.css';

const SectionItemsModal = (props) => {
  const {
    isOpen,
    onClose,
    onSubmit,
    selected = [],
    loading,
  } = props;

  const dispatch = useDispatch();

  const [items, setItems] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [fetching, setFetching] = useState(false);

  const [sortBy, setSortBy] = useState('code');
  const [sortDirection, setSortDirection] = useState(SortDirection.ASC);
  const [filters, setFilters] = useState({ type: '' });
  const [search, setSearch] = useState('');

  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [numberOfDocs, setNumberOfDocs] = useState(0);
  const [, setTotalPages] = useState(1);

  const debouncedOnChange = useMemo(() => {
    return debounce((value) => setFilters({ ...filters, code: value }), 500);
  }, [setFilters]);

  useEffect(() => {
    fetchItems().catch();
  }, [sortBy, sortDirection, filters, isOpen, currentPage, rowsPerPage]);

  const handleSearch = (e) => {
    setSearch(e.target.value);
    debouncedOnChange(e.target.value);
  };

  const fetchItems = async () => {
    try {
      if (!isOpen) {
        return;
      }

      setFetching(true);

      const res = await dispatch(getPaginatedItems({
        page: currentPage,
        docsPerPage: rowsPerPage,
        orderBy: sortBy,
        orderDirection: sortDirection?.toLowerCase(),
        filters,
      }));

      setNumberOfDocs(res?.count);
      setTotalPages(Math.ceil(res?.count / rowsPerPage));
      setItems(res?.items?.length ? res?.items : []);
    } catch (err) {
      console.error(err);
    } finally {
      setFetching(false);
    }
  };

  const handleCheck = (itemId) => {
    let temp = selectedItems.slice();

    if (temp?.map((i) => i.id)?.includes(itemId)) {
      temp = temp.filter((i) => i.id !== itemId);
    } else {
      temp = [...temp, { id: itemId, quantity: 1, ref: '' }];
    }

    setSelectedItems(temp);
  };

  // const handleCheckAll = (value) => {
  //   let temp = selectedItems.slice();
  //
  //   if (value) {
  //     list.forEach((item) => {
  //       if (!selectedItems?.map((i) => i.id).includes(item.id)) {
  //         temp.push({ id: item.id, quantity: 1, ref: '' });
  //       }
  //     });
  //   } else {
  //     temp = selectedItems.filter((item) => !list?.map((i) => i.id).includes(item.id));
  //   }
  //   setSelectedItems(temp);
  // };

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      size="lg"
    >
      <div className="form__container section-items-modal__container">
        <div className="form__body">
          <div className="form__subtitle">
            Add Items
          </div>

          <div className="section-items-modal__filters">
            <div className="m-r-md">
              <Input
                type="search"
                placeholder="Search"
                value={search}
                onChange={handleSearch}
              />
            </div>

            <div className="m-l-md">
              <Select
                value={filters?.type}
                onChange={(value) => setFilters({ ...filters, type: value })}
                options={[{ label: 'All', value: '' }, ...Object.values(itemTypeOptions)]}
              />
            </div>
          </div>

          <div className="secondary font-size-sm m-b-sm">Items</div>
          <div className="form__select-users" style={{ minWidth: 860, minHeight: 540, position: 'relative' }}>
            {fetching && (
              <div
                className='d-flex p-lg flex-center align-center'
                style={{
                  position: 'absolute',
                  height: 'calc(100% - 32px)',
                  width: 'calc(100% - 32px)',
                  background: 'rgba(227, 230, 233, 0.52)',
                  zIndex: 1000,
                  borderRadius: '8px'
                }}
              >
                <CircularProgress size={40} />
              </div>
            )}
            {items?.length ? (
              <VirtualizedTable
                data={items?.map((i) => ({ ...i, description: i.checklistDescription || i.description || '' }))}
                onCheckAll={() => {}}
                checked={selectedItems?.map((i) => i.id)}
                onCheck={handleCheck}
                sortBy={sortBy}
                setSortBy={setSortBy}
                sortDirection={sortDirection}
                setSortDirection={setSortDirection}
                selected={selected?.map((i) => i.id)}
              />
            ) : (
              <div className="text-center secondary p-md">
                No Items
              </div>
            )}
          </div>

          <TablePagination
            rowsPerPageOptions={[50, 100, 200]}
            count={numberOfDocs || items.length}
            page={currentPage}
            rowsPerPage={rowsPerPage}
            onPageChange={(e, value) => setCurrentPage(value)}
            onRowsPerPageChange={(e, value) => setRowsPerPage(value)}
            style={{ padding: '4px' }}
          />

          <div className="form__actions m-t-lg">
            <Button
              type="submit"
              text="Submit"
              onClick={async () => {
                await onSubmit(selectedItems);
                setSelectedItems([]);
              }}
              disabled={loading}
              loading={loading}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

const VirtualizedTable = (props) => {
  const {
    data,
    selected,
    checked,
    onCheck,
    sortBy,
    setSortBy,
    sortDirection,
    setSortDirection,
  } = props;

  const handleSort = (params) => {
    setSortBy(params.sortBy);
    setSortDirection(params.sortDirection);
  };

  return (
    <div style={{ width: '100%', height: '100%' }}>
      <Table
        width={860} // Width of the table
        height={500} // Height of the table
        headerHeight={36} // Height of the table header
        rowHeight={36} // Height of each table row
        rowCount={data.length} // Total number of rows
        rowGetter={({ index }) => data[index]} // Function to get the data for each row
        sort={handleSort}
        sortBy={sortBy}
        sortDirection={sortDirection}
      >
        <Column
          label=""
          disableSort
          dataKey="id"
          width={50}
          headerRenderer={({ label, sortBy, sortDirection }) => (
            <div>
              {/*<Checkbox*/}
              {/*  input={{*/}
              {/*    onClick: (e) => onCheckAll(e.target.checked),*/}
              {/*    checked: data.every((item) => checked?.includes(item.id)),*/}
              {/*  }}*/}
              {/*  fontSize="small"*/}
              {/*/>*/}
            </div>
          )}
          cellRenderer={({ rowIndex }) => {
            return (
              <div className='p-l-md'>
                <Checkbox
                  input={{
                    onClick: () => onCheck(data[rowIndex].id),
                    checked: !!checked?.includes(data[rowIndex].id) || !!selected?.includes(data[rowIndex].id),
                    disabled: !!selected?.includes(data[rowIndex].id),
                  }}
                  index={rowIndex}
                  disabled={!!selected?.includes(data[rowIndex].id)}
                  fontSize="small"
                />
              </div>
            )
          }}
        />
        <Column
          label="Item Code"
          dataKey="code"
          width={200}
          headerRenderer={({ label, sortBy, sortDirection }) => (
            <div>
              {label}
              {sortBy === 'code' && (
                <SortCaret direction={sortDirection}/>
              )}
            </div>
          )}
        />
        <Column
          label="Description"
          dataKey="description"
          width={410}
          headerRenderer={({ label, sortBy, sortDirection }) => (
            <div>
              {label}
              {sortBy === 'description' && (
                <SortCaret direction={sortDirection}/>
              )}
            </div>
          )}
        />
        <Column
          label="Type"
          dataKey="type"
          width={200}
          headerRenderer={({ label, sortBy, sortDirection }) => (
            <div>
              {label}
              {sortBy === 'type' && (
                <SortCaret direction={sortDirection}/>
              )}
            </div>
          )}
        />
      </Table>
    </div>
  );
};

const SortCaret = ({ direction }) => {
  return (
    <span style={{ marginLeft: '5px' }}>
      {direction === SortDirection.ASC ? '▼' : '▲'}
    </span>
  );
};

export default SectionItemsModal;
