import React, { useState, useEffect, useMemo } from 'react';
import { Field, Form } from 'react-final-form';
import { useDispatch } from 'react-redux';

import CircularProgress from '@material-ui/core/CircularProgress';

import KitsList from './KitsList';
import ItemsList from './ItemsList';
import KitPreferencesList from './KitPreferencesList';
import Button from '../../shared/Button';
import Checkbox from '../../shared/Checkbox';
import Select from '../../shared/Select';

import { getKitPreferenceByParameters } from '../../../actions/kitPreferencesActions';

import ItemsIcon from '../../../assets/icons/ItemIcon';
import KitIcon from '../../../assets/icons/KitIcon';
import KitPreferenceIcon from '../../../assets/icons/KitPreferenceIcon';

import {
  kitTypeBookingOptions,
  caseStatusOptions,
  kitVariantTypes,
  kitPreferenceItemTypes,
} from '../../../constants/enums';

const kitTypes = [
  { label: 'Loan Booking', value: kitVariantTypes.loan },
  { label: 'Consignment Booking', value: kitVariantTypes.consignment },
];

const VIEWS = {
  LOADING: 0,
  DEFAULT: 1,
  KIT_PREFERENCES: 2,
};

const Step3 = (props) => {
  const {
    onClickNext,
    onClickBack,
    onClose,
    kits = [],
    items = [],
    status,
    sets = [],
  } = props;
  const { products, hospital } = props?.initialValues;

  const dispatch = useDispatch();

  const [noKitsChecked, setChecked] = useState(props?.initialValues && !!props?.initialValues.noEquipmentToShip);
  const [type, setType] = useState(props?.initialValues?.kitVariant || kitVariantTypes.loan);
  const [initialValues, setInitialValues] = useState(props.initialValues || {});

  const [, setLoading] = useState(false);
  const [view, setView] = useState(VIEWS.LOADING);
  const [kitPreference, setKitPreference] = useState(null);

  useEffect(() => {
    if (initialValues.surgeon && initialValues.procedure && initialValues.hospital) {
      setLoading(true);
      fetchKitPreference({
        surgeonId: initialValues.surgeon,
        procedureId: initialValues.procedure,
        hospitalId: initialValues.hospital,
      })
        .finally(() => setLoading(false));
    }
  }, [initialValues.surgeon, initialValues.procedure, initialValues.hospital]);

  const fetchKitPreference = async (parameters) => {
    const res = await dispatch(getKitPreferenceByParameters(parameters));
    if (res && !!res.active) {
      setKitPreference(res);
      let _initialValues = { ...initialValues };

      if (!_initialValues.kitPreference) {
        _initialValues.kitPreference = res.id;
      }

      if (!initialValues.kits && !initialValues.items) {
        const preSelectedKits = res?.items?.filter((i) => i.type === kitPreferenceItemTypes.KIT.value && !!i.preSelect)?.map((i) => ({
          id: i.value,
          quantity: 1,
        }));
        const preSelectedItems = res?.items?.filter((i) => i.type === kitPreferenceItemTypes.ITEM.value && !!i.preSelect)?.map((i) => ({
          id: i.value,
          quantity: i?.quantity || 1,
        }));

        _initialValues = {
          ..._initialValues,
          kits: preSelectedKits || [],
          items: preSelectedItems || [],
        };
      }

      setInitialValues(_initialValues);
      setView(VIEWS.KIT_PREFERENCES);
    } else {
      setView(VIEWS.DEFAULT);
    }
  };

  const resetKitPreferences = (value, mutators) => {
    if (!kitPreference || value === kitVariantTypes.consignment) {
      return;
    }

    const preSelectedKits = kitPreference?.items?.filter((i) => i.type === kitPreferenceItemTypes.KIT.value && !!i.preSelect)?.map((i) => ({
      id: i.value,
      quantity: 1,
    }));
    const preSelectedItems = kitPreference?.items?.filter((i) => i.type === kitPreferenceItemTypes.ITEM.value && !!i.preSelect)?.map((i) => ({
      id: i.value,
      quantity: i?.quantity || 1,
    }));

    mutators?.setKits(preSelectedKits);
    mutators?.setItems(preSelectedItems);
  };

  const validate = (values) => {
    const errors = {};
    return errors;
  };

  const kitsList = useMemo(() => {
    if (!kits) {
      return [];
    }
    if (!products || kitPreference) {
      return kits;
    }
    return kits.filter((kit) => kit.products.some((product) => products.includes(product)));
  }, [kits, products, kitPreference]);

  const consignmentKits = useMemo(() => {
    const setsList = sets?.filter((set) => !!set.consigned && set?.consignment?.hospital === hospital);
    return kits?.filter((kit) => (
      setsList?.map((s) => s.kit)?.includes(kit.id) && kit?.products?.some((product) => products.includes(product))
    )) || [];
  }, [hospital, kits, sets, type]);

  const itemsList = useMemo(() => {
    if (!items) {
      return [];
    }
    return items;
  }, [items, kitPreference]);

  if (view === VIEWS.LOADING) {
    return <div className="d-flex flex-center p-t-lg p-b-lg">
      <CircularProgress size={48} />
    </div>;
  }

  return (
    <div>
      <Form
        onSubmit={(formObj) => onClickNext(formObj, !!kitPreference)}
        validate={validate}
        initialValues={{
          ...initialValues,
          kitVariant: initialValues.kitVariant || kitTypes[0].value,
        }}
        subscription={{ pristine: true, invalid: true, values: true }}
        mutators={{
          changeNoEquipment: ([value], state, utils) => {
            utils.changeValue(state, 'noEquipmentToShip', () => value)
          },
          resetItems: (args, state, utils) => {
            utils.changeValue(state, 'items', () => [])
          },
          setKits: ([value], state, utils) => {
            utils.changeValue(state, 'kits', () => value)
          },
          setItems: ([value], state, utils) => {
            utils.changeValue(state, 'items', () => value)
          },
        }}
      >
        {
          ({ handleSubmit, submitting, invalid, pristine, form, values }) => (
            <form onSubmit={handleSubmit} className="form__body">

              <div className="m-b-lg">
                <div className="field__title">Type</div>
                <Field name="kitVariant">
                  {({ input, meta }) => (
                    <Select
                      name={input.name}
                      value={input.value}
                      onChange={(value) => {
                        input.onChange(value);
                        setType(value);
                        form.mutators.setKits([]);
                        form.mutators.setItems([]);
                        resetKitPreferences(value, form.mutators);
                      }}
                      options={kitTypes}
                      disabled={status && status !== caseStatusOptions.request}
                    />
                  )}
                </Field>
              </div>

              {view === VIEWS.KIT_PREFERENCES && values.kitVariant === kitVariantTypes.loan ? (
                <div className="m-b-lg">
                  <div className="m-b-lg form__select-users">
                    <>
                      <div className="m-b-sm p-b-sm" style={{ borderBottom: '1px solid #eeeeee' }}>
                        <Checkbox
                          disabled={status && ![caseStatusOptions.request, caseStatusOptions.booked]?.includes(status)}
                          input={{
                            checked: noKitsChecked,
                            onChange: (e) => {
                              setChecked(e.target.checked);
                              form.mutators.setKits([]);
                              form.mutators.setItems([]);
                              form.mutators.changeNoEquipment(e.target.checked);
                            },
                          }}
                          fontSize="small"
                          label="No kit preferences"
                        />
                      </div>

                      {(!!kitsList.length || !!itemsList.length) && (
                        <div className="p-t-md p-b-sm">
                          <div className="d-flex font-bold font-size-bg m-b-md">
                            <KitPreferenceIcon color="#F58634" />
                            <span className="m-l-sm" style={{ color: '#F58634' }}>
                              Kit Preferences Applied!
                            </span>
                          </div>
                          {!!kitPreference?.description && (
                            <div>
                              {kitPreference.description}
                            </div>
                          )}
                          <div className="d-flex font-bold m-t-lg m-b-lg">
                            <KitIcon />
                            <span className="m-l-sm">
                              {kitPreference?.name || 'Kit Preferences'}
                            </span>
                          </div>
                          <KitPreferencesList
                            kitPreference={kitPreference}
                            kitsList={kitsList}
                            itemsList={itemsList}
                            status={status}
                            noKitsChecked={noKitsChecked}
                            selectedKits={values.kits}
                            selectedItems={values.items}
                            onChangeKits={form.mutators.setKits}
                            onChangeItems={form.mutators.setItems}
                          />
                        </div>
                      )}
                    </>
                  </div>
                </div>
              ) : (
                <div className="m-b-lg">
                  {/*<div className='field__title'>Kits</div>*/}
                  <div className="m-b-lg form__select-users">
                    <Field name="kits">
                      {({ input, meta }) => (
                        <>
                          <div className="m-b-sm p-b-sm" style={{ borderBottom: '1px solid #eeeeee' }}>
                            <Checkbox
                              disabled={status && ![caseStatusOptions.request, caseStatusOptions.booked]?.includes(status)}
                              input={{
                                ...input,
                                checked: noKitsChecked,
                                onChange: (e) => {
                                  input.onChange([]);
                                  setChecked(e.target.checked);
                                  form.mutators.changeNoEquipment(e.target.checked);
                                  form.mutators.resetItems();
                                },
                              }}
                              fontSize="small"
                              label={type === kitVariantTypes.consignment ? 'Kit info unknown (skip)' : 'No equipment to ship'}
                            />
                          </div>

                          {type === kitVariantTypes.consignment ? (
                            consignmentKits.length ? (
                              <div className="p-t-md p-b-sm">
                                <div className="d-flex font-bold font-size-bg m-b-md">
                                  <KitIcon />
                                  <span className="m-l-sm">
                                  {type === kitVariantTypes.consignment ? 'Select Consignment' : 'Select Loan Equipment'}
                                </span>
                                </div>
                                {Object.values(kitTypeBookingOptions).map((kitType) => {
                                  if (!!consignmentKits.filter((kit) => kit.type === kitType.value).length) {
                                    return (
                                      <KitsList
                                        key={kitType.value}
                                        kitType={kitType}
                                        kitsList={consignmentKits}
                                        status={status}
                                        noKitsChecked={noKitsChecked}
                                        input={input}
                                        values={initialValues?.kits}
                                        kits={kits}
                                        items={items}
                                      />
                                    );
                                  } else {
                                    return null;
                                  }
                                })}
                              </div>
                            ) : (
                              <div className="secondary text-center m-t-md m-b-sm">No consignment kits</div>
                            )
                          ) : (
                            !!kitsList.length && (
                              <div className="p-t-md p-b-sm">
                                <div className="d-flex font-bold font-size-bg m-b-md">
                                  <KitIcon />
                                  <span className="m-l-sm">
                                  {type === kitVariantTypes.consignment ? 'Select Consignment' : 'Select Loan Equipment'}
                                </span>
                                </div>
                                {Object.values(kitTypeBookingOptions).map((kitType) => {
                                  if (!!kitsList.filter((kit) => kit.type === kitType.value).length) {
                                    return (
                                      <KitsList
                                        key={kitType.value}
                                        kitType={kitType}
                                        kitsList={kitsList}
                                        status={status}
                                        noKitsChecked={noKitsChecked}
                                        input={input}
                                        kits={kits}
                                        items={items}
                                      />
                                    );
                                  } else {
                                    return null;
                                  }
                                })}
                              </div>
                            )
                          )}
                        </>
                      )}
                    </Field>
                    {!!itemsList.length && type !== kitVariantTypes.consignment && (
                      <Field name="items">
                        {({ input, meta }) => (
                          <div className="m-t-sm p-t-sm" style={{ borderTop: '1px solid #eeeeee' }}>
                            <div className="d-flex font-bold font-size-bg p-t-md p-b-sm">
                              <ItemsIcon />
                              <span className="m-l-sm">Additional Items</span>
                            </div>
                            <ItemsList
                              itemsList={itemsList}
                              input={input}
                              status={status}
                              noKitsChecked={noKitsChecked}
                            />
                          </div>
                        )}
                      </Field>
                    )}
                  </div>
                </div>
              )}

              <div className="form__actions">
                <div className="d-flex">
                  <div className="m-r-md">
                    <Button
                      type="cancel"
                      text="Back"
                      onClick={onClickBack}
                    />
                  </div>
                  <Button
                    type="submit"
                    text={values?.kits?.length || values?.items?.length || noKitsChecked ? 'Next' : 'Skip'}
                    disabled={invalid}
                    onClick={handleSubmit}
                  />
                </div>
                <Button
                  type="cancel"
                  onClick={onClose}
                />
              </div>
            </form>
          )}
      </Form>
    </div>
  );
};

export default Step3;
