import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

import defaultColor from './defaultColor';

export default function ArrowDownIcon(props) {
  const color = props.color === 'secondary' ? '#b1b1b1' : props.color;
  return <SvgIcon {...props} viewBox="0 0 14 14" fill="none">
    <path
      d="M6.812582 0.285866C6.682956 0.340228 6.5794820000000005 0.448126 6.522838 0.588C6.50587 0.629916 6.501992 1.485414 6.496 6.507326000000001L6.489 12.377652000000001 6.3420000000000005 12.276964000000001C5.533234 11.722984 4.7047 10.923234 4.087846 10.101140000000001C3.8311420000000003 9.759022 3.625636 9.435089999999999 3.421642 9.05107C3.3118119999999998 8.844318 3.24282 8.765302 3.1250240000000002 8.711374C3.01987 8.663242 2.861936 8.652476 2.759568 8.686482C2.5426100000000003 8.75854 2.386972 8.999466 2.41458 9.220484C2.431534 9.356172 2.671984 9.802534000000001 2.976162 10.26298C3.433906 10.955896 4.011434 11.622618000000001 4.69 12.24153C5.314946 12.811526 5.994968 13.293112 6.663902 13.639402L6.86014 13.741 6.998740000000001 13.741L7.137354 13.741 7.28875 13.664434C8.664208 12.96883 10.100230000000002 11.661076 11.023838000000001 10.26298C11.328016 9.802534000000001 11.568465999999999 9.356172 11.58542 9.220484C11.613028 8.999466 11.45739 8.75854 11.240432 8.686482C11.138064 8.652476 10.980129999999999 8.663242 10.874976 8.711374C10.75718 8.765302 10.688188 8.844318 10.578358 9.05107C10.409882 9.36824 10.238102 9.648632 10.046638 9.919C9.428650000000001 10.791662 8.524712000000001 11.684035999999999 7.658 12.277076L7.511 12.377652000000001 7.5040000000000004 6.507326000000001C7.4980080000000005 1.485414 7.494129999999999 0.629916 7.477162 0.588C7.424186 0.45719799999999994 7.327068 0.35095200000000004 7.21 0.29575C7.096614000000001 0.24227 6.9266119999999995 0.23805600000000002 6.812582 0.285866"
      stroke={color || defaultColor} fill="none" fillRule="evenodd" />
  </SvgIcon>
}
