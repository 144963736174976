import React, { useState } from 'react';
import { Field, Form } from 'react-final-form';

import Grid from '@material-ui/core/Grid';
import MuiPhoneNumber from 'material-ui-phone-number';

import Button from '../shared/Button';
import Input from '../shared/Input';
import CountrySelect from '../shared/CountrySelect';
import Select from '../shared/Select';
import FileUpload from '../shared/FileUpload';
import ColorPicker from '../shared/color-picker/ColorPicker';

import { countries, timeZones } from '../../constants';
import numberOfEmployees from '../../constants/numberOfEmployees';

import '../../styles/shared/form.scss';

const CompanyForm = (props) => {
  const {
    initialValues,
    onSubmit,
    loading,
    storage,
    storageLoading,
    onGetStorageData,
  } = props;

  const [logo, setLogo] = useState(null);
  const [icon, setIcon] = useState(null);
  const [documentLogo, setDocumentLogo] = useState(null);

  const validate = (values) => {
    const errors = {};

    if (!values.businessName) {
      errors.businessName = 'Required';
    }
    if (!values.country) {
      errors.country = 'Required';
    }
    if (!values.address1) {
      errors.address1 = 'Required';
    }
    if (!values.city) {
      errors.city = 'Required';
    }
    if (!values.state) {
      errors.state = 'Required';
    }
    if (!values.postCode) {
      errors.postCode = 'Required';
    }

    return errors;
  };

  return (
    <Form
      onSubmit={(formObj) => onSubmit(formObj, { logo, icon, documentLogo })}
      validate={validate}
      initialValues={{
        ...initialValues,
      }}
      subscription={{ pristine: true, invalid: true }}
    >
      {
        ({ handleSubmit, submitting, invalid, pristine }) => (
          <form onSubmit={handleSubmit} className=''>
            <Grid container spacing={3} style={{ width: 'auto', margin: 0 }}>
              <Grid item xs={12} sm={6}>
                <div>
                  <div className='field__title'>Name</div>
                  <Field name='businessName'>
                    {({ input, meta }) => (
                      <Input
                        name={input.name}
                        value={input.value}
                        placeholder='Name'
                        type='text'
                        onChange={input.onChange}
                      />
                    )}
                  </Field>
                </div>

                <div>
                  <div className='field__title'>Country</div>
                  <Field name='country'>
                    {({ input, meta }) => (
                      <CountrySelect
                        countries={countries}
                        initialValues={initialValues}
                        input={input}
                      />
                    )}
                  </Field>
                </div>

                <div>
                  <div className='field__title'>Company Address</div>
                  <div className='combined-fields__container'>
                    <Field name='address1'>
                      {({ input, meta }) => (
                        <Input
                          name={input.name}
                          value={input.value}
                          borderless
                          startAdornment='Address'
                          type='text'
                          onChange={input.onChange}
                        />
                      )}
                    </Field>
                    <Field name='address2'>
                      {({ input, meta }) => (
                        <Input
                          name={input.name}
                          value={input.value}
                          borderless
                          startAdornment='Address'
                          type='text'
                          onChange={input.onChange}
                        />
                      )}
                    </Field>
                    <Field name='city'>
                      {({ input, meta }) => (
                        <Input
                          name={input.name}
                          value={input.value}
                          startAdornment='City'
                          borderless
                          type='text'
                          onChange={input.onChange}
                        />
                      )}
                    </Field>
                    <Field name='state'>
                      {({ input, meta }) => (
                        <Input
                          name={input.name}
                          value={input.value}
                          startAdornment='State'
                          borderless
                          type='text'
                          onChange={input.onChange}
                        />
                      )}
                    </Field>
                    <Field name='postCode'>
                      {({ input, meta }) => (
                        <Input
                          name={input.name}
                          value={input.value}
                          startAdornment='Postcode'
                          borderless
                          type='text'
                          onChange={input.onChange}
                        />
                      )}
                    </Field>
                  </div>
                </div>

                <div>
                  <div className='field__title'>Business No.</div>
                  <Field name='businessNumber'>
                    {({ input, meta }) => (
                      <Input
                        name={input.name}
                        value={input.value}
                        type='text'
                        onChange={input.onChange}
                      />
                    )}
                  </Field>
                </div>

                <div>
                  <div className='field__title'>External Users Connections (if active external users allowed)</div>
                  <Field name='externalUsersAllowed'>
                    {({ input, meta }) => (
                      <Input
                        type='switch'
                        name={input.name}
                        value={input.value}
                        onChange={input.onChange}
                        placeholder='External Users Connections'
                      />
                    )}
                  </Field>
                </div>

                <div>
                  <div className='field__title'>Time Zone</div>
                  <Field name='timeZone'>
                    {({ input, meta }) => (
                      <Select
                        name={input.name}
                        value={input.value}
                        onChange={input.onChange}
                        options={timeZones}
                        endAdornment='Change'
                      />
                    )}
                  </Field>
                </div>
              </Grid>
              <Grid item xs={12} sm={6}>
                <div>
                  <div className='field__title'>Phone Number</div>
                  <Field name='phone'>
                    {({ input, meta }) => (
                      <MuiPhoneNumber
                        value={input.value}
                        onChange={input.onChange}
                        fullWidth
                        placeholder='Phone'
                        defaultCountry='au'
                        className='form__phone p-l-md'
                        InputProps={{ disableUnderline: true }}
                      />
                    )}
                  </Field>
                </div>

                <div className='m-b-lg'>
                  <div className='field__title'>Splash Screen Logo (white)</div>
                  <Field name='logoName'>
                    {({ input, meta }) => (
                      <FileUpload
                        id='tenant-logo'
                        file={logo}
                        onUpload={(file) => {
                          setLogo(file);
                          input.onChange(file.name);
                        }}
                        placeholder={input.value}
                        accept='image'
                      />
                    )}
                  </Field>
                </div>

                <div className='m-b-lg'>
                  <div className='field__title'>Company Icon (black)</div>
                  <Field name='iconName'>
                    {({ input, meta }) => (
                      <FileUpload
                        id='tenant-icon'
                        file={icon}
                        onUpload={(file) => {
                          setIcon(file);
                          input.onChange(file.name);
                        }}
                        placeholder={input.value}
                        accept='image'
                      />
                    )}
                  </Field>
                </div>

                <div className='m-b-lg'>
                  <div className='field__title'>Document Logo (full color)</div>
                  <Field name='documentLogo'>
                    {({ input, meta }) => (
                      <FileUpload
                        id='document-logo'
                        file={documentLogo}
                        onUpload={(file) => {
                          setDocumentLogo(file);
                          input.onChange(file.name);
                        }}
                        placeholder={input.value}
                        accept='image'
                      />
                    )}
                  </Field>
                </div>

                <div>
                  <div className='field__title'>Brand Color</div>
                  <Field name='colorPrimary'>
                    {({ input, meta }) => (
                      <ColorPicker
                        value={input.value}
                        onChange={input.onChange}
                      />
                    )}
                  </Field>
                </div>

                <div className='m-t-lg'>
                  <div className='field__title'>Number of Employees</div>
                  <Field name='maxEmployee'>
                    {({ input, meta }) => (
                      <Select
                        name={input.name}
                        value={input.value}
                        onChange={input.onChange}
                        options={numberOfEmployees}
                        endAdornment='Change'
                      />
                    )}
                  </Field>
                </div>

              </Grid>
              <div className='form__actions' style={{ padding: '12px 12px 0 12px' }}>
                <div className='flex-1 d-flex flex-end'>
                  <Button
                    type='submit'
                    text='Save Changes'
                    disabled={invalid || loading || pristine}
                    loading={loading}
                    onClick={handleSubmit}
                  />
                </div>
                <div className='d-flex space-between flex-1 p-l-md' style={{ paddingRight: '32px' }}>
                  <div style={{ alignSelf: 'end' }}>
                    <div className='font-bold m-b-sm'>
                      <span className='secondary'>Total Cloud Storage:</span>
                      <span className='m-l-sm'>{storage?.size || '0.00'}</span>
                    </div>
                    <div className='font-bold secondary' style={{ alignSelf: 'end' }}>
                      Version 4.6.0
                    </div>
                  </div>
                  <Button
                    text='Calculate Storage'
                    disabled={storageLoading}
                    loading={storageLoading}
                    onClick={onGetStorageData}
                  />
                </div>
              </div>
            </Grid>
          </form>
        )}
    </Form>
  );
};

export default CompanyForm;
