import React, { useCallback, useMemo } from 'react';
import moment from 'moment';

import WarningIcon from '@material-ui/icons/Error';

import Table from '../shared/table/TableRemote';

import { getDiffDays } from '../../utils/date';
import { dateUtils } from '../../utils';
import { getComparator, stableSort } from '../../utils/table';

import { setAllocationStatuses } from '../../constants/enums';

const { ASSIGNED, SHIPPED, DISABLED, AVAILABLE, QUARANTINED, CONFIRMED } = setAllocationStatuses;

const columns = [
  { title: '', field: 'caseStatus', type: 'caseStatus', sort: false },
  { title: 'case date', field: 'date', type: 'date', sort: true },
  { title: 'surgeon', field: 'surgeonName', type: 'default', sort: true },
  { title: 'hospital', field: 'hospitalName', type: 'default', sort: true },
  { title: 'state', field: 'hospitalState', type: 'default', sort: true },
  { title: 'procedure', field: 'procedureName', type: 'default', sort: true },
  { title: 'patient ref', field: 'patientReference', type: 'default', sort: true },
  { title: 'assigned', field: 'assignedSets', type: 'colored', sort: true },
  { title: '% shipped', field: 'shippedSets', type: 'progress', sort: true },
  { title: 'status', field: 'allocationStatus', type: 'colored', sort: true },
  { title: 'ship by', field: 'shipByDate', type: 'date', sort: true },
  { title: 'shipped', field: 'shippingDate', type: 'default', sort: true },
  { title: 'tracking', field: 'tracking', type: 'default', sort: true }
];

const reportingColumns = [
  { title: 'shipping date', field: 'date', type: 'date', sort: true },
  { title: 'hospital', field: 'hospitalName', type: 'default', sort: true },
  { title: 'surgeon', field: 'surgeonName', type: 'default', sort: true },
];

const ShippedKitsTable = (props) => {
  const {
    cases,
    onRowClick = () => {},
    hospitals = [],
    isReport,
    rowColor,
    rowBorderColor,
    order,
    setOrder,
    orderBy,
    setOrderBy,
    page,
    setPage,
    rowsPerPage,
    setRowsPerPage
  } = props;

  const _columns = [
    ...columns,
    {
      title: '',
      field: 'warning',
      type: 'custom',
      sort: false,
      formatter: (value, row) => {
        const allocations = row?.setsAllocation;
        const total = allocations.filter((item) => [ASSIGNED.value, SHIPPED.value, AVAILABLE.value, CONFIRMED.value].includes(item.status));
        const shipped = allocations.filter((item) => item.status === SHIPPED.value || item.status === CONFIRMED.value);
        const hospital = hospitals?.find((h) => h.id === row.hospital);
        const leadDays = hospital?.leadDays || 3;

        const shipDate = moment(value).subtract(leadDays, 'days');
        const days = getDiffDays(shipDate, moment());

        if (total.length !== shipped.length && days < 1) {
          return <WarningIcon style={{ color: QUARANTINED.color }} />;
        }

        if (total.length !== shipped.length && days < 5) {
          return <WarningIcon style={{ color: ASSIGNED.color }} />;
        }

        return null;
      }
    },
  ];

  const getAssignedSets = useCallback((allocations) => {
    const total = allocations.filter((item) => item.status !== DISABLED.value);
    const assigned = allocations.filter((item) => item.status === ASSIGNED.value || item.status === SHIPPED.value || item.status === CONFIRMED.value);
    return {
      value: `${assigned.length}/${total.length}`,
      color: assigned.length === total.length ? SHIPPED.color : ASSIGNED.color,
    };
  }, [cases]);

  const getShippedSets = useCallback((allocations) => {
    const total = allocations.filter((item) => [ASSIGNED.value, SHIPPED.value, CONFIRMED.value, AVAILABLE.value].includes(item.status));
    const shipped = allocations.filter((item) => item.status === SHIPPED.value || item.status === CONFIRMED.value);
    return { value: shipped.length / total.length * 100, color: SHIPPED.color };
  }, [cases]);

  const getStatus = useCallback((allocations, date, hospitalId) => {
    const total = allocations.filter((item) => [ASSIGNED.value, SHIPPED.value, AVAILABLE.value, CONFIRMED.value].includes(item.status));
    const shipped = allocations.filter((item) => item.status === SHIPPED.value || item.status === CONFIRMED.value);
    const hospital = hospitals?.find((h) => h.id === hospitalId);
    const leadDays = hospital?.leadDays || 3;

    if (total.length === shipped.length) {
      return { value: 'All sets shipped', color: SHIPPED.color };
    } else {
      const shipDate = moment(date).subtract(leadDays, 'days');
      const days = getDiffDays(shipDate, moment());
      return {
        value: days > 0 ? `${days} day(s) to ship` : 'Overdue',
        color: days > 0 ? (days > leadDays + 1 ? SHIPPED.color : ASSIGNED.color) : QUARANTINED.color
      };
    }
  }, [cases, hospitals]);

  const getShipByDate = useCallback((date, hospitalId) => {
    const hospital = hospitals?.find((h) => h.id === hospitalId);
    const leadDays = hospital?.leadDays || 3;
    return moment(date).subtract(leadDays, 'days');
  }, [cases, hospitals]);

  const getShippingDate = useCallback((allocations) => {
    const dates = allocations.filter((item) => !!item.shippingDate).map((item) => dateUtils.formatDate(item.shippingDate));
    const uniq = Array.from(new Set(dates));
    return uniq.join(', ');
  }, [cases]);

  const getTracking = useCallback((allocations) => {
    const trackingNumbers = allocations.filter((item) => !!item.trackingNumber).map((item) => item.trackingNumber);
    const uniq = Array.from(new Set(trackingNumbers));
    return uniq.join(', ');
  }, [cases]);

  const rows = useMemo(() => {
    if (cases && cases.length) {
      const sortedRows = stableSort(cases, getComparator(order, orderBy)) || [];
      return sortedRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
    }
    return [];
  },[cases, order, orderBy, page, rowsPerPage]);

  return (
    <div style={{ width: '100%' }}>
      <Table
        rows={rows.map((item) => ({
          ...item,
          assignedSets: getAssignedSets(item.setsAllocation || []),
          shippedSets: getShippedSets(item.setsAllocation || []),
          allocationStatus: getStatus(item.setsAllocation || [], item.date, item.hospital),
          shipByDate: getShipByDate(item.date, item.hospital),
          shippingDate: getShippingDate(item.setsAllocation || []),
          tracking: getTracking(item.setsAllocation || []),
          warning: item.date,
          caseStatus: {
            kitVariant: item.kitVariant,
            additional: !!item.additionalKits?.length || !!item.additionalItems?.length,
          },
        }))}
        columns={isReport ? reportingColumns : _columns}
        onRowClick={onRowClick}
        pagination
        checkboxes={false}
        rowColor={rowColor}
        rowBorderColor={rowBorderColor}
        order={order}
        setOrder={setOrder}
        orderBy={orderBy}
        setOrderBy={setOrderBy}
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        totalRows={cases?.length}
      />
    </div>
  );
};

export default ShippedKitsTable;
