import { createSelector } from 'reselect';

// Cases
const casesListSelector = (state) => state.cases;
export const getCasesList = createSelector(
  casesListSelector,
  (cases) => [...cases?.list, ...cases?.completedCases, ...cases?.transferRequests, ...cases?.assignedCases]
);

// Surgeons
const surgeonsListSelector = (state) => state.surgeons;
export const getSurgeonsList = createSelector(
  surgeonsListSelector,
  (surgeons) => [...surgeons?.list, ...surgeons?.transferredSurgeons]
);

// Usage
const usageListSelected = (state) => state.usage;
export const getUsageList = createSelector(
  usageListSelected,
  (usage) => [...usage?.completedCases]
  // (usage) => [...usage?.list, ...usage?.completedCases]
);
