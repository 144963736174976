import React, { useState } from 'react';
import { CSVDownload } from "react-csv";

import Menu from '@material-ui/core/Menu';
import { withStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';

import DeleteIcon from '../../assets/icons/DeleteIcon';
import FileCopyIcon from '../../assets/icons/CopyIcon';
import UploadIcon from '../../assets/icons/UploadIcon';
import GetAppIcon from '../../assets/icons/GetAppIcon';

import Button from '../shared/Button';
import Select from '../shared/Select';

import { itemTypeOptions } from '../../constants/enums';
import ItemsIcon from '../../assets/icons/ItemIcon';
import ImportIcon from '../../assets/icons/UploadIcon';

const ITEM_HEIGHT = 48;

const StyledMenuItem = withStyles((theme) => ({
  root: {
    paddingTop: '12px',
    paddingBottom: '12px',
  }
}))(MenuItem);

const ItemsFilters = (props) => {
  const {
    onAddItemClick,
    filters,
    setFilters,
    statuses,
    onDelete,
    onUploadClick,
    onBulkUpdateClick,
    onDuplicateClick,
    deletePermission,
    getAllItems,
  } = props;

  const [csvData, setCsvData] = useState(null); // State to hold CSV data
  const [exporting, setExporting] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleExport = async () => {
    try {
      setExporting(true);
      const data = await getAllItems();
      setCsvData(data?.map((item) => ([item.code, item.description, itemTypeOptions[item.type]?.label || ''])));
    } catch (err) {
      console.error(err);
    } finally {
      setExporting(false);
    }
  };

  return (
    <div className='settings-cmp__filters--main'>
      <div className='settings-cmp__actions'>
        <Button
          text='Add Item'
          type='submit'
          onClick={onAddItemClick}
        />

        <Button
          type='submit'
          width='60px'
          onClick={handleClick}
        >
          <UploadIcon color="#ffffff" />
        </Button>

        <Menu
          id='long-menu'
          anchorEl={anchorEl}
          keepMounted
          open={open}
          onClose={handleClose}
          PaperProps={{
            style: {
              maxHeight: ITEM_HEIGHT * 4.5,
              width: 200,
            },
            elevation: 3,
          }}
        >
          <StyledMenuItem
            onClick={() => {
              handleClose();
              onUploadClick();
            }}
          >
            <ImportIcon />
            <span className='m-l-md'>Upload Items</span>
          </StyledMenuItem>
          <StyledMenuItem
            onClick={() => {
              handleClose();
              onBulkUpdateClick();
            }}
          >
            <ItemsIcon />
            <span className='m-l-md'>Bulk Update</span>
          </StyledMenuItem>
        </Menu>

        <Button
          type='icon'
          onClick={onDelete}
          disabled={!deletePermission}
        >
          <DeleteIcon style={{ color: '#000000' }}/>
        </Button>

        <Button
         type='icon'
         onClick={onDuplicateClick}
        >
          <FileCopyIcon style={{ color: '#000000' }}/>
        </Button>
        <Button
          type='icon'
          onClick={handleExport}
          loading={exporting}
          disabled={exporting}
        >
          <GetAppIcon style={{ color: '#000000' }}/>
        </Button>
      </div>

      <div className='settings-cmp__filters'>
        <Select
          value={filters?.type || ''}
          onChange={(value) => setFilters({ ...filters, type: value })}
          options={Object.values(itemTypeOptions)}
          defaultLabel={`All Types (${Object.values(itemTypeOptions).length})`}
          width={200}
        />

        <Select
          value={filters?.status || ''}
          onChange={(value) => setFilters({ ...filters, status: value })}
          options={statuses}
          defaultLabel={`All Status (${statuses.length})`}
          width={200}
        />
      </div>

      {!!csvData && <CSVDownload data={csvData} filename="exported-items.csv" target="_blank" />}
    </div>
  )
};

export default ItemsFilters;
