import React from 'react';

import { makeStyles, withStyles } from '@material-ui/core/styles';
import MuiButton from '@material-ui/core/Button';
import { CircularProgress } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';

import ShippingIcon from '../../../assets/icons/ShippingIcon';
import TransferIcon from '../../../assets/icons/TransferIcon';
import UsageIcon from '../../../assets/icons/UsageIcon';
import DescriptionOutlinedIcon from '../../../assets/icons/DocumentIcon';
import ConsignmentIcon from '../../../assets/icons/ConsignedSetIcon';
import ReturnIcon from '../../../assets/icons/ReturnIcon';

import { setAllocationStatuses } from '../../../constants/enums';

import { getBackgroundColor, getBorderColor } from '../../../utils/colors';

const StyledButton = withStyles((theme) => ({
  root: {
    height: '40px !important',
    width: '40px !important',
    minWidth: '40px !important',
    borderRadius: '8px',
    textTransform: 'capitalize',
    fontSize: '10px',
    border: props => `1px solid ${getBorderColor(props.color)}`,
    background: props => getBackgroundColor(props.color),
    '&:hover': {
      background: props => getBackgroundColor(props.color)
    }
  }
}))(MuiButton);

const useStyles = makeStyles(() => ({
  tooltip: {
    fontSize: '12px',
    padding: '4px 8px',
    borderRadius: 8,
    cursor: 'pointer'
  }
}));

const getStatusIcon = (status) => {
  switch (status) {
    case setAllocationStatuses.SHIPPED.value:
      return <ShippingIcon />;
    case setAllocationStatuses.TRANSFERRED.value:
      return <TransferIcon />;
    case setAllocationStatuses.RETURNED.value:
      return <ReturnIcon />;
    case setAllocationStatuses.DISPATCH.value:
      return <DescriptionOutlinedIcon />;
    case setAllocationStatuses.USAGE.value:
      return <UsageIcon />;
    case setAllocationStatuses.CONFIRMED.value:
      return <ConsignmentIcon />;
    default:
      return null;
  }
};

const getColor = (status, disabled) => {
  if (disabled) {
    return 'grey';
  }
  switch (status) {
    case setAllocationStatuses.SHIPPED.value:
      return 'green';
    case setAllocationStatuses.TRANSFERRED.value:
      return 'orange';
    case setAllocationStatuses.RETURNED.value:
      return 'blue';
    case setAllocationStatuses.DISPATCH.value:
      return 'blue';
    case setAllocationStatuses.USAGE.value:
      return 'green';
    case setAllocationStatuses.CONFIRMED.value:
      return 'blue';
    default:
      return null;
  }
};

const getButtonLabel = (status) => {
  switch (status) {
    case setAllocationStatuses.SHIPPED.value:
      return 'Ship';
    case setAllocationStatuses.RETURNED.value:
      return 'Return';
    case setAllocationStatuses.TRANSFERRED.value:
      return 'Transfer';
    case setAllocationStatuses.DISPATCH.value:
      return 'Documents';
    case setAllocationStatuses.USAGE.value:
      return 'Usage';
    case setAllocationStatuses.CONFIRMED.value:
      return 'Confirm';
    default:
      return null;
  }
};

const ActionButton = ({
  status = setAllocationStatuses.AVAILABLE.value,
  onClick,
  disabled,
  loading,
}) => {
  const classes = useStyles();

  return (
    <Tooltip
      title={getButtonLabel(status)}
      aria-label={`action_button_tooltip`}
      classes={{ tooltip: classes.tooltip }}
    >
      <StyledButton
        className="action-icon-button"
        onClick={() => onClick(status)}
        disabled={disabled}
        color={getColor(status, disabled)}
      >
        {loading && <CircularProgress size={24} />}
        {!loading && (
          <div className="d-flex direction-column space-between">
            {getStatusIcon(status)}
            {/*<div className='filter-button__label'>*/}
            {/*  {getButtonLabel(status)}*/}
            {/*</div>*/}
          </div>
        )}
      </StyledButton>
    </Tooltip>
  )
};

export default ActionButton;
