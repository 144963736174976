import React from 'react';

import ShippingIcon from '../../../assets/icons/ShippingIcon';

import { setAllocationStatuses, setAllocationTypes } from '../../../constants/enums';

import '../checklists/checklists.scss';

const ReturnSetsList = (props) => {
  const { list = [], selected, onSelect } = props;

  const handleSelect = (item) => {
    onSelect(item);
    const container = document.querySelector('.app-wrapper');
    if (container) {
      container.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }

  return (
    <div className='case-checklists__container'>
      {list?.map((item) => (
        <div
          key={item.id}
          className={`case-checklist-item${selected?.id === item.id ? ' selected' : ''}`}
          onClick={() => handleSelect(item)}
          style={{ borderColor: setAllocationStatuses[item.allocationStatus]?.color }}
        >
          <div
            className='font-bold m-b-sm'
            // style={{ color: selected?.id === item.id ? tenantColor : 'inherit' }}
          >
            {item.allocationType === setAllocationTypes.ITEM ? item.code : item.kitName}
          </div>
          <div className='m-b-sm'>
            {item.allocationType === setAllocationTypes.ITEM ? item.description : item.setNumber}
          </div>
          <div className='d-flex space-between'>
            <div className='uppercase' style={{ color: setAllocationStatuses[item.allocationStatus]?.color }}>
              {setAllocationStatuses[item.allocationStatus]?.label}
            </div>
            {item?.allocationStatus === setAllocationStatuses.SHIPPED.value && (
              <ShippingIcon color={setAllocationStatuses.TRANSFERRED.color} />
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default ReturnSetsList;
