import React, { useState, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import { sortBy } from 'lodash';

import LoadScreen from '../../../components/load-screen';
import Button from '../../../components/shared/Button';
import {
  CaseInfo,
  ChecklistInfo,
  ChecklistsContainer,
  ChecklistSubmitModal,
  ChecklistSection,
} from '../../../components/cases/checklists';
import BatchSection from '../../../components/sets/batch-control/BatchSection';

import { useLoading } from '../../../hooks';
import { getCase, subscribeToCaseChecklists, updateChecklist, } from '../../../actions/casesActions';
import { getSurgeons } from '../../../actions/surgeonsActions';
import { getHospitals } from '../../../actions/hospitalsActions';
import { getSets, setSets, updateBatchItems, updateItemQuantity } from '../../../actions/setsActions';

import routes from '../../../constants/routes';
import { billOfMaterialItemTypes, caseChecklistStatuses } from '../../../constants/enums';
import { getItemsByIds } from '../../../actions/itemsActions';

const CaseChecklistsPage = () => {
  const history = useHistory();
  const { id } = useParams();
  const { search } = useLocation();

  const caseId = id.replace(':', '');
  const setId = search ? search.replace('?id=', '') : '';

  const dispatch = useDispatch();
  const activeCase = useSelector((state) => state.activeCase.data);
  const caseChecklists = useSelector((state) => state.activeCase.checklists);
  const surgeons = useSelector((state) => state.surgeons.list);
  const hospitals = useSelector((state) => state.hospitals.list);
  const sets = useSelector((state) => state.sets.list);
  const tenantColor = useSelector((state) => state.tenant.currentTenant.colorPrimary);

  const [checklists, setChecklists] = useState([]);
  const [selected, setSelected] = useState(null);
  const [open, toggleModal] = useState(false);

  const [items, setItems] = useState([]);
  const [fetching, setFetching] = useState(false);

  const { loading, startLoading, stopLoading } = useLoading(false);
  const { loading: submitting, startLoading: startSubmit, stopLoading: stopSubmit } = useLoading(false);
  const { loading: batchLoading, startLoading: startBatchLoading, stopLoading: stopBatchLoading } = useLoading(false);

  useEffect(() => {
    // const unsubscribeToCase = dispatch(subscribeToCase(caseId));
    const unsubscribeToCaseChecklists = dispatch(subscribeToCaseChecklists(caseId));

    onLoad().catch((err) => console.error(err));

    return () => {
      // if (unsubscribeToCase && typeof unsubscribeToCase === 'function') {
      //   unsubscribeToCase();
      // }
      unsubscribeToCaseChecklists();
    };
  }, []);

  useEffect(() => {
    if (caseChecklists && caseChecklists.length && !checklists?.length) {
      const checklist = setId ? caseChecklists.find((item) => item.id === setId) : null;
      const inspectionChecklists = caseChecklists?.filter((item) => !item.consigned);
      const sortedChecklists = sortBy(inspectionChecklists, 'kitName');
      setChecklists(sortedChecklists);
      onSelectSection(checklist || sortedChecklists[0]).catch();
    }
  }, [caseChecklists]);

  const onLoad = async () => {
    startLoading()
    try {
      await Promise.all([
        dispatch(getCase(caseId)),
        dispatch(getSurgeons()),
        dispatch(getHospitals()),
        dispatch(getSets()),
      ]);
    } catch (err) {
      console.error(err);
    } finally {
      stopLoading();
    }
  };

  const onSelectSection = async (checklist) => {
    try {
      setFetching(true);
      setSelected(checklist);
      if (checklist?.sections?.length) {
        const res = await dispatch(getItemsByIds(checklist?.sections?.flatMap((section) => section?.items || []).map((i) => i.id)));
        setItems(res || []);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setFetching(false);
    }
  };

  const onItemsChange = async (sectionIndex, item, values) => {
    try {
      startBatchLoading();
      const set = sets?.find(((s) => s.id === selected?.id));
      await dispatch(updateBatchItems(set, item.id, values));
      await dispatch(getSets());

      const temp = [...checklists];
      const checklist = temp?.find((i) => i.id === selected?.id);

      if (checklist && checklist.sections) {
        const sections = [...checklist.sections];
        const _items = [...sections[sectionIndex].items];
        const _item = _items?.find((i) => i.id === item.id);

        _item.outbound = values?.filter((i) => !!i.code)?.length;
        _item.batchOutbound = values?.filter((i) => !!i.code)?.map((value) => ({ id: value.id || '', code: value.code || '' }));
        checklist.sections = sections;

        setChecklists(temp);
        setSelected(checklist);

        await dispatch(updateChecklist(caseId, checklist.id, checklist));
      }
    } catch (err) {
      console.error(err);
    } finally {
      stopBatchLoading();
    }
  };

  const onQuantityChange = (sectionIndex, item, value) => {
    const temp = [...checklists];
    const checklist = temp?.find((i) => i.id === selected?.id);

    if (checklist && checklist.sections) {
      const sections = [...checklist.sections];
      const _items = [...sections[sectionIndex].items];
      const _item = _items?.find((i) => i.id === item.id);

      _item.outbound = value;
      _item.batchQuantity = value;
      checklist.sections = sections;

      setChecklists(temp);
      setSelected(checklist);

      dispatch(updateChecklist(caseId, checklist.id, checklist));
    }

    const updatedSets = [...sets];
    const set = updatedSets?.find(((s) => s.id === selected?.id));
    if (set) {
      set.itemsQuantity = {
        ...set.itemsQuantity,
        [item.id]: value
      };
      dispatch(setSets(updatedSets));
      dispatch(updateItemQuantity(set, item.id, value));
    }
  };

  const onSubmit = async (data, changedValues) => {
    startSubmit();

    const doc = {
      ...data,
      sections: data?.sections?.map((section) => ({
        ...section,
        items: section?.items?.map((item) => {
          if (item.type === billOfMaterialItemTypes.NOTES.value) {
            return item;
          }
          const _item = changedValues?.find((i) => i.id === item.id);
          return { ...item, outbound: _item ? _item.outbound : item.outbound };
        })
      }))
    };

    try {
      await dispatch(updateChecklist(caseId, data.id, doc, true));
      toggleModal(false);
      setSelected({ ...selected, ...doc });

      const arr = [...checklists];
      const updatedChecklist = arr?.find((c) => c.id === data.id);
      if (updatedChecklist) {
        updatedChecklist.status = data.status;
        setChecklists(arr);
      }
    } catch (err) {
      console.error(err);
    } finally {
      stopSubmit();
    }
  };

  const onViewClick = () => {
    history.push(`${routes.CASES}/${caseId}?view=SETS`);
  };

  const formattedId = useMemo(() => {
    if (!activeCase) {
      return ''
    }
    const arr = activeCase?.id?.split('-');
    return arr?.length > 1 ? `${arr[0]}-${arr[1]}` : activeCase?.id;
  }, [activeCase]);

  return (
    <div className='page-container case-checklists-page__container'>
      {(loading || fetching) && <LoadScreen />}
      {activeCase && activeCase.id && (
        <>
          <div className='page-title'>
            Case
            <span className='page-subtitle'>{formattedId}</span>
          </div>

          <div className='case-checklists-page__body'>
            <div className='d-flex direction-column'>
              <div className='width-100'>
                <Button
                  type='outlined'
                  text='View Case'
                  onClick={onViewClick}
                />
                <Button
                  type='submit'
                  text='Submit'
                  onClick={() => toggleModal(true)}
                  loading={submitting}
                  disabled={submitting || selected?.status !== caseChecklistStatuses.INSPECT.value}
                />
              </div>
              <ChecklistsContainer
                checklists={checklists}
                selected={selected}
                onSelect={onSelectSection}
                tenantColor={tenantColor}
              />
            </div>
            <div>
              <div className='d-flex space-between'>
                {selected ? (
                  <ChecklistInfo
                    {...selected}
                  />
                ) : (
                  <div />
                )}
                <CaseInfo
                  activeCase={activeCase}
                  surgeons={surgeons}
                  hospitals={hospitals}
                />
              </div>

              <div style={{ marginTop: 32 }}>
                {selected && selected.sections && (
                  selected.sections.map((section, index) => (
                    selected.status === caseChecklistStatuses.INSPECT.value ? (
                      <BatchSection
                        key={`${section.id}_${section.name}_${section.subtitle}`}
                        index={index}
                        checklistPage
                        sectionIndex={index}
                        onChange={(item, values) => onItemsChange(index, item, values)}
                        onQuantityChange={(item, value) => onQuantityChange(index, item, value)}
                        batchItems={sets?.find(((s) => s.id === selected?.id))?.batchItems || {}}
                        itemsQuantity={sets?.find(((s) => s.id === selected?.id))?.itemsQuantity || {}}
                        loading={batchLoading}
                        {...section}
                        items={section?.items?.map((item) => ({
                          ...item,
                          batchControl: !!items?.find((i) => i.id === item.id)?.batchControl
                        }))}
                      />
                    ) : (
                      <ChecklistSection
                        key={`${section.id}_${section.name}_${section.subtitle}`}
                        sectionIndex={index}
                        checklistId={selected.id}
                        status={selected.status}
                        tenantColor={tenantColor}
                        {...section}
                      />
                    )
                  ))
                )}
              </div>
            </div>
          </div>
        </>
      )}

      {selected && (
        <ChecklistSubmitModal
          open={open}
          onClose={() => toggleModal(false)}
          onSubmit={onSubmit}
          defaultChecklist={caseChecklists?.find((item) => item.id === selected.id)}
          checklist={selected}
          tenantColor={tenantColor}
          loading={submitting}
          itemsList={items}
          batchItems={sets?.find(((s) => s.id === selected?.id))?.batchItems || {}}
          itemsQuantity={sets?.find(((s) => s.id === selected?.id))?.itemsQuantity || {}}
        />
      )}
    </div>
  );
};

export default CaseChecklistsPage;
